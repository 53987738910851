import { useEffect, useState } from "react";
import { GenericHelperService } from "../../api/generics/helper.service";
import { WarehouseService } from "../../api/services/warehouse.service";
import {
  IListConfig,
  ILocationUpdater,
  IProducts,
  IStatusUpdate,
  IWarehouse,
  IWarehouseBayLocation,
  IWarehouseContext,
  IWarehouseMap,
  IWarehouseMapForm,
  IWarehouseStats,
} from "../../common/models";
import { cloneDeep } from "lodash";
import {
  EmptyListConfig,
  EmptyLocationUpdater,
  EmptyStatusUpdate,
  GENERAL_CONSTANTS,
  LOCATION_STATUS_COLORS,
} from "../../common/constants/generalConstants";
import { toast } from "react-toastify";
import { IProductCategory } from "../../common/models/master";
import { ProductService } from "../../api/services/product.service";
import { MasterService } from "../../api/services/master.service";
import _ from "lodash";
import { ILogsData, ILogsProps } from "../../common/models/logs";
import { SamplingService } from "../../api/services/sampling.service";

const useWarehouse = () => {
  const warehouseService: WarehouseService = new WarehouseService();
  const helperService: GenericHelperService = new GenericHelperService();
  const productService: ProductService = new ProductService();
  const samplingService: SamplingService = new SamplingService();
  const masterService: MasterService = new MasterService();
  const [productsList, setProductsList] = useState<IProducts[]>([]);
  const [productCateList, setProductCateList] = useState<IProductCategory[]>(
    []
  );
  const [masterWarehouseList, setMasterWarehouseList] = useState<IWarehouse[]>(
    []
  );
  const [warehouseList, setWarehouseList] = useState<IWarehouse[]>([]);
  const [warehouseForm, setWarehouseForm] = useState<IWarehouse>(null);
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);

  const [selectedWarehouse, setSelectedWarehouse] = useState<IWarehouse>(null);
  const [warehouseMap, setWarehouseMap] = useState<IWarehouseMap[]>([]);

  const [previewMapForm, setPreviewMapForm] = useState<IWarehouseMapForm>(null);
  const [previewMap, setPreviewMap] = useState<any>(null);

  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [selectedLocationDtls, setSelectedLocationDtls] = useState<any>(null);
  const [wareHouseStats, setWareHouseStats] = useState<IWarehouseStats>(null);
  const [stockWarehouseLogs, setStockWarehouseLogs] = useState<ILogsData[]>([]);
  const [WarehouseLogs, setWarehouseLogs] = useState<ILogsData[]>([]);

  const getLogsStockWarehouse = async (id: number) => {
    const _logs: ILogsProps[] = await samplingService.getLogs(`TO${id}`);
    setStockWarehouseLogs(_logs);
  };

  const getLogsWarehouse = async (id: number) => {
    const _logs: ILogsProps[] = await masterService.getLogsWarehouse(`WH${id}`);
    setWarehouseLogs(_logs);
  };

  //#region Warehouse List
  const getWarehousesList = async () => {
    const _warehouses = await warehouseService.getWarehouses();
    const _filters: IListConfig = cloneDeep(filters);
    _filters.currentPage = 1;
    _filters.searchQuery = "";
    _filters.totalRecords = _warehouses.length;
    _filters.totalPageCount = Math.ceil(
      _warehouses.length / _filters.currentPageSize
    );
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setFilters(_filters);
    setMasterWarehouseList(_warehouses ?? []);
  };

  const initData = () => {
    getWarehousesList();
    setWarehouseForm(null);
    setSelectedLocation(null);
    setSelectedLocationDtls(null);
    setSelectedList(EmptyStatusUpdate);
    getProductsList();
    getProductCategoriesList();
  };

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const indexOfLastItem = filters.currentPage * filters.currentPageSize;
    const indexOfFirstItem = indexOfLastItem - filters.currentPageSize;
    let _filteredList: IWarehouse[] = cloneDeep(masterWarehouseList);
    _filteredList = _filteredList.filter(
      (_sup: IWarehouse, _supIdx: number) => {
        return (
          _sup.warehouseName &&
          _sup.warehouseName
            .toLowerCase()
            .includes(filters.searchQuery.toLowerCase())
        );
      }
    );
    _filteredList.sort((a, b) =>
      helperService.sort(
        filters.sortDirection === "asc" ? a : b,
        filters.sortDirection === "asc" ? b : a,
        filters.sortItem,
        filters.sortIsDate
      )
    );
    console.log("Filtered Sorted list - ", _filteredList);
    const _result: IWarehouse[] =
      _filteredList.slice(indexOfFirstItem, indexOfLastItem) ?? [];
    setWarehouseList(_result);
    getWarehouseMap(selectedWarehouse ?? _result[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, masterWarehouseList]);
  //#endregion

  const getProductsList = async () => {
    const _products = await productService.getProducts();
    _products?.map((item) => {
      item.selected = false;
    });
    let _filteredProducts = _.filter(_products, { status: "ACTIVE" });
    setProductsList(_filteredProducts ?? []);
  };

  const getProductCategoriesList = async () => {
    const _productCateList = await masterService.getProductCategories();
    setProductCateList(_productCateList ?? []);
  };
  //#region Warehouse CRUD
  const updateStatus = async () => {
    const _updateStatus: boolean = await warehouseService.updateStatus(
      selectedList
    );
    if (_updateStatus) {
      toast.success("Status updated successfully.");
      initData();
    } else {
      toast.error("Error updating status.");
    }
  };

  const saveWarehouse = async () => {
    const _warehouseSave: Boolean = await warehouseService.saveWarehouse(
      warehouseForm
    );
    if (_warehouseSave) {
      if (typeof _warehouseSave == "string") {
        toast.error(_warehouseSave);
      } else {
        toast.success("Werehouse saved successfully.");
        initData();
      }
    } else {
      toast.error("Error saving Werehouse.");
    }
  };

  const deleteWarehouse = async () => {
    const _deleteItem: IWarehouse = cloneDeep(warehouseForm);
    _deleteItem.deleted = true;
    const _itemSave = await warehouseService.saveWarehouse(_deleteItem);
    if (_itemSave) {
      toast.success("Warehouse deleted successfully.");
      initData();
    } else {
      toast.error("Error deleting warehouse.");
    }
  };
  //#endregion

  //#region Warehouse Map
  const getWarehouseMap = async (warehouse: IWarehouse) => {
    if (warehouse) {
      setSelectedWarehouse(warehouse);
      const _warehousesMap: IWarehouseMap[] =
        await warehouseService.getWarehouseMapById(warehouse.wareHouseId);
      setBayThemeColorForWarehouse(
        _warehousesMap,
        (newWarehouseMap: IWarehouseMap[]) => {
          setWarehouseMap(newWarehouseMap);
        }
      );
    }
  };

  const getWarehouseStats = async (id: number) => {
    if (id) {
      const _warehousesStats = await warehouseService.getWarehousStatsById(id);
      if (_warehousesStats) {
        setWareHouseStats(_warehousesStats);
      }
    }
  };

  const setBayThemeColorForWarehouse = (
    warehouseMap: IWarehouseMap[],
    callback: Function
  ) => {
    warehouseMap?.forEach((_warehouseMap) => {
      _warehouseMap?.bays?.forEach((_bay) => {
        (_bay?.baysLocations ?? [])
          .filter((_loc) => _loc?.deleted === false)
          .map(async (_loc, _locIdx) => {
            _loc.themeColor = getLocationColor(_loc);
          });
      });
    });
    callback(warehouseMap);
  };

  const generatePreview = () => {
    const newAsile = warehouseService.generatePreview(previewMapForm);
    setPreviewMap(newAsile);
  };

  const saveLocations = async () => {
    const newLocations = warehouseService.saveWarehouseLocation(previewMap);
    if (newLocations) {
      const _warehousesMap: IWarehouseMap[] =
        await warehouseService.getWarehouseMapById(previewMap.warehouseId);
      setWarehouseMap(_warehousesMap);
      initData();
      return true;
    }
    return false;
  };

  const saveLocationTransfer = async (obj) => {
    const locationTransfer = await warehouseService.saveLocationTransfer(obj);
    if (locationTransfer) {
      return true;
    }
    return false;
  };

  const editLocationDetails = async (
    location: IWarehouseBayLocation,
    message: string,
    refreshData: boolean = true
  ) => {
    const newLocations = await warehouseService.editWarehouseLocation(location);
    if (newLocations) {
      toast.success(message);
      if (refreshData) {
        initData();
      }
    } else {
      toast.error("Error updating status.");
    }
  };

  const editLocation = () => {
    const newLocations =
      warehouseService.editWarehouseLocation(selectedLocation);
    if (newLocations) {
      toast.success("Status updated successfully.");
      initData();
    } else {
      toast.error("Error updating status.");
    }
  };
  const deleteLocation = () => {
    const _selectedLocation = { ...selectedLocationDtls[0], deleted: true };
    const newLocations =
      warehouseService.editWarehouseLocation(_selectedLocation);
    if (newLocations) {
      toast.success("Location deleted successfully.");
      initData();
    } else {
      toast.error("Error deleting Location.");
    }
  };
  //#endregion

  const getWarehouseDetails = (wid: number) => {
    let result: IWarehouse;
    masterWarehouseList?.map((item) => {
      if (item?.wareHouseId === wid) result = item;
    });
    return result;
  };

  const getLocationDtlsById = async (loc: IWarehouseBayLocation) => {
    let _locDetails = await warehouseService.GetLocationDtlsById(loc?.locId);
    setSelectedLocation(loc);
    _locDetails?.map((item) => {
      item.transferQty = 0;
      item.transferReason = "";
      item.transferShelfId = "";
    });
    setSelectedLocationDtls(_locDetails ?? []);
    return _locDetails;
  };

  const getLocationColor = (_loc: IWarehouseBayLocation) => {
    let color = "";
    let status =
      _loc?.status === GENERAL_CONSTANTS.OCCUPIED
        ? _loc.batchStatus
        : _loc.status;

    switch (status) {
      case LOCATION_STATUS_COLORS.QUARANTINE.name:
        color = LOCATION_STATUS_COLORS.QUARANTINE.color;
        break;
      case LOCATION_STATUS_COLORS.REJECTED.name:
        color = LOCATION_STATUS_COLORS.REJECTED.color;
        break;
      case LOCATION_STATUS_COLORS.RELEASE.name:
        color = LOCATION_STATUS_COLORS.RELEASE.color;
        break;
      case LOCATION_STATUS_COLORS.IN_USE.name:
        color = LOCATION_STATUS_COLORS.IN_USE.color;
        break;
      case LOCATION_STATUS_COLORS.NOT_IN_USE.name:
        color = LOCATION_STATUS_COLORS.NOT_IN_USE.color;
        break;
    }

    return color;
  };

  const updateWarehouseDetails = async (
    locations: number[],
    status: any,
    message: string,
    refreshData: boolean = true
  ) => {
    if (locations.length === 0) return;
    let config: ILocationUpdater = cloneDeep(EmptyLocationUpdater);
    config.ids = locations;
    config.status = status;
    const newLocations = await warehouseService.editWarehouseLocationsArray(
      config
    );
    if (newLocations) {
      toast.success(message);
      if (refreshData) {
        initData();
      } else {
        getWarehouseMap(selectedWarehouse);
      }
    } else {
      toast.error("Error updating status.");
    }

    //end
  };

  const deallocateUnusedAislesIfAny = async (
    selectedShelfs: any[],
    assignedLocations: any[]
  ) => {
    let unusedLocations = [];

    if (_.isEqual(selectedShelfs, assignedLocations)) return;

    selectedShelfs?.map((selected) => {
      let isUsed = false;
      assignedLocations?.map((assigned) => {
        if (selected === assigned) {
          isUsed = true;
        }
      });
      if (!isUsed) {
        unusedLocations.push(selected);
      }
    });
    await updateWarehouseDetails(
      unusedLocations,
      GENERAL_CONSTANTS.IN_USE,
      "Unused pallets deallocated successfully!!"
    );
  };

  const updateLocation = async (locData) => {
    const res = await warehouseService.updateLocation(locData);
    if (res && res.status === 200) {
      setSelectedLocationDtls(null);
      toast.success("Location updated successfully");
    } else {
      toast.error("Unable to update location");
    }
  };

  return {
    warehouseList,
    masterWarehouseList,
    selectedList,
    filters,
    warehouseForm,
    warehouseMap,
    selectedWarehouse,
    getWarehouseMap,
    setFilters,
    setWarehouseForm,
    setSelectedList,
    updateStatus,
    saveWarehouse,
    deleteWarehouse,
    previewMapForm,
    setPreviewMapForm,
    previewMap,
    setPreviewMap,
    generatePreview,
    saveLocations,
    selectedLocation,
    setSelectedLocation,
    editLocation,
    deleteLocation,
    getWarehouseDetails,
    getLocationDtlsById,
    selectedLocationDtls,
    setSelectedLocationDtls,
    productsList,
    productCateList,
    updateWarehouseDetails,
    deallocateUnusedAislesIfAny,
    getWarehouseStats,
    wareHouseStats,
    saveLocationTransfer,
    updateLocation,
    stockWarehouseLogs,
    getLogsStockWarehouse,
    getLogsWarehouse,
    WarehouseLogs,
  } as IWarehouseContext;
};

export default useWarehouse;
