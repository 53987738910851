import { useContext, useState } from "react";
import { LabelConstants } from "../../common/constants/labelConstants";
import { EmptyStatusUpdate } from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import { DeliveryOrderContext } from "../../controllers/contexts/deliveryorder.context";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; 
dayjs.extend(utc);  // Extend dayjs with the utc plugin

const DeliveryOrderStatusUpdateComponent = (props) => {
  const { selectedList, setSelectedList, updateStatus } =
    useContext(DeliveryOrderContext);

  const [todayDate, setTodayDate] = useState(dayjs().startOf('day').add(dayjs().utcOffset(), 'minute'));
  const [delivered, setDelivered] = useState(false);

  const handleCustomerForm = (type: string, value: any) => {
    const _form = cloneDeep(selectedList);
    if (type === 'status') {
      setDelivered(value === 'DELIVERED');
      if(value === 'DELIVERED') {
        _form['modifiedDate'] = todayDate;
      }
    }
    if(type === 'modifiedDate') {
      setTodayDate(dayjs(value).startOf('day').add(dayjs().utcOffset(), 'minute'));
      _form['modifiedDate'] = dayjs(value).startOf('day').add(dayjs().utcOffset(), 'minute');
    } else {
      _form[type] = value;
    }
    setSelectedList(_form);
  };

  const isFormValid = (): boolean => {
    let isValid = false;
    isValid = selectedList?.comments !== "" && selectedList?.status !== "";
    return !isValid;
  };

  return (
    <div className="modal fade" id="updateStatus" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable w-25">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{LabelConstants.UpdateStatus}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close" onClick={() => { setDelivered(false); setTodayDate(dayjs().startOf('day').add(dayjs().utcOffset(), 'minute'))}}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="form-group col-6">
                <label className="form-label">
                  {LabelConstants.Status}
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-select"
                  value={selectedList.status}
                  onChange={(e) => handleCustomerForm("status", e.target.value)}
                >
                  {props.tempStatus === "DRAFT" ? (
                    <>
                      <option value="DRAFT">DRAFT</option>
                      <option value="DISPATCHED">DISPATCHED</option>
                      <option value="CANCELLED">CANCELLED</option>
                    </>
                  ) : props.tempStatus === "DISPATCHED" ? (
                    <>
                      <option value="DISPATCHED">DISPATCHED</option>
                      <option value="IN_TRANSIT">IN_TRANSIT</option>
                    </>
                  ) : props.tempStatus === "IN_TRANSIT" ? (
                    <>
                      <option value="IN_TRANSIT">IN_TRANSIT</option>
                      <option value="OFD">OFD</option>
                    </>
                  ) : props.tempStatus === "OFD" ? (
                    <>
                      <option value="OFD">OFD</option>
                      <option value="DELIVERED">DELIVERED</option>
                      <option value="NOT_DELIVERED">NOT_DELIVERED</option>
                    </>
                  ) : props.tempStatus === "DELIVERED" ? (
                    <option value="DELIVERED">DELIVERED</option>
                  ) : props.tempStatus === "NOT_DELIVERED" ? (
                    <option value="NOT_DELIVERED">NOT_DELIVERED</option>
                  ) : props.tempStatus === "CANCELLED" ? (
                    <option value="CANCELLED">CANCELLED</option>
                  ) : (
                    ""
                  )}
                </select>
              </div>
              <style>
                {`
                  .ant-picker-dropdown {
                  z-index: 1060 !important;
                  }

                  .ant-picker-dropdown .ant-picker-panel-container {
                  z-index: 1060 !important;
                  }
               `}
              </style>
              {delivered && (
                <div className="form-group col-6">
                  <label className="form-label">
                    {LabelConstants.DeliveredDate}
                    <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                     className="form-control bg-white"
                     value={todayDate}
                     format="DD-MM-YYYY"
                     onChange={(e, ds) => {
                       let date = e?.format("YYYY-MM-DD");
                       handleCustomerForm("modifiedDate", date);
                     }}
                  />
                </div>
              )}
              <div className="form-group col-12">
                <label className="form-label" htmlFor="comments">
                  {LabelConstants.Comments}
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  id={"comments"}
                  value={selectedList.comments}
                  onChange={(e) =>
                    handleCustomerForm("comments", e.target.value)
                  }
                  rows={5}
                  cols={5}
                  className="form-control"
                  placeholder="Commnets"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer modal-footer-uniform">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={() => { setSelectedList(EmptyStatusUpdate); setDelivered(false); setTodayDate(dayjs().startOf('day').add(dayjs().utcOffset(), 'minute')) }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary float-end"
              data-bs-dismiss="modal"
              disabled={isFormValid()}
              onClick={() => {updateStatus(); setDelivered(false); setTodayDate(dayjs().startOf('day').add(dayjs().utcOffset(), 'minute'))}}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryOrderStatusUpdateComponent;
