import { useContext, useEffect, useState } from "react";
import { LabelConstants } from "../../common/constants/labelConstants";
import {
  EmptyStatusUpdate,
  SUCCESS,
} from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import { OrderPickingContext } from "../../controllers/contexts/orderpicking.context";
import { useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { toast } from "react-toastify";

var redirectionStatus = ["PICKED", "READY_FOR_DISPATCH", "PART_PICKED"];
const OPStatusUpdateComponent = (props) => {
  const {
    selectedList,
    setSelectedList,
    updateStatus,
    orderPickingList,
    validateIfCancelIsEligible,
  } = useContext(OrderPickingContext);
  const [proceedWithRedirection, setproceedWithRedirection] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setproceedWithRedirection(false);
  }, [props]);

  const handleCustomerForm = (type: string, value: any) => {
    const _form = cloneDeep(selectedList);
    if (type === "status") {
      let proceedRedirection = false;
      if (value === "CANCELLED") {
        let previousStatus = _form?.status;
        if (redirectionStatus.includes(previousStatus)) {
          proceedRedirection = true;
        }
      }

      if (proceedRedirection !== proceedWithRedirection) {
        setproceedWithRedirection(proceedRedirection);
      }

      if (proceedRedirection) {
        return;
      }
    }

    _form[type] = value;
    setSelectedList(_form);
  };

  const continueWithCancelOperation = async () => {
    if (selectedList?.ids?.length < 0) {
      return;
    }

    let item = orderPickingList?.find(
      (item) => item?.id === selectedList?.ids[0]
    );
    await validateIfCancelIsEligible(item?.pickingNum)?.then((result) => {
      if (result?.status === SUCCESS) {
        navigate(RoutingConstants.orderPickingCancel, {
          state: { op: item },
        });
      } else {
        toast.error(result?.message);
      }
    });
  };

  const isFormValid = (): boolean => {
    let isValid = false;

    if (proceedWithRedirection) {
      isValid = selectedList?.status !== "";
    } else {
      isValid = selectedList?.comments !== "" && selectedList?.status !== "";
    }
    return !isValid;
  };

  return (
    <div className="modal fade" id="updateStatus" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable w-25">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{LabelConstants.UpdateStatus}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="form-group col-12">
                <label className="form-label">
                  {LabelConstants.Status}
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-select"
                  value={
                    proceedWithRedirection ? "CANCELLED" : selectedList.status
                  }
                  onChange={(e) => handleCustomerForm("status", e.target.value)}
                >
                  {/* {PurchaseStatuses.map((_status: string, _statusIdx: number) => {
                                    return <option key={`statusOption${_statusIdx}`} value={_status}>{_status}</option>
                                })} */}

                  {props.tempStatus === "DRAFT" ? (
                    <>
                      <option value={"DRAFT"}>DRAFT</option>
                      <option value={"PICKING"}>PICKING</option>
                      <option value={"CANCELLED"}>CANCELLED</option>
                    </>
                  ) : props.tempStatus === "PICKING" ? (
                    <>
                      <option value={"PICKING"}>PICKING</option>
                      <option value={"PART_PICKED"}>PARTIALLY PICKED</option>
                      <option value={"PICKED"}>PICKED</option>
                      <option value={"CANCELLED"}>CANCELLED</option>
                    </>
                  ) : props.tempStatus === "PART_PICKED" ? (
                    <>
                      <option value={"PART_PICKED"}>PARTIALLY PICKED</option>
                      <option value={"PICKED"}>PICKED</option>
                      <option value={"CANCELLED"}>CANCELLED</option>
                    </>
                  ) : props.tempStatus === "PICKED" ? (
                    <>
                      <option value={"PICKED"}>PICKED</option>
                      <option value={"READY_FOR_DISPATCH"}>
                        READY FOR DISPATCH
                      </option>
                      <option value={"CANCELLED"}>CANCELLED</option>
                    </>
                  ) : props.tempStatus === "READY_FOR_DISPATCH" ? (
                    <>
                      <option value={"READY_FOR_DISPATCH"}>
                        READY FOR DISPATCH
                      </option>
                      <option value={"CANCELLED"}>CANCELLED</option>
                    </>
                  ) : props.tempStatus === "CANCELLED" ? (
                    <option value={"CANCELLED"}>CANCELLED</option>
                  ) : props.tempStatus === "DISPATCHED" ? (
                    <option value={"DISPATCHED"}>DISPATCHED</option>
                  ) : (
                    ""
                  )}
                </select>
              </div>
              {!proceedWithRedirection && (
                <div className="form-group col-12">
                  <label className="form-label" htmlFor="comments">
                    {LabelConstants.Comments}
                    <span className="text-danger">*</span>
                  </label>
                  <textarea
                    id={"comments"}
                    value={selectedList.comments}
                    onChange={(e) =>
                      handleCustomerForm("comments", e.target.value)
                    }
                    rows={5}
                    cols={5}
                    className="form-control"
                    placeholder="Commnets"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer modal-footer-uniform">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={() => setSelectedList(EmptyStatusUpdate)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary float-end"
              data-bs-dismiss="modal"
              disabled={isFormValid()}
              onClick={() => {
                if (proceedWithRedirection) {
                  continueWithCancelOperation();
                } else {
                  updateStatus();
                }
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OPStatusUpdateComponent;
