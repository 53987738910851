import { LabelConstants } from "../../../common/constants/labelConstants";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import {
  NavLink,
  useNavigate,
  useLocation,
  useBlocker,
} from "react-router-dom";
import "react-phone-number-input/style.css";
import { useContext, useEffect, useState } from "react";
import { GrnContext } from "../../../controllers/contexts/grn.context";
import { cloneDeep } from "lodash";
import {
  FORM_MODES,
  GoodReceiptStatus,
  GoodReceiptTypes,
  NewDeleteConfirmationDialog,
  NewGoodsReceipt,
  SEQUENCE_PREFIXES,
} from "../../../common/constants/generalConstants";
import { IGRNOrderItem, IOrders } from "../../../common/models/grn";
import _ from "lodash";
import validator from "../../../common/models/validator";
import { WarehouseContext } from "../../../controllers/contexts/warehouse.context";
import {
  IDeleteConfirmationProps,
  IProducts,
  IWarehouse,
} from "../../../common/models";
import {
  getDateIfValid,
  getNumberForString,
  getProductCatNameById,
  isNullUndefined,
  isNumeric,
} from "../../../common/methods/globalmethods";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";
import DeleteConfirmationComponent from "../../common/deleteConfirmation.component";
import Select from "react-select";
import { DatePicker } from "antd";

var myModal;
const VALIDATOR = {
  goodsRecType: [(value) => (_.isEmpty(value) ? `Enter order type` : null)],
  orderNum: [(value) => (_.isEmpty(value) ? `Select order number` : null)],
  receiptDate: [(value) => (_.isEmpty(value) ? `Date required` : null)],
  isSupValid: [(value) => (_.isBoolean(value) ? null : `Is supplier valid ?`)],
  sealNum: [(value) => (_.isEmpty(value) ? `Enter seal number` : null)],
  isSealed: [(value) => (_.isBoolean(value) ? null : `Is container sealed ?`)],
  description: [(value) => (_.isEmpty(value) ? `Enter description` : null)],
  status: [(value) => (_.isEmpty(value) ? `Select status` : null)],
  warehouseId: [(value) => (_.isEmpty(value) ? `Select warehouse name` : null)],
  isSamplingRequired: [
    (value) => (_.isBoolean(value) ? null : `Is sampling required ?`),
  ],
};

interface FormErrors {
  goodsRecType?: string;
  orderNum?: string;
  receiptDate?: string;
  isSupValid?: boolean;
  sealNum?: string;
  isSealed?: boolean;
  description?: string;
  status?: string;
  warehouseId?: string;
  isSamplingRequired?: boolean;
}

const GrnCreateComponent = () => {
  const {
    GRNForm,
    setGRNForm,
    getOrderTypeStringValue,
    productsList,
    orderList,
    updateSelectionOfProducts,
    setSelectedProductIndex,
    setSelectedTransferQty,
    getProductsListForOrder,
    createGRNOrder,
    UpdateGRNOrder,
    pushSelectedProducts,
    getNextReceiptNumberForType,
    ordersList,
    getPOsList,
    getTOsList,
    getROsList,
    productCateList,
    getSupplierInfoByPONumber,
    getAssignedLocationsArray,
    getSupplierInfoByRONumber,
    getSupplierInfoByTONumber,
    setBatchToPalletMapping,
  } = useContext(GrnContext);
  const { warehouseList, getWarehouseMap, deallocateUnusedAislesIfAny } =
    useContext(WarehouseContext);

  const [error, setError] = useState<FormErrors>({});
  const [modeType, setModeType] = useState(FORM_MODES.View);
  const [headerName, setheaderName] = useState(LabelConstants.CreateGRN);
  const [filteredProducts, setFilteredProducts] =
    useState<IProducts[]>(productsList);
  const [searchProduct, setSearchProduct] = useState<any>("");
  const [dialoginfo, setDialoginfo] = useState<IDeleteConfirmationProps>();
  const [supplierName, setsupplierName] = useState("");
  const [warehouseId, setWarehouseId] = useState();

  const [lastProductApiDetails, setLastProductApiDetails] = useState({
    type: "",
    number: "",
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    myModal = new Modal(document.getElementById("deleteConfirmation"), {});
    initData();
  }, []);

  useEffect(() => {
    getSupplierInfo();
  }, [GRNForm?.orderNum]);

  useEffect(() => {
    const filteredList = productsList?.filter((product) => {
      const searchItem = searchProduct.toLowerCase();
      return (
        product.productName.toLowerCase().includes(searchItem) ||
        product.productCode.toLowerCase().includes(searchItem)
      );
    });
    setFilteredProducts(filteredList);
  }, [searchProduct, productsList]);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      ![
        RoutingConstants.grn_batch_create,
        RoutingConstants.grn_batch_edit,
        RoutingConstants.grn_batch_view,
        RoutingConstants.grn_order_view,
        RoutingConstants.grn,
        currentLocation,
      ].includes(nextLocation.pathname)
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      cleanUpInvalidRedirections();
    }
  }, [blocker]);

  const cleanUpInvalidRedirections = async () => {
    showDialog(
      () => {
        blocker.reset();
      },
      async () => {
        if (
          location?.pathname === RoutingConstants.grn_order_create ||
          location?.pathname === RoutingConstants.grn_order_edit
        ) {
          await deallocateUnusedAislesIfAny(
            getAssignedLocationsArray(GRNForm),
            GRNForm?.defaultSelectedShelfs
          );
          setGRNForm(null);
        }
        blocker.proceed();
      },
      "Confirm exit",
      `Exit ?`,
      ""
    );
  };

  const getSupplierInfo = async () => {
    if (GRNForm?.orderNum === "") return;
    let details;
    let supName, supId;
    if (GRNForm?.goodsRecType === "PO") {
      details = await getSupplierInfoByPONumber(GRNForm?.orderNum);
      supName = details?.supName;
      supId = details?.supId;
    } else if (GRNForm?.goodsRecType === "RO") {
      details = await getSupplierInfoByRONumber(GRNForm?.orderNum);
      supName = details?.customerName;
      supId = details?.customerId;
    } else if (GRNForm?.goodsRecType === "TO") {
      details = await getSupplierInfoByTONumber(GRNForm?.orderNum);
      handleGrnForm("warehouseId", details.toWarehouseId);
      const _warehouse = warehouseList.find(
        (_) => _.wareHouseId === details.toWarehouseId
      );
      let warehouseid = "";
      if (!isNullUndefined(_warehouse?.wareHouseId)) {
        warehouseid = _warehouse?.wareHouseId + "";
        getWarehouseMap(_warehouse);
      }
      setWarehouseId(details.toWarehouseId);
    }
    setsupplierName(supName);

    handleGrnForm("supplierId", supId);
  };

  const initData = async () => {
    let mode,
      headername = "";
    if (location?.pathname === RoutingConstants.grn_order_create) {
      headername = LabelConstants.CreateGRN;
      mode = FORM_MODES.Create;
      let number = await getNextReceiptNumberForType(SEQUENCE_PREFIXES.GRN);
      handleGrnForm("goodsReceiptNumber", number);
    } else if (location?.pathname === RoutingConstants.grn_order_edit) {
      headername = LabelConstants.EditGRN;
      mode = FORM_MODES.Edit;
      let isItemAlreadySelected = false;
      productsList?.map((item) => {
        if (item?.selected) {
          isItemAlreadySelected = true;
        }
      });
      if (!isItemAlreadySelected) {
        GRNForm?.grDetails?.map((item) => {
          updateSelectionOfProducts(item?.productInfo?.productId);
        });
      }
    } else if (location?.pathname === RoutingConstants.grn_order_view) {
      mode = FORM_MODES.View;
      headername = LabelConstants.ViewGRN;
    }

    if (location?.pathname !== RoutingConstants.grn_order_create) {
      const _warehouse = warehouseList.find(
        (_) => _.wareHouseId === getNumberForString(GRNForm?.warehouseId)
      );
      if (!isNullUndefined(_warehouse?.wareHouseId)) {
        getWarehouseMap(_warehouse);
      }
    }

    setLastProductApiDetails({
      type: GRNForm?.goodsRecType,
      number: GRNForm?.orderNum,
    });
    setModeType(mode);
    setheaderName(headername);

    if (mode !== FORM_MODES.Create) {
      if (isNullUndefined(GRNForm?.goodsRecType)) {
        exit();
        return;
      }

      handleGrnForm("goodsRecType", GRNForm?.goodsRecType);
    }
  };

  const handleGrnForm = (type: string, value: any) => {
    let _form = cloneDeep(GRNForm);
    if (isNullUndefined(_form)) {
      _form = NewGoodsReceipt;
      _form.grDetails = [];
    }

    if (type === "goodsRecType") {
      if (value === "PO") {
        getPOsList();
      } else if (value === "RO") {
        getROsList(GRNForm.status === "APPROVED" ? GRNForm.orderNum : null);
      } else if (value === "TO") {
        getTOsList(modeType);
      }
      if (modeType === FORM_MODES.Create) {
        _form["supplierId"] = 0;
        _form["orderNum"] = "";
        setsupplierName("");
        _form.grDetails = [];
      }
    }
    _form[type] = value;
    setGRNForm(_form);
  };

  const clearForm = () => {
    setGRNForm(null);
  };

  const onClickSearch = (e) => {
    setSearchProduct(e.target.value);
  };

  const addRemoveProductFromLists = async (_product: IProducts) => {
    await updateSelectionOfProducts(_product.productId);
    pushSelectedProducts(_product.productId);
  };

  const showDialog = (
    onCancel: Function,
    onConfirm: Function,
    header: string,
    title_prefix: string,
    title: string = null
  ) => {
    let dialog: IDeleteConfirmationProps = cloneDeep(
      NewDeleteConfirmationDialog
    );
    dialog.cancel = onCancel;
    dialog.confirm = onConfirm;
    dialog.title = title;
    dialog.header = header;
    dialog.title_prefix = title_prefix;
    setDialoginfo(dialog);
    myModal.show();
  };

  const validateAndRetrieveOrderInfomation = async () => {
    let obj = {
      goodsRecType: GRNForm?.goodsRecType,
      orderNum: GRNForm?.orderNum,
      warehouseId: GRNForm?.warehouseId,
    };

    if (GRNForm?.goodsRecType === "TO") {
      delete obj.warehouseId;
    }

    let isErrors = validator(obj, VALIDATOR);
    setError(isErrors);
    if (_.isEmpty(isErrors)) {
      if (
        lastProductApiDetails?.type !== GRNForm?.goodsRecType ||
        lastProductApiDetails.number !== GRNForm?.orderNum ||
        productsList?.length <= 0
      ) {
        const res = await getProductsListForOrder(
          GRNForm?.goodsRecType,
          GRNForm?.orderNum,
          false,
          modeType
        );
        if (res && res.orderdetails && res.orderdetails.status) {
          handleGrnForm("detailsTO", res.orderdetails);
        }
        handleGrnForm("grDetails", []);
        setLastProductApiDetails({
          type: GRNForm?.goodsRecType,
          number: GRNForm?.orderNum,
        });
      }
      var myModal = new Modal(document.getElementById("modal-right"), {});
      myModal.show();
    }
  };

  const validateAndCreateOrder = async () => {
    let obj;
    if (GRNForm?.goodsRecType === "TO") {
      obj = {
        receiptDate: GRNForm?.receiptDate,
        isSupValid: GRNForm?.isSupValid,
        sealNum: GRNForm?.sealNum,
        isSealed: GRNForm?.isSealed,
        description: GRNForm?.description,
        status: GRNForm?.status,
        isSamplingRequired: GRNForm?.isSamplingRequired,
        goodsRecType: GRNForm?.goodsRecType,
        orderNum: GRNForm?.orderNum,
      };
    } else {
      obj = {
        receiptDate: GRNForm?.receiptDate,
        isSupValid: GRNForm?.isSupValid,
        sealNum: GRNForm?.sealNum,
        isSealed: GRNForm?.isSealed,
        description: GRNForm?.description,
        status: GRNForm?.status,
        warehouseId: GRNForm?.warehouseId,
        isSamplingRequired: GRNForm?.isSamplingRequired,
        goodsRecType: GRNForm?.goodsRecType,
        orderNum: GRNForm?.orderNum,
      };
    }

    let isErrors = validator(obj, VALIDATOR);
    setError(isErrors);

    if (_.isEmpty(isErrors)) {
      if (!validateIfOrderHasProducts()) {
        return;
      }

      if (!validateGRNOrderQuantities()) return;

      let response,
        successmessage = "Order created successfully!!",
        failuremesg = "Order creation failure!!";

      if (location?.pathname === RoutingConstants.grn_order_create)
        response = await createGRNOrder();
      else if (location?.pathname === RoutingConstants.grn_order_edit) {
        successmessage = "Order updated successfully";
        failuremesg = "Order update failure!!";
        response = await UpdateGRNOrder();
      }

      if (response?.result) {
        toast.success(successmessage);
        exit();
      } else {
        toast.error(response?.message ?? failuremesg);
      }
    }
  };

  const validateIfOrderHasProducts = () => {
    let result = false;
    if (GRNForm?.grDetails?.length <= 0) {
      toast.error("Cannot create order without products!!");
      return result;
    }
    if (GRNForm?.grDetails[0]?.product?.length <= 0) {
      toast.error("Cannot create order without batch data!!");
      return result;
    }

    return true;
  };

  const validateGRNOrderQuantities = () => {
    let result = true;
    GRNForm?.grDetails?.map((item) => {
      let actual = item?.productInfo?.quantity;
      let alreadyRecvd = item?.productInfo?.receivedQty ?? 0;
      let assigned = calculateQtyReceived(item) + alreadyRecvd;
      if (assigned > actual) {
        toast.error(
          `Total received quantity ${
            assigned - alreadyRecvd
          } + ${alreadyRecvd} (current + already received) for product ${
            item?.productInfo?.productName
          } is more than order quantity ${actual}!!`
        );
        result = false;
      }
    });
    return result;
  };
  const routeToBatch = () => {
    if (location?.pathname === RoutingConstants.grn_order_create)
      navigate(RoutingConstants.grn_batch_create);
    else if (location?.pathname === RoutingConstants.grn_order_edit)
      navigate(RoutingConstants.grn_batch_edit);
    else if (location?.pathname === RoutingConstants.grn_order_view)
      navigate(RoutingConstants.grn_batch_view);
  };

  const validateAndGoToDetails = async (orderindex: number, prodId) => {
    let obj = {
      warehouseId: GRNForm?.warehouseId,
    };
    let isErrors;
    if (!obj) {
      isErrors = validator(obj, VALIDATOR);
      setError(isErrors);
    }

    if (_.isEmpty(isErrors)) {
      let selectedBatch;

      if (GRNForm?.goodsRecType === "TO") {
        selectedBatch = orderList?.batchDetailsDTOS?.find(
          (batch) => batch.prodId === prodId
        );
      } else if (
        GRNForm?.goodsRecType === "RO" &&
        modeType !== FORM_MODES.View
      ) {
        prepoulateBatchForRO(prodId, orderindex);
      }

      if (selectedBatch) {
        const batchDetails = getBatchData(selectedBatch, "RECEIVED");
        setSelectedTransferQty(selectedBatch?.quantity?.toString());
        setBatchToPalletMapping([batchDetails]);
      } else {
        setBatchToPalletMapping([]);
      }

      setSelectedProductIndex(orderindex);
      routeToBatch();
    }
  };

  const prepoulateBatchForRO = (prodId, orderindex) => {
    const _form = cloneDeep(GRNForm);

    let orderbatchlist = orderList?.returnOrderDetails?.filter(
      (batch) => batch.prodId === prodId
    );
    if (orderbatchlist?.length > 0) {
      let productbatchdata = [];
      orderbatchlist?.map((item) => {
        const batchDetails = getBatchData(item);
        let pd = getBatchProductDetails(batchDetails);
        if (
          GRNForm?.goodsRecType === "RO" &&
          modeType === FORM_MODES.Edit &&
          !isNullUndefined(pd)
        ) {
          batchDetails.palletsCount = pd?.palletsCount;
          batchDetails.palletAssignmentType = pd?.palletAssignmentType;
          batchDetails.prodLocs = pd?.prodLocs;
          batchDetails.goodsRecId = pd?.goodsRecId;
          batchDetails.grDetId = pd?.grDetId;
          batchDetails.status = pd?.status;
          batchDetails.remainingQty = pd?.remainingQty;
        }
        productbatchdata.push(batchDetails);
      });
      _form["grDetails"][orderindex]["product"] = productbatchdata;
      setGRNForm(_form);
    }
  };

  const getBatchProductDetails = (batchItem) => {
    let result;
    const _form = cloneDeep(GRNForm);
    _form?.grDetails?.map((item) => {
      if (item?.productInfo?.productId?.toString() === batchItem?.prodId) {
        item?.product?.map((batchProduct) => {
          if (batchProduct?.batchNo === batchItem?.batchNo) {
            result = batchProduct;
          }
        });
      }
    });
    return result;
  };

  const exit = async () => {
    clearForm();
    navigate(RoutingConstants.grn);
  };

  const getBatchData = (selectedBatch, status = "") => {
    const batchDetails = {
      samplingReq: false,
      goodsRecId: undefined,
      grDetId: undefined,
      prodId: selectedBatch?.prodId?.toString(),
      prodCatId: selectedBatch?.prodCatId?.toString(),
      batchNo: selectedBatch?.batchNo,
      batchExpDate:
        selectedBatch?.batchExpiryDate ?? selectedBatch?.batchExpiry,
      status: status,
      receivedQty:
        selectedBatch?.quantity?.toString() ??
        selectedBatch?.returnQty?.toString(),
      palletsCount: undefined,
      isSamplingReq: false,
      remainingQty: "",
      aisleName: undefined,
      palletAssignmentType: undefined,
      prodLocs: [],
    };
    return batchDetails;
  };

  const calculateQtyReceived = (orderItem: IGRNOrderItem) => {
    let result = 0;
    orderItem?.product?.map((item) => {
      if (isNumeric(item?.receivedQty)) {
        result += parseInt(item?.receivedQty);
      }
    });
    return result;
  };

  return (
    <div className="container-full">
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{LabelConstants.GoodsReceipt}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.OrderIn}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {headerName}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">{LabelConstants.ProductDetails}</h4>
                {modeType !== FORM_MODES.View && (
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      validateAndRetrieveOrderInfomation();
                    }}
                  >
                    Add Products
                  </button>
                )}
              </div>

              <div className="box-body">
                <div className="table-responsive">
                  <table className="table product-overview table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>{LabelConstants.ProductCategory}</th>
                        <th>{LabelConstants.ProductDetails}</th>
                        <th>{LabelConstants.TotalQty}</th>
                        <th>{LabelConstants.ReceivedQty}</th>
                        <th style={{ textAlign: "center" }}>
                          {LabelConstants.BatchDetails}
                        </th>
                        <th style={{ textAlign: "center" }}>
                          {LabelConstants.Action}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {GRNForm?.grDetails?.map(
                        (_orderItem: IGRNOrderItem, _orderItemIdx: number) => {
                          return (
                            <tr>
                              <td>
                                <p>
                                  {`${getProductCatNameById(
                                    _orderItem?.productInfo?.prodCategory,
                                    productCateList
                                  )}`}
                                </p>
                              </td>
                              <td>
                                <p>
                                  {_orderItem?.productInfo?.productName
                                    ? `${_orderItem?.productInfo?.productCode} - ${_orderItem?.productInfo?.productName},${_orderItem?.productInfo?.dosageForm},${_orderItem?.productInfo?.strength},${_orderItem?.productInfo?.packSize}`
                                    : ""}
                                </p>
                              </td>
                              <td>
                                {GRNForm?.goodsRecType === "TO" ? (
                                  <p>
                                    {orderList?.batchDetailsDTOS
                                      ?.find(
                                        (batch) =>
                                          batch.prodId ===
                                          _orderItem.productInfo.productId
                                      )
                                      .quantity.toString()}
                                  </p>
                                ) : (
                                  <p>{_orderItem?.productInfo?.quantity}</p>
                                )}
                              </td>
                              <td>
                                <p>{calculateQtyReceived(_orderItem)}</p>
                              </td>
                              <td align="center">
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-circle btn-danger btn-xs"
                                  onClick={() => {
                                    validateAndGoToDetails(
                                      _orderItemIdx,
                                      _orderItem.productInfo.productId
                                    );
                                  }}
                                >
                                  <i className="ti-arrow-right"></i>
                                </a>
                              </td>
                              <td
                                align="center"
                                style={
                                  modeType === FORM_MODES.View
                                    ? { pointerEvents: "none", opacity: 0.5 }
                                    : {}
                                }
                              >
                                <div className="d-flex justify-content-center">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      showDialog(
                                        () => {},
                                        () => {
                                          addRemoveProductFromLists(
                                            _orderItem?.productInfo
                                          );
                                        },
                                        undefined,
                                        undefined,
                                        _orderItem?.productInfo?.productName
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash text-danger fs-3"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  {modeType !== FORM_MODES.View && (
                    <button
                      className="btn btn-success pull-right"
                      onClick={() => {
                        validateAndCreateOrder();
                      }}
                    >
                      {LabelConstants.Save}
                    </button>
                  )}
                  <button
                    className="btn btn-info"
                    onClick={async () => {
                      if (
                        modeType !== FORM_MODES.View &&
                        !_.isEmpty(GRNForm?.grDetails)
                      ) {
                        showDialog(
                          () => {},
                          async () => {
                            if (
                              location?.pathname ===
                                RoutingConstants.grn_order_create ||
                              location?.pathname ===
                                RoutingConstants.grn_order_edit
                            ) {
                              await deallocateUnusedAislesIfAny(
                                getAssignedLocationsArray(GRNForm),
                                GRNForm?.defaultSelectedShelfs
                              );
                            }
                            exit();
                          },
                          "Confirm exit",
                          `Exit ?`,
                          ``
                        );
                      } else {
                        exit();
                      }
                    }}
                  >
                    {LabelConstants.Cancel}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-lg-4"
            style={
              modeType === FORM_MODES.View ? { pointerEvents: "none" } : {}
            }
          >
            <div className="box">
              <div className="box-header bg-info  p-3">
                {/* <h4 className="box-title">{`#${
                  GRNForm?.goodsReceiptNumber ?? ""
                }`}</h4>
                <h4 className="box-title pull-right">
                  {LabelConstants.Summary}
                </h4> */}
                <h4 className="box-title">GRN Details</h4>
                <p className="p-0 m-0">
                  GRN : {GRNForm?.goodsReceiptNumber ?? "N/A"}
                </p>
              </div>

              <div className="box-body">
                <div className="row">
                  <div className="col-5">
                    <label className="form-label">
                      {LabelConstants.GoodsReceiptType}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className="col-7"
                    style={
                      modeType === FORM_MODES.Edit
                        ? { pointerEvents: "none" }
                        : {}
                    }
                  >
                    <select
                      className="form-select"
                      value={GRNForm?.goodsRecType}
                      onChange={(e) =>
                        handleGrnForm("goodsRecType", e.target.value)
                      }
                    >
                      <option value={""}>Select Goods Receipt Type</option>
                      {GoodReceiptTypes.map(
                        (_supType: any, _supTypeIdx: number) => {
                          return (
                            <option
                              key={`goodReceiptTypes${_supTypeIdx}`}
                              value={_supType.value}
                            >
                              {_supType.text}
                            </option>
                          );
                        }
                      )}
                    </select>
                    {error?.goodsRecType && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.goodsRecType}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div
                  className="row mt-20"
                  style={
                    modeType === FORM_MODES.Edit
                      ? { pointerEvents: "none" }
                      : {}
                  }
                >
                  <div className="col-5">
                    <label className="form-label" htmlFor="orderNum">
                      {getOrderTypeStringValue()?.type}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className="col-7"
                    style={
                      isNullUndefined(GRNForm?.goodsRecType) ||
                      GRNForm?.goodsRecType === ""
                        ? { pointerEvents: "none", opacity: 0.5 }
                        : {}
                    }
                  >
                    <Select
                      value={ordersList?.filter(
                        (option: IOrders) => option.value === GRNForm?.orderNum
                      )}
                      placeholder={getOrderTypeStringValue()?.type}
                      options={ordersList}
                      isSearchable={true}
                      onChange={(e) => {
                        handleGrnForm("orderNum", e?.value);
                      }}
                    />
                    {error?.orderNum && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.orderNum}</span>
                      </label>
                    )}
                  </div>
                </div>
                {GRNForm?.goodsRecType !== "TO" && (
                  <div className="row mt-20">
                    <div className="col-5">
                      <label className="form-label">
                        {getOrderTypeStringValue()?.name}
                      </label>
                    </div>
                    <div className="col-7">
                      <input
                        className="form-control"
                        placeholder={getOrderTypeStringValue()?.name}
                        value={supplierName}
                        disabled
                      />
                    </div>
                  </div>
                )}
                <div className="row mt-20">
                  <div className="col-5">
                    <label className="form-label" htmlFor="receiptDate">
                      {LabelConstants.DateOfReceipt}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className="col-7"
                    style={error?.receiptDate && { borderColor: "red" }}
                  >
                    <DatePicker
                      id="receiptDate"
                      className="form-control bg-white"
                      value={getDateIfValid(GRNForm?.receiptDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleGrnForm("receiptDate", date);
                      }}
                    />
                    {error?.receiptDate && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.receiptDate}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-5">
                    <label className="form-label" htmlFor="status">
                      {LabelConstants.Status}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className="col-7"
                    style={error?.status && { borderColor: "red" }}
                  >
                    <select
                      className="form-select"
                      value={GRNForm?.status}
                      onChange={(e) => handleGrnForm("status", e.target.value)}
                    >
                      <option value={""}>Select Goods Receipt Status</option>
                      {GoodReceiptStatus.map(
                        (_statusType: any, _statusTypeIdx: number) => {
                          return (
                            <option
                              key={`goodReceiptStatus${_statusTypeIdx}`}
                              value={_statusType.value}
                            >
                              {_statusType.text}
                            </option>
                          );
                        }
                      )}
                    </select>
                    {error?.status && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.status}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div
                  className="row mt-20"
                  style={
                    modeType === FORM_MODES.Edit ||
                    (modeType === FORM_MODES.Create &&
                      !isNullUndefined(GRNForm?.warehouseId) &&
                      !_.isEmpty(GRNForm?.warehouseId))
                      ? { pointerEvents: "none" }
                      : {}
                  }
                >
                  <div className="col-5">
                    <label className="form-label">
                      {LabelConstants.WareHouseName}
                      <span className="text-danger">*</span>
                    </label>
                  </div>

                  <div className="col-7">
                    <select
                      className="form-select"
                      value={
                        GRNForm?.goodsRecType === "TO"
                          ? warehouseId
                          : GRNForm?.warehouseId
                      }
                      onChange={(e) => {
                        const _warehouse = warehouseList.find(
                          (_) => _.wareHouseId === Number(e.target.value)
                        );
                        showDialog(
                          () => {},
                          () => {
                            console.log(_warehouse?.wareHouseId);
                            let warehouseid = "";
                            if (!isNullUndefined(_warehouse?.wareHouseId)) {
                              warehouseid = _warehouse?.wareHouseId + "";
                              getWarehouseMap(_warehouse);
                            }
                            handleGrnForm("warehouseId", warehouseid);
                          },
                          "Confirm warehouse selection",
                          `Warehouse once selected cannot be changed -`,
                          `\n ${_warehouse?.warehouseName}`
                        );
                      }}
                      disabled={GRNForm?.goodsRecType === "TO"}
                    >
                      <option value={""}>Select Warehouse Name</option>
                      {warehouseList.map(
                        (_wre: IWarehouse, _wreIdx: number) => {
                          return (
                            <option
                              key={`warehouseOption${_wreIdx}`}
                              value={_wre.wareHouseId}
                            >
                              {_wre.warehouseName}
                            </option>
                          );
                        }
                      )}
                    </select>
                    {error?.warehouseId && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.warehouseId}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-5">
                    <label className="form-label me-2">
                      {LabelConstants.SupplierNameAddressCheck}
                      <span className="text-danger">*</span>
                    </label>
                  </div>

                  <div className="col-7">
                    <div className="row">
                      <div
                        className={
                          "iradio_flat-green" +
                          (GRNForm?.isSupValid ? " checked" : "")
                        }
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          handleGrnForm("isSupValid", true);
                        }}
                      />
                      <label className="col-4">{LabelConstants.Yes}</label>
                      <div
                        className={
                          "iradio_flat-green" +
                          (GRNForm?.isSupValid === false ? " checked" : "")
                        }
                        onClick={() => {
                          handleGrnForm("isSupValid", false);
                        }}
                      />
                      <label className="col-4">{LabelConstants.No}</label>
                    </div>
                    {error?.isSupValid && (
                      <label className="form-label mt-10">
                        <span style={{ color: "red" }}>
                          {error?.isSupValid}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-5">
                    <label className="form-label me-2">
                      {LabelConstants.ContainerSealed}
                      <span className="text-danger">*</span>
                    </label>
                  </div>

                  <div className="col-7">
                    <div className="row">
                      <div
                        className={
                          "iradio_flat-green" +
                          (GRNForm?.isSealed ? " checked" : "")
                        }
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          handleGrnForm("isSealed", true);
                        }}
                      />
                      <label className="col-4">{LabelConstants.Yes}</label>
                      <div
                        className={
                          "iradio_flat-green" +
                          (GRNForm?.isSealed === false ? " checked" : "")
                        }
                        onClick={() => {
                          handleGrnForm("isSealed", false);
                        }}
                      />
                      <label className="col-4">{LabelConstants.No}</label>
                    </div>
                    {error?.isSealed && (
                      <label className="form-label mt-10">
                        <span style={{ color: "red" }}>{error?.isSealed}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-5">
                    <label className="form-label" htmlFor="sealNum">
                      {LabelConstants.ContainerSealNumber}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className="col-7"
                    style={error?.sealNum && { borderColor: "red" }}
                  >
                    <input
                      id="sealNum"
                      type="text"
                      className="form-control"
                      placeholder={LabelConstants.ContainerSealNumber}
                      value={GRNForm?.sealNum}
                      onChange={(e) => handleGrnForm("sealNum", e.target.value)}
                    />
                    {error?.sealNum && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.sealNum}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-5">
                    <label className="form-label me-2">
                      {LabelConstants.IsSamplingRequired}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-7">
                    <div className="row">
                      <div
                        className={
                          "iradio_flat-green" +
                          (GRNForm?.isSamplingRequired ? " checked" : "")
                        }
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          handleGrnForm("isSamplingRequired", true);
                        }}
                      />
                      <label className="col-4">{LabelConstants.Yes}</label>
                      <div
                        className={
                          "iradio_flat-green" +
                          (GRNForm?.isSamplingRequired === false
                            ? " checked"
                            : "")
                        }
                        onClick={() => {
                          handleGrnForm("isSamplingRequired", false);
                        }}
                      />
                      <label className="col-4">{LabelConstants.No}</label>
                    </div>
                    {error?.isSamplingRequired && (
                      <label className="form-label mt-10">
                        <span style={{ color: "red" }}>
                          {error?.isSamplingRequired}
                        </span>
                      </label>
                    )}
                  </div>{" "}
                </div>
                <div className="row mt-20">
                  <div className="col-5">
                    <label className="form-label" htmlFor="description">
                      {LabelConstants.Description}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className="col-7"
                    style={error?.description && { borderColor: "red" }}
                  >
                    <textarea
                      id="description"
                      value={GRNForm?.description}
                      onChange={(e) =>
                        handleGrnForm("description", e.target.value)
                      }
                      rows={5}
                      className="form-control"
                      placeholder={LabelConstants.Description}
                    />
                    {error?.description && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.description}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Modal */}

          <div
            className="modal modal-right fade overflow-auto"
            id="modal-right"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Products List</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body" style={{ overflowY: "auto" }}>
                  <input
                    type="search"
                    placeholder="Search Product"
                    value={searchProduct}
                    className="form-control"
                    onChange={onClickSearch}
                  />
                  <br />
                  <ul className="list-group">
                    {filteredProducts?.length > 0 &&
                      filteredProducts.map((product: IProducts) => (
                        <li
                          className={`list-group-item ${
                            GRNForm?.grDetails?.some(
                              (p: IGRNOrderItem) =>
                                p.productInfo?.productId === product.productId
                            ) && "d-none"
                          }`}
                          key={product.productId}
                        >
                          <input
                            type="checkbox"
                            id={product.productId.toString()}
                            checked={product?.selected} // Set checked based on presence in selectedProducts
                            onChange={() => addRemoveProductFromLists(product)} // Set checked based on presence in selectedProducts
                          />
                          <label htmlFor={product.productId.toString()}>
                            <b>{product.productCode}</b> - {product.productName}
                          </label>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="modal-footer modal-footer-uniform">
                  {/* <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button> */}
                  <button
                    type="button"
                    className="btn btn-primary float-end"
                    data-bs-dismiss="modal"
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Right Modal End*/}
        </div>
      </section>
      <DeleteConfirmationComponent
        title={dialoginfo?.title}
        cancel={dialoginfo?.cancel}
        confirm={dialoginfo?.confirm}
        header={dialoginfo?.header}
        title_prefix={dialoginfo?.title_prefix}
      />
    </div>
  );
};
export default GrnCreateComponent;
