import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import PageSortDescription from "../common/pageSortDescription.component";
import { cloneDeep } from "lodash";
import { useContext, useState } from "react";
import { GenericHelperService } from "../../api/generics/helper.service";
import { PurchaseContext } from "../../controllers/contexts/purchase.context";
import {
  IListConfig,
  IListConfigPO,
  IPurchase,
  IStatusUpdate,
  ISupplier,
} from "../../common/models";
import {
  EmptyStatusUpdate,
  PurchaseStatuses,
} from "../../common/constants/generalConstants";
import PurchaseStatusUpdateComponent from "./purchaseStatusUpdate.component";
import moment from "moment";
import useAuth from "../../controllers/hooks/useAuth";
import LogsModalComponent from "../common/logsModal.component";
const PurchaseOrderComponent = () => {
  // const { NewPurchaseOrder} = useContext(PurchaseContext)
  const helperService: GenericHelperService = new GenericHelperService();
  const {
    purchaseOrderList,
    filters,
    selectedList,
    setSelectedList,
    setFilters,
    purchaseForm,
    setPurchaseForm,
    newPurchaseOrder,
    deletePurchase,
    viewPurchase,
    supplierList,
    suppliers,
    pofilters,
    setPOFilters,
    getPurchaseLogs,
    purchaseLogs,
    productsList,
    productCateList,
    queryPOData,
  } = useContext(PurchaseContext);
  console.log("PurchaseOrderList", purchaseOrderList);
  const navigate = useNavigate();
  const { isAccessDisabled } = useAuth();
  const [tempStatus, setTempStatus] = useState("");
  const [createdDate, setcreatedDate] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const [showLogsModal, setShowLogsModal] = useState(false);
  const handleShowLogsModal = (_po: IPurchase) => {
    getPurchaseLogs(_po.poNum);
    setShowLogsModal(true);
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };

  const searchQueryChange = (event: any) => {
    const _searchQuery = event.target.value;
    setFilters({ ...filters, searchQuery: _searchQuery });
  };

  const setUpdateStatus = (prod: IPurchase) => {
    setTempStatus(prod.status);
    const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
    _status.ids = [prod.poNum];
    _status.status = prod.status;
    setSelectedList(_status);
  };

  const handleSort = (column: string, isDate: boolean = false) => {
    const _filters: IListConfig = cloneDeep(filters);
    if (_filters.sortItem === column) {
      _filters.sortDirection =
        _filters.sortDirection === "asc" ? "desc" : "asc";
      _filters.sortIsDate = isDate;
    } else {
      _filters.sortItem = column;
      _filters.sortDirection = "asc";
      _filters.sortIsDate = isDate;
    }
    setFilters(_filters);
  };

  const handleQueryChange = (type: string, value: any) => {
    const _pofilter: IListConfigPO = cloneDeep(pofilters);
    _pofilter[type] = value;
    setPOFilters(_pofilter);
  };

  const clearSearch = () => {
    const _pofilter: IListConfigPO = cloneDeep(pofilters);
    _pofilter.poNum = "";
    _pofilter.status = [];
    _pofilter.supplier = 0;
    _pofilter.createdDateFrom = "";
    _pofilter.createdDateTo = "";
    _pofilter.exDeliveryDateFrom = "";
    _pofilter.exDeliveryDateTo = "";
    _pofilter.search = false;
    setPOFilters(_pofilter);
  };

  const checkIfSearchStringsAreEmpty = () => {
    let result = false;
    if (
      pofilters?.poNum === "" &&
      pofilters?.status?.length <= 0 &&
      pofilters?.supplier === 0 &&
      pofilters?.createdDateFrom === "" &&
      pofilters?.createdDateTo === "" &&
      pofilters?.exDeliveryDateFrom === "" &&
      pofilters?.exDeliveryDateTo === ""
    ) {
      result = true;
    }
    return result;
  };

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
      _status.ids = purchaseOrderList.map((_prd) => _prd.poNum);
      setSelectedList(_status);
    } else {
      setSelectedList(cloneDeep(EmptyStatusUpdate));
    }
  };

  const handleSelectItem = (item: IPurchase, event: any) => {
    const _status: IStatusUpdate = cloneDeep(selectedList);
    if (event.target.checked) {
      _status.ids.push(item.poNum);
    } else {
      const _itmIdx = _status.ids.findIndex((_itm) => _itm === item.poNum);
      if (_itmIdx > -1) {
        _status.ids.splice(_itmIdx, 1);
      }
    }
    setSelectedList(_status);
  };

  const cancelDelete = () => {
    setPurchaseForm(null);
  };

  const confirmDelete = () => {
    deletePurchase();
  };
  console.log("sfhvihsfiv", filters.searchQuery, selectedList);
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{LabelConstants.Customer}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.OrderIn}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {LabelConstants.PurchaseOrder}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <div className="row">
                  <div className="col-lg-10 d-flex">
                    <div className="input-group w-p40 ms-10">
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="PO number"
                        value={pofilters?.poNum}
                        onChange={(event) =>
                          handleQueryChange("poNum", event.target.value)
                        }
                      />
                    </div>

                    <div className="input-group w-p45 ms-10">
                      <select
                        className="form-select"
                        value={pofilters?.status}
                        onChange={(e) =>
                          handleQueryChange("status", [e.target.value])
                        }
                      >
                        <option value={""}>PO Status</option>
                        {PurchaseStatuses?.map(
                          (_statusType: any, _statusTypeIdx: number) => {
                            return (
                              <option
                                key={`poStatus${_statusTypeIdx}`}
                                value={_statusType}
                              >
                                {_statusType}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>

                    <div className="input-group w-p60 ms-10">
                      <select
                        className="form-select"
                        value={pofilters?.supplier}
                        onChange={(e) =>
                          handleQueryChange("supplier", e.target.value)
                        }
                      >
                        <option value={""}>Supplier name</option>
                        {supplierList?.map(
                          (_supplier: ISupplier, _supplierIdx: number) => {
                            return (
                              <option
                                key={_supplierIdx}
                                value={_supplier?.supId}
                              >
                                {_supplier?.supName}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>

                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Created From"
                        value={pofilters?.createdDateFrom}
                        onChange={(event) =>
                          handleQueryChange(
                            "createdDateFrom",
                            event.target.value
                          )
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        Created From
                      </i>
                    </div>

                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Created To"
                        value={pofilters?.createdDateTo}
                        onChange={(event) =>
                          handleQueryChange("createdDateTo", event.target.value)
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        Created To
                      </i>
                    </div>

                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Delivery From"
                        value={pofilters?.exDeliveryDateFrom}
                        onChange={(event) =>
                          handleQueryChange(
                            "exDeliveryDateFrom",
                            event.target.value
                          )
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        Delivery From
                      </i>
                    </div>

                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Delivery To"
                        value={pofilters?.exDeliveryDateTo}
                        onChange={(event) =>
                          handleQueryChange(
                            "exDeliveryDateTo",
                            event.target.value
                          )
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        Delivery To
                      </i>
                    </div>

                    <div className="input-group w-p10 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success rounded"
                        disabled={checkIfSearchStringsAreEmpty()}
                        onClick={() => {
                          setFilters({
                            ...filters,
                            currentPage: 1,
                          });
                          if (pofilters?.search) {
                            queryPOData();
                          }
                          handleQueryChange("search", true);
                        }}
                      >
                        {LabelConstants.Search}
                      </button>
                    </div>
                    <div className="input-group w-p50 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success-light rounded"
                        onClick={() => {
                          clearSearch();
                        }}
                      >
                        {LabelConstants.Clear}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-2 d-flex justify-content-end">
                    <button
                      type="button"
                      disabled={isAccessDisabled("OI", "create", true, "PO")}
                      className="waves-effect waves-light btn btn-success rounded"
                      onClick={() => {
                        newPurchaseOrder();
                        navigate(RoutingConstants.purchaseCreate);
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      {`${LabelConstants.Create} ${LabelConstants.PurchaseOrder}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="table-responsive">
                  <table
                    className="table table-hover table-bordered mb-0"
                    style={{ textAlign: "center" }}
                  >
                    <thead className="table-primary">
                      <tr>
                        {/* <th>
                        <input type="checkbox" id={`md_checkbox_all`} className="chk-col-primary" checked={purchaseOrderList?.length > 0 && selectedList?.ids.length === purchaseOrderList?.length} onChange={(event) => handleSelectAll(event)} />
                        <label htmlFor={`md_checkbox_all`}></label>
                      </th> */}
                        <th>S.No</th>
                        <th onClick={() => handleSort("refNumber")}>
                          {"Ref No."}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "refNumber"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("poCreatedDate")}>
                          {"Created Date"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "poCreatedDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("expDelDate")}>
                          {"Exp Delivery Date"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "expDelDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("purchaseOrderNumber")}>
                          {"PO Num"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "purchaseOrderNumber"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("amount")}>
                          {"Amount"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "amount"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("taxAmount")}>
                          {"Tax"}
                          <i className={`ms-2 fa fa-sort$`} />
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "taxAmount"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("carriageAmount")}>
                          {"Carriage"}
                          <i className={`ms-2 fa fa-sort$`} />
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "carriageAmount"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("modifiedDate")}>
                          {"Modified Date"}
                          <i className={`ms-2 fa fa-sort$`} />
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "modifiedDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("supplierName")}>
                          {"Supplier Name"}
                          <i className={`ms-2 fa fa-sort$`} />
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "supplierName"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("status")}>
                          {"Status"}
                          <i className={`ms-2 fa fa-sort$`} />
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "status"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseOrderList &&
                        purchaseOrderList.map((_po, _poIdx) => {
                          return (
                            <tr key={_poIdx}>
                              {/* <td>
                          <input type="checkbox" id={`md_checkbox_${_poIdx}`} className="chk-col-primary" checked={selectedList.ids.findIndex(_itm => _itm === _po?.poNum) >= 0} onChange={(event) => handleSelectItem(_po, event)} />
                          <label htmlFor={`md_checkbox_${_poIdx}`}></label>
                        </td> */}
                              {/* <td>{_po?.poNum}</td> */}
                              <td>
                                {(filters?.currentPage - 1) *
                                  filters?.currentPageSize +
                                  _poIdx +
                                  1}
                              </td>
                              <td>{_po?.refNumber}</td>
                              <td>
                                {moment(_po?.poCreatedDate).format(
                                  "DD/MM/yyyy"
                                )}
                              </td>
                              <td>
                                {moment(_po?.expDelDate).format("DD/MM/yyyy")}
                              </td>
                              <td>{_po?.purchaseOrderNumber}</td>
                              <td>
                                {(_po?.amount).toFixed(2).replace(/\.00$/, "")}
                              </td>
                              <td>
                                {(_po?.taxAmount)
                                  .toFixed(2)
                                  .replace(/\.00$/, "")}
                              </td>
                              <td>{_po?.carriageAmount}</td>
                              <td>
                                {moment(_po?.modifiedDate).format("DD/MM/yyyy")}
                              </td>
                              <td>{_po?.supplierName ?? ""}</td>
                              <td>
                                <span
                                  style={
                                    isAccessDisabled(
                                      "OI",
                                      "update_status",
                                      true,
                                      "PO"
                                    )
                                      ? { pointerEvents: "none", opacity: 0.5 }
                                      : {}
                                  }
                                  role="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#updateStatus"
                                  onClick={() => setUpdateStatus(_po)}
                                  className={`badge bg-${helperService.getStatusColor(
                                    _po.status
                                  )}-light rounded-pill`}
                                >
                                  {_po.status}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex justify-content-center">
                                  <button
                                    type="button"
                                    disabled={isAccessDisabled(
                                      "OI",
                                      "view",
                                      true,
                                      "PO"
                                    )}
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                    onClick={() => {
                                      viewPurchase(_po);
                                      navigate(
                                        RoutingConstants.purchaseDetails,
                                        { state: { isEditable: false } }
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                    disabled={
                                      _po.status !== "DRAFT" ||
                                      isAccessDisabled("OI", "edit", true, "PO")
                                    }
                                    onClick={() => {
                                      viewPurchase(_po);
                                      navigate(
                                        RoutingConstants.purchaseDetails,
                                        { state: { isEditable: true } }
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    // disabled={isAccessDisabled(
                                    //   "OI",
                                    //   "print",
                                    //   true,
                                    //   "PO"
                                    // )}
                                    onClick={() => {
                                      navigate(RoutingConstants.purchasePrint, {
                                        state: { selectedItem: _po, suppliers: suppliers },
                                      });
                                    }}
                                  >
                                    <i
                                      className="fa fa-print"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    disabled={
                                      _po.status !== "DRAFT" ||
                                      isAccessDisabled(
                                        "OI",
                                        "delete",
                                        true,
                                        "PO"
                                      )
                                    }
                                    onClick={() => {
                                      setPurchaseForm(cloneDeep(_po));
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteConfirmation"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    onClick={() => {
                                      setcreatedDate(_po?.createdDate);
                                      setcreatedBy(_po?.createdBy);
                                      handleShowLogsModal(_po);
                                    }}
                                  >
                                    <i
                                      className="fa fa-book"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <PageSortDescription
                      filters={filters}
                      dataList={purchaseOrderList}
                    />
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    {filters.totalPageCount > 1 && (
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="productorder_paginate"
                      >
                        <ReactPaginate
                          forcePage={filters?.currentPage - 1}
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={
                            filters && filters.totalPageCount < 2
                              ? 0
                              : filters.totalPageCount
                          }
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          pageClassName={`paginate_button page-item`}
                          pageLinkClassName="page-link"
                          activeLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          breakClassName={`paginate_button page-item`}
                          disabledClassName={`disabled`}
                          previousClassName={`paginate_button page-item previous`}
                          nextClassName={`paginate_button page-item next`}
                          onPageChange={(e) => {
                            setFilters({
                              ...filters,
                              currentPage: e.selected + 1,
                            });
                          }}
                          containerClassName={`pagination mb-1`}
                          activeClassName={`active`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PurchaseStatusUpdateComponent tempStatus={tempStatus} />
      <DeleteConfirmationComponent
        title={
          purchaseForm?.purchaseOrderNumber
            ? purchaseForm.purchaseOrderNumber.toString()
            : ""
        }
        cancel={cancelDelete}
        confirm={confirmDelete}
      />
      <LogsModalComponent
        onOpen={showLogsModal}
        onClose={handleCloseLogsModal}
        logs={purchaseLogs}
        productsList={productsList}
        productCateList={productCateList}
        createdDate={createdDate}
        createdBy={createdBy}
      />
    </>
  );
};

export default PurchaseOrderComponent;
