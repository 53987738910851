import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import PageSortDescription from "../common/pageSortDescription.component";
import { cloneDeep } from "lodash";
import { useContext, useState } from "react";
import { GenericHelperService } from "../../api/generics/helper.service";
import { IListConfig, IListConfigSO, IStatusUpdate } from "../../common/models";
import {
  EmptyStatusUpdate,
  SalesOrderUpdateStatuses,
} from "../../common/constants/generalConstants";
import moment from "moment";
import { SalesOrderContext } from "../../controllers/contexts/salesorder.context";
import { ISalesOrder } from "../../common/models/salesorder";
import SalesOrderStatusUpdateComponent from "./sales_order_status_update.component";
import useAuth from "../../controllers/hooks/useAuth";
import LogsModalComponent from "../common/logsModal.component";

const SalesOrderComponent = () => {
  const helperService: GenericHelperService = new GenericHelperService();
  const {
    salesOrderList,
    filters,
    setSelectedList,
    setFilters,
    SOForm,
    setSalesOrderForm,
    newSalesOrderForm,
    deleteSalesOrderItem,
    viewSalesOrder,
    sofilters,
    setSOFilters,
    salesOrderLogs,
    getSalesOrderLogs,
    productCateList,
    productsList,
    customersList,
    querySOList,
  } = useContext(SalesOrderContext);
  console.log("salesOrderList", salesOrderList);
  const navigate = useNavigate();
  const { isAccessDisabled } = useAuth();

  const [tempStatus, setTempStatus] = useState("");
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [createdDate, setcreatedDate] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const handleShowLogsModal = (_po: ISalesOrder) => {
    getSalesOrderLogs(_po.id);
    setShowLogsModal(true);
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };

  const handleQueryChange = (type: string, value: any) => {
    const _sofilters: IListConfigSO = cloneDeep(sofilters);
    _sofilters[type] = value;
    setSOFilters(_sofilters);
  };

  const clearSearch = () => {
    const _sofilters: IListConfigSO = cloneDeep(sofilters);
    _sofilters.search = false;
    _sofilters.status = [];
    _sofilters.soNum = "";
    _sofilters.status = [];
    _sofilters.customerId = 0;
    _sofilters.customerName = "";
    _sofilters.createdFrom = "";
    _sofilters.createdTo = "";
    _sofilters.deliveryDateFrom = "";
    _sofilters.deliveryDateTo = "";
    _sofilters.customerRefNum = "";
    setSOFilters(_sofilters);
  };

  const checkIfSearchStringsAreEmpty = () => {
    let result = false;
    if (
      sofilters.soNum === "" &&
      sofilters.status?.length <= 0 &&
      (sofilters.customerId === null || sofilters.customerId === 0) &&
      sofilters.customerName === "" &&
      sofilters.createdFrom === "" &&
      sofilters.createdTo === "" &&
      sofilters.deliveryDateFrom === "" &&
      sofilters.deliveryDateTo === "" && 
      sofilters.customerRefNum == ""
    ) {
      result = true;
    }
    return result;
  };

  const searchQueryChange = (event: any) => {
    const _searchQuery = event.target.value;
    setFilters({ ...filters, searchQuery: _searchQuery });
  };

  const setUpdateStatus = (prod: ISalesOrder) => {
    setTempStatus(prod.status);
    const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
    _status.ids = [prod?.id];
    _status.status = prod?.status;
    setSelectedList(_status);
  };

  const handleSort = (column: string, isDate: boolean = false) => {
    const _filters: IListConfig = cloneDeep(filters);
    if (_filters.sortItem === column) {
      _filters.sortDirection =
        _filters.sortDirection === "asc" ? "desc" : "asc";
      _filters.sortIsDate = isDate;
    } else {
      _filters.sortItem = column;
      _filters.sortDirection = "asc";
      _filters.sortIsDate = isDate;
    }
    setFilters(_filters);
  };

  const getLocation = (po) => {
    const customer = customersList.find(so => so.custId === po.customerId);
    if (customer) {
      const address = customer.custAddresses.find(_so => _so.custAddressId === po.addressId);
      if (address) {
        return address.county;
      }
    }
    return "";
  };

  const cancelDelete = () => {
    setSalesOrderForm(null);
  };

  const confirmDelete = () => {
    deleteSalesOrderItem();
  };
  // console.log("sfhvihsfiv", filters.searchQuery, selectedList);
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{LabelConstants.Customer}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {LabelConstants.OrderOut}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {LabelConstants.SalesOrder}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <div className="row">
                  <div className="col-lg-10 d-flex">
                    <div className="input-group w-p40 ms-10">
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="SO Num"
                        value={sofilters?.soNum}
                        onChange={(event) =>
                          handleQueryChange("soNum", event.target.value)
                        }
                      />
                    </div>
                    <div className="input-group w-p45 ms-10">
                      <select
                        className="form-select"
                        value={sofilters?.status}
                        onChange={(e) =>
                          handleQueryChange("status", [e.target.value])
                        }
                      >
                        <option value={""}>Status</option>
                        {SalesOrderUpdateStatuses.map(
                          (_statusType: any, _statusTypeIdx: number) => {
                            return (
                              <option
                                key={`Status${_statusTypeIdx}`}
                                value={_statusType}
                              >
                                {_statusType}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className="input-group w-p40 ms-10">
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="Ref No"
                        value={sofilters?.customerRefNum}
                        onChange={(event) =>
                          handleQueryChange("customerRefNum", event.target.value)
                        }
                      />
                    </div>
                    <div className="input-group w-p40 ms-10">
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="Customer Name"
                        value={sofilters?.customerName}
                        onChange={(event) =>
                          handleQueryChange("customerName", event.target.value)
                        }
                      />
                    </div>

                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Create From"
                        value={sofilters?.createdFrom}
                        onChange={(event) =>
                          handleQueryChange("createdFrom", event.target.value)
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        Created From
                      </i>
                    </div>
                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Created To"
                        value={sofilters?.createdTo}
                        onChange={(event) =>
                          handleQueryChange("createdTo", event.target.value)
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        Created To
                      </i>
                    </div>

                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Delivery From"
                        value={sofilters?.deliveryDateFrom}
                        onChange={(event) =>
                          handleQueryChange(
                            "deliveryDateFrom",
                            event.target.value
                          )
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        Delivery From
                      </i>
                    </div>
                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Delivery To"
                        value={sofilters?.deliveryDateTo}
                        onChange={(event) =>
                          handleQueryChange(
                            "deliveryDateTo",
                            event.target.value
                          )
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        Delivery To
                      </i>
                    </div>
                    <div className="input-group w-p10 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success rounded"
                        disabled={checkIfSearchStringsAreEmpty()}
                        onClick={() => {
                          setFilters({
                            ...filters,
                            currentPage: 1,
                          });
                          if (sofilters?.search) {
                            querySOList();
                          }
                          handleQueryChange("search", true);
                        }}
                      >
                        {LabelConstants.Search}
                      </button>
                    </div>
                    <div className="input-group w-p50 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success-light rounded"
                        onClick={() => {
                          clearSearch();
                        }}
                      >
                        {LabelConstants.Clear}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-2 d-flex justify-content-end">
                    <button
                      type="button"
                      disabled={isAccessDisabled("OT", "create", true, "SO")}
                      className="waves-effect waves-light btn btn-success rounded"
                      onClick={() => {
                        newSalesOrderForm();
                        navigate(RoutingConstants.sales_order_create);
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      {`${LabelConstants.Create} ${LabelConstants.SalesOrder}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="table-responsive">
                  <table
                    className="table table-hover table-bordered mb-0"
                    style={{ textAlign: "center" }}
                  >
                    <thead className="table-primary">
                      <tr>
                        <th>S.No</th>
                        <th onClick={() => handleSort("refNumber")}>
                          {"Ref No."}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "refNumber"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("soCreatedDate")}>
                          {"Created Date"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "soCreatedDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("expDelDate")}>
                          {"Exp Delivery Date"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "expDelDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("salesOrderNumber")}>
                          {"SO Num"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "salesOrderNumber"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("customerName")}>
                          {"Customer Name"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "customerName"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={ () => handleSort("location")}>
                            {"Customer Location"}
                            <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "location"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("amount")}>
                          {"Amount"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "amount"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("carrierChargeAmount")}>
                          {"Carriage"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "carrierChargeAmount"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("taxAmount")}>
                          {"Tax"}
                          <i className={`ms-2 fa fa-sort$`} />
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "taxAmount"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("modifiedDate")}>
                          {"Modified Date"}
                          <i className={`ms-2 fa fa-sort$`} />
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "modifiedDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("status")}>
                          {"Status"}
                          <i className={`ms-2 fa fa-sort$`} />
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "status"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salesOrderList?.map((_po: ISalesOrder, _poIdx) => {
                        return (
                          <tr key={_poIdx}>
                            <td>
                              {(filters?.currentPage - 1) *
                                filters?.currentPageSize +
                                _poIdx +
                                1}
                            </td>{" "}
                            <td>{_po?.customerRefNum}</td>
                            <td>
                              {moment(_po?.soCreatedDate).format("DD/MM/yyyy")}
                            </td>
                            <td>
                              {moment(_po?.deliveryDate).format("DD/MM/yyyy")}
                            </td>
                            <td>{_po?.soNum}</td>
                            <td>{_po?.customerName}</td>
                            <td>{getLocation(_po)}</td>
                            <td>{_po?.totalSaleOrderAmount}</td>
                            <td>{_po?.carrierChargeAmount}</td>
                            <td>{_po?.totalTaxAmount}</td>
                            <td>
                              {moment(_po?.modifiedDate).format("DD/MM/yyyy")}
                            </td>
                            <td>
                              <span
                                style={
                                  isAccessDisabled(
                                    "OT",
                                    "update_status",
                                    true,
                                    "SO"
                                  )
                                    ? { pointerEvents: "none", opacity: 0.5 }
                                    : {}
                                }
                                role="button"
                                data-bs-toggle="modal"
                                data-bs-target="#updateStatus"
                                onClick={() => setUpdateStatus(_po)}
                                className={`badge bg-${helperService.getStatusColor(
                                  _po.status
                                )}-light rounded-pill`}
                              >
                                {_po.status}
                              </span>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <button
                                  type="button"
                                  disabled={isAccessDisabled(
                                    "OT",
                                    "view",
                                    true,
                                    "SO"
                                  )}
                                  className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                  onClick={async () => {
                                    await viewSalesOrder(_po, () => {
                                      navigate(
                                        RoutingConstants.sales_order_view,
                                        {
                                          state: { isEditable: false },
                                        }
                                      );
                                    });
                                  }}
                                >
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  disabled={
                                    _po?.status !== "DRAFT" ||
                                    isAccessDisabled("OT", "edit", true, "SO")
                                  }
                                  style={
                                    _po?.status === "SUBMITTED"
                                      ? { cursor: "no-drop" }
                                      : {}
                                  }
                                  className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                  onClick={async () => {
                                    if (_po?.status === "SUBMITTED") {
                                      return;
                                    }
                                    await viewSalesOrder(_po, () => {
                                      navigate(
                                        RoutingConstants.sales_order_edit,
                                        {
                                          state: { isEditable: true },
                                        }
                                      );
                                    });
                                  }}
                                >
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                  // disabled={isAccessDisabled(
                                  //   "OT",
                                  //   "print",
                                  //   true,
                                  //   "SO"
                                  // )}
                                  onClick={() => {
                                    navigate(
                                      RoutingConstants.sales_order_print,
                                      {
                                        state: { selectedItem: _po },
                                      }
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-print"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                {_po?.status === "DRAFT" ? (
                                  <button
                                    type="button"
                                    disabled={
                                      _po?.status !== "DRAFT" ||
                                      isAccessDisabled(
                                        "OT",
                                        "delete",
                                        true,
                                        "SO"
                                      )
                                    }
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    onClick={() => {
                                      setSalesOrderForm(cloneDeep(_po));
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteConfirmation"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    style={{ cursor: "no-drop" }}
                                    disabled={isAccessDisabled(
                                      "OT",
                                      "delete",
                                      true,
                                      "SO"
                                    )}
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    onClick={() => {}}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                  onClick={() => {
                                    setcreatedDate(_po?.createdDate);
                                    setcreatedBy(_po?.createdBy);
                                    handleShowLogsModal(_po);
                                  }}
                                >
                                  <i
                                    className="fa fa-book"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <PageSortDescription
                      filters={filters}
                      dataList={salesOrderList}
                    />
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    {filters.totalPageCount > 1 && (
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="productorder_paginate"
                      >
                        <ReactPaginate
                          forcePage={filters?.currentPage - 1}
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={
                            filters && filters.totalPageCount < 2
                              ? 0
                              : filters.totalPageCount
                          }
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          pageClassName={`paginate_button page-item`}
                          pageLinkClassName="page-link"
                          activeLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          breakClassName={`paginate_button page-item`}
                          disabledClassName={`disabled`}
                          previousClassName={`paginate_button page-item previous`}
                          nextClassName={`paginate_button page-item next`}
                          onPageChange={(e) => {
                            setFilters({
                              ...filters,
                              currentPage: e.selected + 1,
                            });
                          }}
                          containerClassName={`pagination mb-1`}
                          activeClassName={`active`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SalesOrderStatusUpdateComponent tempStatus={tempStatus} />
      <DeleteConfirmationComponent
        title={SOForm?.soNum ? SOForm?.soNum.toString() : ""}
        cancel={cancelDelete}
        confirm={confirmDelete}
      />
      <LogsModalComponent
        onOpen={showLogsModal}
        onClose={handleCloseLogsModal}
        logs={salesOrderLogs}
        productsList={productsList}
        productCateList={productCateList}
        createdDate={createdDate}
        createdBy={createdBy}
      />
    </>
  );
};

export default SalesOrderComponent;
