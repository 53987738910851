import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import {
  getDateIfValid,
  getProductCatNameById,
  getProductNameById,
  isNullUndefined,
  isNullUndefinedEmpty,
} from "../../common/methods/globalmethods";
import { OrderPickingContext } from "../../controllers/contexts/orderpicking.context";
import { DatePicker } from "antd";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { toast } from "react-toastify";
import { Autocomplete, TextField } from "@mui/material";
import { Modal } from "bootstrap";
import {
  CancelLocationDetails,
  SaveNewLocationDetails,
} from "../../common/models/pickingorder";
import moment from "moment";
import {
  ERROR,
  saveNewLocationDetailsObject,
  SUCCESS,
} from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";

var myModal;
var currentSelectedIndex = -1;

var LOCATION_TYPES = {
  FREE: "Free",
  AUTO: "Auto",
  SAME_LOCATION: "SAME_LOCATION",
  NO_SELECTION: "NO_SELECTION",
  EMPTY_LOCATION: "EMPTY_LOCATION",
};

const OrderPickingCancelComponent = () => {
  const location = useLocation();
  const orderPickingData = location.state.op;
  const {
    productsList,
    productCateList,
    salesOrdersListAll,
    getCancelLocationDetails,
    updateLocationQtyCancel,
    cancelOrder,
    updateLocationDetails,
    getCancelledPrefLocationDetails,
  } = useContext(OrderPickingContext);
  const { warehouseMap, warehouseList } = useContext(WarehouseContext);
  const [updateOP, setUpdateOP] = useState<any>(orderPickingData);
  const [cancelLocData, setcancelLocData] = useState<CancelLocationDetails[]>(
    []
  );
  const navigate = useNavigate();

  useEffect(() => {
    myModal = new Modal(document.getElementById("modal-right"), {
      backdrop: "static",
      keyboard: false,
    });
  }, []);

  const shelfIds = warehouseMap.reduce((acc, aisle) => {
    aisle.bays.forEach((bay) => {
      bay.baysLocations.forEach((location) => {
        if (location.status === "INUSE") {
          acc.push({
            value: location.shelfId,
            label: location.shelfId,
            locationID: location?.locId,
          });
        }
      });
    });
    return acc;
  }, []);

  const performValidations = () => {
    let data = cloneDeep(updateOP);
    let status = SUCCESS;
    if (isNullUndefinedEmpty(data?.cancelComments)) {
      toast.error("Comments cannot be empty!!");
      return;
    }
    data?.orderPickingDetails?.map((orderItem) => {
      if (
        isNullUndefinedEmpty(orderItem?.assignedLocType) ||
        orderItem?.assignedLocType === LOCATION_TYPES.NO_SELECTION
      ) {
        toast.error(
          `Please select a valid location for batch ${orderItem?.batchNo} to Cancel!!`
        );
        status = ERROR;
        return;
      }

      if (orderItem?.assignedLocType === LOCATION_TYPES?.FREE) {
        if (
          isNullUndefinedEmpty(orderItem?.transferShelfId) ||
          isNullUndefinedEmpty(orderItem?.palletRefNum)
        ) {
          let message = `Select a valid available location for batch ${orderItem?.batchNo}!!`;
          if (isNullUndefinedEmpty(orderItem?.palletRefNum)) {
            message = `Pallet Ref No cannot be empty for batch ${orderItem?.batchNo}!!`;
          }
          toast.error(message);
          status = ERROR;
          return;
        }
      }

      if (orderItem?.assignedLocType === LOCATION_TYPES?.AUTO) {
        if (isNullUndefinedEmpty(orderItem?.autoLocationDetails)) {
          toast.error(
            `Please select location from Auto/Free for batch ${orderItem?.batchNo} to Cancel!!`
          );
          status = ERROR;
          return;
        }
      }

      if (orderItem?.assignedLocType === LOCATION_TYPES?.EMPTY_LOCATION) {
        if (isNullUndefinedEmpty(orderItem?.palletRefNum)) {
          toast.error(
            `Enter a valid Pallet Ref No for batch ${orderItem?.batchNo}!!`
          );
          status = ERROR;
          return;
        }
      }
    });
    return status;
  };

  const buildSaveObject = (_prod) => {
    let array = [];
    let data: SaveNewLocationDetails = cloneDeep(saveNewLocationDetailsObject);
    data.locationId = _prod?.locationId;
    data.productInventory.id = _prod?.id;
    data.productInventory.locationId = _prod?.transferLocId;
    data.productInventory.warehouseId = updateOP?.warehouseId;
    data.productInventory.shelfId = _prod?.transferShelfId;
    data.productInventory.batchNo = _prod?.batchNo;
    data.productInventory.batchExpiryDate = _prod?.batchExpiry;
    data.productInventory.prodId = _prod?.prodId;
    data.productInventory.prodCatId = _prod?.prodCatId;
    data.productInventory.quantity = _prod?.pickingQty;
    data.productInventory.status = "REL";
    data.productInventory.palletRefNum = _prod?.palletRefNum;
    array.push(data);
    return array;
  };

  const performRowCancelCall = async () => {
    let result;
    let data = cloneDeep(updateOP);
    for (let i = 0; i < data?.orderPickingDetails?.length; i++) {
      let _prod = data?.orderPickingDetails[i];
      if (
        [LOCATION_TYPES.FREE, LOCATION_TYPES.EMPTY_LOCATION]?.includes(
          _prod?.assignedLocType
        )
      ) {
        let obj = buildSaveObject(_prod);
        result = await updateLocationDetails(obj);
      } else if (
        [LOCATION_TYPES.SAME_LOCATION]?.includes(_prod?.assignedLocType)
      ) {
        result = await updateLocationQtyCancel(_prod?.newId, _prod?.pickingQty);
      } else if ([LOCATION_TYPES.AUTO]?.includes(_prod?.assignedLocType)) {
        result = await updateLocationQtyCancel(
          _prod?.autoLocationDetails?.id,
          _prod?.pickingQty
        );
      }
      if (result?.status !== SUCCESS) {
        break;
      }
    }

    return result;
  };

  const onCancelOrder = async () => {
    if (performValidations() !== SUCCESS) {
      return;
    }

    let result = await performRowCancelCall();
    if (result?.status !== SUCCESS) {
      toast.error(result?.message ?? "Error cancelling the order!!");
      return;
    }

    cancelOrder(updateOP?.id, updateOP?.cancelComments).then((result: any) => {
      if (result?.status === SUCCESS) {
        toast.success(result?.message);
        navigate(RoutingConstants.orderPickingList);
        return;
      } else {
        toast.error(result?.message ?? "Error cancelling the order!!");
      }
    });
  };

  const handleCommentsChange = (e: any) => {
    let _updateOP = { ...updateOP };
    _updateOP.cancelComments = e.target.value;
    setUpdateOP(_updateOP);
  };

  const handlePickedQty = (prod: any, e: any) => {
    if (e.target.value <= prod.availableQty) {
      let _updateOP = { ...updateOP };
      _updateOP.orderPickingDetails.map((_loc) => {
        if (_loc.locationId === prod.locationId) {
          _loc.pickingQty = e.target.value;
        }
      });
      setUpdateOP(_updateOP);
    } else {
      toast.error("Please enter valid quantity");
    }
  };

  const getLocationDetailsForLocation = async (
    _prod: any,
    indexVal: number
  ) => {
    await getCancelLocationDetails(
      _prod?.locationId,
      _prod?.batchNo,
      _prod?.prodId,
      _prod?.pickingQty,
      _prod?.batchExpiry
    ).then((data: CancelLocationDetails[]) => {
      showModalWithData(data, _prod, "Location is empty!!", indexVal);
    });
  };

  const getAutoLocationDetails = async (indexVal: number) => {
    let data = cloneDeep(updateOP);
    let pickingDetails = data.orderPickingDetails[indexVal];
    await getCancelledPrefLocationDetails(
      pickingDetails?.batchNo,
      pickingDetails?.prodId,
      pickingDetails?.batchExpiry,
      pickingDetails?.pickingQty,
      data?.warehouseId
    ).then((data: CancelLocationDetails[]) => {
      showModalWithData(
        data,
        pickingDetails,
        "No available locations!! Please select a Free location to continue!!",
        indexVal
      );
    });
  };

  const onCheck = async (
    indexVal: number,
    type: string,
    value: string = ""
  ) => {
    let data = cloneDeep(updateOP);
    data.orderPickingDetails[indexVal].assignedLocType = type;
    if (type === LOCATION_TYPES.NO_SELECTION) {
      data.orderPickingDetails[indexVal].transferLocId = "";
      data.orderPickingDetails[indexVal].palletRefNum = "";
      data.orderPickingDetails[indexVal].transferShelfId = "";
    } else if (type === LOCATION_TYPES.SAME_LOCATION) {
      data.orderPickingDetails[indexVal].autoLocationDetails = null;
      data.orderPickingDetails[indexVal].newId = value;
    }
    setUpdateOP(data);
    if (type === LOCATION_TYPES.AUTO) {
      await getAutoLocationDetails(indexVal);
    } else if (type === LOCATION_TYPES.NO_SELECTION) {
      await getLocationDetailsForLocation(
        data.orderPickingDetails[indexVal],
        indexVal
      );
    }
  };

  const updateAutoLocationDetails = (locDetails: CancelLocationDetails) => {
    let data = cloneDeep(updateOP);
    data.orderPickingDetails[currentSelectedIndex].autoLocationDetails =
      locDetails;
    setUpdateOP(data);
  };

  const checkBox = (indexVal: number) => (
    <div
      style={{
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        pointerEvents: [
          LOCATION_TYPES.SAME_LOCATION,
          LOCATION_TYPES.EMPTY_LOCATION,
        ]?.includes(updateOP.orderPickingDetails[indexVal].assignedLocType)
          ? "none"
          : "auto",
        opacity: [
          LOCATION_TYPES.SAME_LOCATION,
          LOCATION_TYPES.EMPTY_LOCATION,
        ]?.includes(updateOP.orderPickingDetails[indexVal].assignedLocType)
          ? 0.5
          : 1,
      }}
    >
      <div>
        <input
          type="radio"
          id={`input_a_${indexVal}`}
          value={LOCATION_TYPES.FREE}
          checked={
            updateOP.orderPickingDetails[indexVal].assignedLocType ===
            LOCATION_TYPES.FREE
          }
          onChange={(e) => {
            onCheck(indexVal, LOCATION_TYPES.FREE);
          }}
        />
        <label htmlFor={`input_a_${indexVal}`}>{LOCATION_TYPES.FREE}</label>
      </div>
      <div>
        <input
          type="radio"
          id={`input_b_${indexVal}`}
          value={LOCATION_TYPES.AUTO}
          checked={
            updateOP.orderPickingDetails[indexVal].assignedLocType ===
            LOCATION_TYPES.AUTO
          }
          onChange={(e) => {
            onCheck(indexVal, LOCATION_TYPES.AUTO);
          }}
        />
        <label
          onClick={() => {
            if (
              updateOP.orderPickingDetails[indexVal].assignedLocType ===
              LOCATION_TYPES.AUTO
            ) {
              onCheck(indexVal, LOCATION_TYPES.AUTO);
            }
          }}
          htmlFor={`input_b_${indexVal}`}
        >
          {LOCATION_TYPES.AUTO}
        </label>
      </div>
    </div>
  );

  const showModalWithData = (
    data: CancelLocationDetails[],
    _prod: any,
    emptyMessage: string,
    indexVal
  ) => {
    if (data?.length > 0 && !isNullUndefined(data[0]?.warehouseModel)) {
      toast.info(emptyMessage);
      let data = cloneDeep(updateOP);
      data.orderPickingDetails[indexVal].transferLocId = _prod?.locationId;
      data.orderPickingDetails[indexVal].transferShelfId = _prod?.shelfId;
      data.orderPickingDetails[indexVal].assignedLocType =
        LOCATION_TYPES.EMPTY_LOCATION;
      setUpdateOP(data);
      return;
    } else if (data?.length <= 0) {
      toast.info(emptyMessage);
      return;
    }
    currentSelectedIndex = indexVal;
    setcancelLocData(data);
    // myModal.modal, "show");
    myModal.show();
  };

  const disableModalSave = () => {
    let order = updateOP?.orderPickingDetails[currentSelectedIndex];
    if (
      order?.assignedLocType === LOCATION_TYPES.AUTO &&
      isNullUndefinedEmpty(order?.autoLocationDetails)
    ) {
      return true;
    }
    return false;
  };

  const getListValues = () => {
    let result = shelfIds.map((option) => option.value);
    let data = cloneDeep(updateOP);
    data?.orderPickingDetails?.map((item) => {
      result = result?.filter((value) => value !== item?.transferShelfId);
    });
    return result;
  };

  const isLocationAlreadySelected = (value) => {
    let result = false;
    let data = cloneDeep(updateOP);
    data?.orderPickingDetails?.map((item) => {
      if (item?.transferShelfId === value) {
        result = true;
      }
    });
    return result;
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.orderPickingList}
                    >
                      Order Picking
                    </NavLink>{" "}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Order Picking Cancel
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-success float-end"
            onClick={() => {}}
          >
            Save changes
          </button>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">Order Picking </h4>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>S.No</th>
                        <th>Product Category</th>
                        <th>Product Name</th>
                        <th>Batch</th>
                        <th>Location</th>
                        <th>Assign Location</th>
                        <th>Available Locations</th>
                        <th>Pallet Ref No</th>
                        <th>Picked Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {updateOP && updateOP.orderPickingDetails.length > 0 ? (
                        updateOP.orderPickingDetails.map(
                          (_prod: any, j: number) => {
                            return (
                              <tr key={j}>
                                <td>{j + 1}</td>
                                <td className="text-start">
                                  {getProductCatNameById(
                                    _prod.prodCatId,
                                    productCateList
                                  )}
                                </td>
                                <td className="text-start">
                                  {getProductNameById(
                                    _prod.prodId,
                                    productsList
                                  )}
                                </td>
                                <td>{_prod.batchNo}</td>
                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    className="hover-warning"
                                    style={{ fontWeight: "bold" }}
                                    onClick={() => {
                                      onCheck(j, LOCATION_TYPES.NO_SELECTION);
                                    }}
                                  >
                                    {_prod?.shelfId}
                                  </a>
                                </td>
                                <td>{checkBox(j)}</td>
                                <td>
                                  <Autocomplete
                                    options={getListValues()}
                                    disabled={
                                      _prod?.assignedLocType !==
                                      LOCATION_TYPES.FREE
                                    }
                                    value={_prod?.transferShelfId}
                                    onChange={(event, newValue) => {
                                      // if (isLocationAlreadySelected(newValue)) {
                                      //   toast.error(
                                      //     "Location already selected!!"
                                      //   );
                                      //   newValue = "";
                                      // }
                                      let data = cloneDeep(updateOP);
                                      data.orderPickingDetails[
                                        j
                                      ].transferShelfId = newValue ?? "";
                                      data.orderPickingDetails[
                                        j
                                      ].transferLocId =
                                        shelfIds.find(
                                          (item) => item?.value === newValue
                                        )?.locationID ?? "";
                                      setUpdateOP(data);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{ width: 120 }}
                                      />
                                    )}
                                  />
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    style={{ width: 100 }}
                                    disabled={
                                      ![
                                        LOCATION_TYPES.FREE,
                                        LOCATION_TYPES.EMPTY_LOCATION,
                                      ].includes(_prod?.assignedLocType)
                                    }
                                    value={_prod?.palletRefNum ?? ""}
                                    onChange={(e) => {
                                      let data = cloneDeep(updateOP);
                                      data.orderPickingDetails[j].palletRefNum =
                                        e.target.value;
                                      setUpdateOP(data);
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    disabled
                                    value={_prod.pickingQty}
                                    onChange={(e) => handlePickedQty(_prod, e)}
                                  />
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={10} align="center">
                            <br />
                            <p>No products added to Order Picking</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => navigate(RoutingConstants.orderPickingList)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-success float-end"
                    onClick={() => {
                      onCancelOrder();
                    }}
                  >
                    Cancel Order
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="box">
              <div className="box-header bg-info p-3">
                <h4 className="box-title">Order Picking Details</h4>
                <p className="p-0 m-0">
                  #{LabelConstants.PoNumber} : {updateOP.pickingNum}
                </p>
              </div>
              <div className="box-body">
                <div className="row align-items-center">
                  <div className="col-4">
                    <label className="form-label">
                      Picking Type
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      disabled
                      value={updateOP.pickingType}
                    >
                      <option value="AUTO">AUTO</option>
                      <option value="MANUAL">MANUAL</option>
                    </select>
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Sales Order
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      disabled
                      value={updateOP.soNum + "-" + updateOP.customerId}
                    >
                      <option value={""}>--</option>
                      {salesOrdersListAll &&
                        salesOrdersListAll.length > 0 &&
                        salesOrdersListAll.map((so: any, i: number) => {
                          if (so.status !== "DRAFT") {
                            return (
                              <option
                                key={i}
                                value={so.soNum + "-" + so.customerId}
                              >
                                {so.soNum} | {so.customerName}
                              </option>
                            );
                          }
                        })}
                    </select>
                  </div>
                </div>
                <div className="row mt-20 align-items-center">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.CreatedDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      disabled
                      className="form-control bg-white"
                      value={getDateIfValid(updateOP.pickingDate)}
                      format="DD-MM-YYYY"
                    />
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Status
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      disabled
                      value={updateOP.status}
                    >
                      {updateOP.status === "DRAFT" ? (
                        <>
                          <option value={"DRAFT"}>DRAFT</option>
                          <option value={"PICKING"}>PICKING</option>
                          <option value={"CANCELLED"}>CANCELLED</option>
                        </>
                      ) : updateOP.status === "PICKING" ? (
                        <>
                          <option value={"PICKING"}>PICKING</option>
                          <option value={"PART_PICKED"}>
                            PARTIALLY PICKED
                          </option>
                          <option value={"PICKED"}>PICKED</option>
                          <option value={"CANCELLED"}>CANCELLED</option>
                        </>
                      ) : updateOP.status === "PART_PICKED" ? (
                        <>
                          <option value={"PART_PICKED"}>
                            PARTIALLY PICKED
                          </option>
                          <option value={"PICKED"}>PICKED</option>
                        </>
                      ) : updateOP.status === "PICKED" ? (
                        <>
                          <option value={"PICKED"}>PICKED</option>
                          <option value={"READY_FOR_DISPATCH"}>
                            READY FOR DISPATCH
                          </option>
                        </>
                      ) : updateOP.status === "READY_FOR_DISPATCH" ? (
                        <option value={"READY_FOR_DISPATCH"}>
                          READY FOR DISPATCH
                        </option>
                      ) : updateOP.status === "DISPATCHED" ? (
                        <option value={"DISPATCHED"}>DISPATCHED</option>
                      ) : updateOP.status === "CANCELLED" ? (
                        <option value={"CANCELLED"}>CANCELLED</option>
                      ) : updateOP.status === "PART_DISPATCHED" ? (
                        <option value={"PART_DISPATCHED"}>
                          PART_DISPATCHED
                        </option>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      WareHouse Name
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      disabled
                      value={updateOP.warehouseId}
                    >
                      <option value={""}>--</option>
                      {warehouseList.length > 0 &&
                        warehouseList.map((wereHouse: any, i: number) => {
                          return (
                            <option key={i} value={wereHouse.wareHouseId}>
                              {wereHouse.warehouseName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      Description
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      disabled
                      value={updateOP.description}
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Comments
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      value={updateOP?.cancelComments ?? ""}
                      onChange={(e) => handleCommentsChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Products list modal */}
      <div className="modal modal-center fade overflow-auto" id="modal-right">
        <div
          className="modal-dialog modal-lg"
          style={{ width: "1000px", maxWidth: "100%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Products List</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  let order =
                    updateOP?.orderPickingDetails[currentSelectedIndex];
                  if (order?.assignedLocType === LOCATION_TYPES.AUTO) {
                    updateAutoLocationDetails(null);
                    return;
                  }
                }}
              ></button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <table className="table table-bordered text-center">
                <tbody>
                  <table className="table table-bordered m-0">
                    <thead className="table-secondary">
                      <tr>
                        {updateOP?.orderPickingDetails[currentSelectedIndex]
                          ?.assignedLocType === LOCATION_TYPES.AUTO && (
                          <td>Select Location</td>
                        )}
                        <td>Product Category</td>
                        <td>Product Name</td>
                        {updateOP?.orderPickingDetails[currentSelectedIndex]
                          ?.assignedLocType !== LOCATION_TYPES.AUTO && (
                          <td>Location</td>
                        )}
                        <td>Batch No</td>
                        <td>Batch Exp.</td>
                        <td>Pallet Ref No</td>
                        <td>Status</td>
                        <td>Qty</td>
                        <td>Picked Qty</td>
                        <td>Total Qty</td>
                      </tr>
                    </thead>
                    <tbody>
                      {cancelLocData?.map((locitem, index) => (
                        <>
                          <tr>
                            {updateOP?.orderPickingDetails[currentSelectedIndex]
                              ?.assignedLocType === LOCATION_TYPES.AUTO && (
                              <td>
                                <div>
                                  <input
                                    type="radio"
                                    id={`input_chbox_${index}`}
                                    checked={
                                      updateOP?.orderPickingDetails[
                                        currentSelectedIndex
                                      ]?.autoLocationDetails?.id === locitem?.id
                                    }
                                    onChange={(e) => {
                                      updateAutoLocationDetails(locitem);
                                    }}
                                  />
                                  <label htmlFor={`input_chbox_${index}`}>
                                    {locitem?.shelfId}
                                  </label>
                                </div>
                              </td>
                            )}
                            <td className="text-start">
                              {getProductCatNameById(
                                locitem?.prodCatId,
                                productCateList
                              )}
                            </td>
                            <td className="text-start">
                              {getProductNameById(
                                locitem?.prodId,
                                productsList
                              )}
                            </td>
                            {updateOP?.orderPickingDetails[currentSelectedIndex]
                              ?.assignedLocType !== LOCATION_TYPES.AUTO && (
                              <td>{locitem?.shelfId}</td>
                            )}
                            <td>{locitem?.batchNo}</td>
                            <td>
                              {moment(locitem?.batchExpiryDate)?.format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>{locitem?.palletRefNum}</td>
                            <td>{locitem?.status}</td>
                            <td>{locitem?.quantity}</td>
                            <td>
                              {locitem?.qtyEdit ? locitem?.pickedQty : "-"}
                            </td>
                            <td>
                              {locitem?.qtyEdit
                                ? locitem?.manual
                                  ? locitem?.afterIncludePickingQty
                                  : locitem?.totalQty
                                : "-"}
                            </td>
                          </tr>
                          {locitem?.manual &&
                            !isNullUndefinedEmpty(locitem?.manualMessage) && (
                              <tr>
                                <td
                                  colSpan={10}
                                  className="text-start"
                                  style={{ color: "red" }}
                                >
                                  *{locitem?.manualMessage}
                                </td>
                              </tr>
                            )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </tbody>
              </table>
            </div>
            {cancelLocData?.length > 0 &&
              (cancelLocData[0].manual || cancelLocData[0].qtyEdit) && (
                <div className="modal-footer modal-footer-uniform">
                  <button
                    type="button"
                    className="btn btn-primary float-end"
                    data-bs-dismiss="modal"
                    disabled={disableModalSave()}
                    onClick={() => {
                      let order =
                        updateOP?.orderPickingDetails[currentSelectedIndex];
                      if (order?.assignedLocType === LOCATION_TYPES.AUTO) {
                        return;
                      }
                      onCheck(
                        currentSelectedIndex,
                        LOCATION_TYPES.SAME_LOCATION,
                        cancelLocData[0]?.id?.toString()
                      );
                    }}
                  >
                    Save changes
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderPickingCancelComponent;
