import { useEffect, useState } from "react";
import {
  IListConfig,
  IReportsContext,
  ISupplier,
  IListConfigPORpt,
  IWarehouse,
  IListConfigStockRpt,
  IListConfigPlanningRpt,
  IListConfigGoodsMovementRpt,
  IListConfigGoodsReceiptRpt,
  IListConfigBatchTraceabilityRpt,
  IProducts,
  ICustomer,
  IListConfigProductMovementRpt,
  IListConfigDispatchRpt,
  IListConfigPendingSORpt,
  IListConfigSupplierRpt,
  IListConfigTransporterRpt,
  IListConfigProductRpt,
} from "../../common/models";
import { cloneDeep } from "lodash";
import { GenericHelperService } from "../../api/generics/helper.service";
import {
  BatchStatusTypes,
  BatchTraceabilityReportKeys,
  CustomerReportKeys,
  DISPATCH_STATUS,
  DispatchReportKeys,
  EmptyListConfig,
  EmptyListConfigBatchTraceabilityRpt,
  EmptyStockReport,
  GoodsMovementReportKeys,
  GoodsReceiptReportKeys,
  MovementTypes,
  PENDING_SO_STATUS,
  POReportKeys,
  PendingSOReportKeys,
  PlanningReportKeys,
  ProductMovementReportKeys,
  ProductsReportKeys,
  PurchaseStatuses,
  Statuses,
  StockReportKeys,
  SupplierReportKeys,
  TransporterReportKeys,
} from "../../common/constants/generalConstants";
import _ from "lodash";
import { isNullUndefined } from "../../common/methods/globalmethods";
import { SupplierService } from "../../api/services/supplier.service";
import { ReportsService } from "../../api/services/reports.service";
import { WarehouseService } from "../../api/services/warehouse.service";
import {
  IBatchTraceabilityReport,
  IBatchTraceabilityReportServerData,
  ICustomerReport,
  IDispatchReport,
  IGoodsMovementReport,
  IGoodsReceiptReport,
  IPOReport,
  IPendingSOReport,
  IPlanningReport,
  IProductMovementReport,
  IProductReport,
  IStockReport,
  ISupplierReport,
  ITransactionReport,
} from "../../common/models/reports";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { ProductService } from "../../api/services/product.service";
import { CustomerService } from "../../api/services/customer.service";
import { LabelConstants } from "../../common/constants/labelConstants";

let allKeyValue = 9999999999;
const useReports = () => {
  const supplierService: SupplierService = new SupplierService();
  const warehouseService: WarehouseService = new WarehouseService();
  const reportsService: ReportsService = new ReportsService();
  const productService: ProductService = new ProductService();
  const customerService: CustomerService = new CustomerService();
  const helperService: GenericHelperService = new GenericHelperService();
  const [suppliers, setsuppliers] = useState<ISupplier[]>(null);
  const [warehouses, setwarehouses] = useState<IWarehouse[]>(null);
  const [products, setproducts] = useState<IProducts[]>(null);
  const [customers, setCustomers] = useState<ICustomer[]>(null);
  const [masterData, setmasterData] = useState([]);
  const [filteredMasterData, setFilteredMasterData] = useState([]);
  const [filteredMasterDownloadData, setFilteredMasterDownloadData] = useState(
    []
  );
  const [masterFilter, setMasterfilter] =
    useState<IListConfig>(EmptyListConfig);

  useEffect(() => {
    getSuppliers();
    getWarehouses();
    getProducts();
    getCustomers();
  }, []);

  useEffect(() => {
    const indexOfLastItem =
      masterFilter.currentPage * masterFilter.currentPageSize;
    const indexOfFirstItem = indexOfLastItem - masterFilter.currentPageSize;

    let _filteredList = cloneDeep(masterData ?? []);

    if (_filteredList) {
      // _filteredList.sort((a, b) =>
      //   helperService.sort(
      //     masterFilter.sortDirection === "asc" ? a : b,
      //     masterFilter.sortDirection === "asc" ? b : a,
      //     masterFilter.sortItem,
      //     masterFilter.sortIsDate
      //   )
      // );
      console.log("Filtered Sorted list - ", _filteredList);
      setFilteredMasterData(
        _filteredList.slice(indexOfFirstItem, indexOfLastItem) ?? []
      );
    }
  }, [masterFilter, masterData]);

  const getKeysForPath = (path: string) => {
    let keys = [];
    switch (path) {
      case RoutingConstants.supplier_reports:
        keys = SupplierReportKeys;
        break;
      case RoutingConstants.customer_reports:
        keys = CustomerReportKeys;
        break;
      case RoutingConstants.transporter_reports:
        keys = TransporterReportKeys;
        break;
      case RoutingConstants.product_reports:
        keys = ProductsReportKeys;
        break;
      case RoutingConstants.stk_reports:
        keys = StockReportKeys;
        break;
      case RoutingConstants.sp_reports:
        keys = PlanningReportKeys;
        break;
      case RoutingConstants.po_reports:
        keys = POReportKeys;
        break;
      case RoutingConstants.gm_reports:
        keys = GoodsMovementReportKeys;
        break;
      case RoutingConstants.gr_reports:
        keys = GoodsReceiptReportKeys;
        break;
      case RoutingConstants.bt_reports:
        keys = BatchTraceabilityReportKeys;
        break;
      case RoutingConstants.pm_reports:
        keys = ProductMovementReportKeys;
        break;
      case RoutingConstants.pso_reports:
        keys = PendingSOReportKeys;
        break;
      case RoutingConstants.dsp_reports:
        keys = DispatchReportKeys;
        break;
    }
    return keys;
  };

  const getFilteredDownloadData = (type: string, event, done) => {
    let keysData = getKeysForPath(type);
    let dataarray = [];
    let headers = [];
    keysData?.map((item) => {
      headers.push(item?.placeholder);
    });
    dataarray.push(headers);
    masterData?.map((item, index) => {
      let data = [];
      keysData?.map((keys) => {
        data.push(item[keys.key]);
      });
      dataarray.push(data);
    });
    setFilteredMasterDownloadData(dataarray);
    done(true);
  };

  const getSuppliers = async () => {
    let _Supplier = await supplierService.getSupplier();
    _Supplier?.splice(0, 0, {
      supId: allKeyValue,
      supName: "All",
      PIPCode: "",
    });

    setsuppliers(_Supplier ?? []);
    return _Supplier;
  };

  const getWarehouses = async () => {
    let _warehouses = await warehouseService.getWarehouses();
    _warehouses?.map((item: any) => {
      item.value = item.wareHouseId;
      item.label = item.warehouseName;
    });

    _warehouses?.splice(0, 0, <IWarehouse>{
      value: LabelConstants.All,
      label: "All",
    });

    setwarehouses(_warehouses ?? []);
    return _warehouses;
  };

  const getProducts = async () => {
    let _products = await productService.getProducts();
    _products?.map((item: any, index) => {
      item.value = item.productId;
      item.label = `${item?.productCode} - ${item?.productName}`;
    });

    _products?.splice(0, 0, <IProducts>{
      value: LabelConstants.All,
      label: "All",
    });

    setproducts(_products ?? []);
    return _products;
  };

  const getCustomers = async () => {
    let _customers = await customerService.getCustomer();
    _customers?.splice(0, 0, {
      custId: allKeyValue,
      custName: "All",
    });

    setCustomers(_customers ?? []);
    return _customers;
  };

  const getPOReports = async (filter: IListConfigPORpt) => {
    let data: IPOReport[] = await reportsService.getPOReports(
      removeUnrequiredAttributes(filter, LabelConstants.PO)
    );
    updateFiltersByData(data ?? []);
  };

  const getStockReports = async (filter: IListConfigStockRpt) => {
    let data: IStockReport[] = await reportsService.getStockReports(
      removeUnrequiredAttributes(filter, LabelConstants.Stock)
    );
    const result = (<any>Object).groupBy(
      data,
      ({ productName }) => productName
    );

    let resultdata = [];
    Object.keys(result).forEach((key) => {
      let items = result[key];
      resultdata = resultdata.concat(items);
      let item = cloneDeep(EmptyStockReport);
      item.productName = key;
      item.qty = items.reduce((n, { qty }) => n + qty, 0);
      item.isgroupkey = true;
      resultdata = resultdata.concat(item);
    });

    updateFiltersByData(resultdata ?? []);
  };

  const getPlanningReports = async (filter: IListConfigPlanningRpt) => {
    let data: IPlanningReport[] = await reportsService.getPlanningReports(
      removeUnrequiredAttributes(filter, LabelConstants.Stock_Planning)
    );
    updateFiltersByData(data ?? []);
  };

  const getGoodsMovementReports = async (
    filter: IListConfigGoodsMovementRpt
  ) => {
    let query = "?";

    if (!isNullUndefined(filter?.prodId) && filter?.prodId !== 0) {
      let value;
      if (filter.prodId + "" === LabelConstants.All) {
        value = getProductIdsWithComa();
      } else {
        value = filter?.prodId;
      }
      query += "prodId=" + value;
    }

    if (!isNullUndefined(filter?.movementType) && filter?.movementType !== "") {
      if (query !== "?") {
        query += "&";
      }
      let value;
      if (filter.movementType === LabelConstants.All) {
        value = getStatusIdWithComa(MovementTypes);
      } else {
        value = filter?.movementType;
      }
      query += "movementType=" + value;
    }

    let data: IGoodsMovementReport[] =
      await reportsService.getGoodsMovementReports(query);
    updateFiltersByData(data ?? []);
  };

  const getBatchTraceabilityReports = async (
    filter: IListConfigBatchTraceabilityRpt
  ) => {
    let query = "?";

    if (!isNullUndefined(filter?.batchNo) && filter?.batchNo !== "") {
      query += "batchNo=" + filter?.batchNo + "&";
    }

    if (!isNullUndefined(filter?.prodId) && filter?.prodId !== 0) {
      let value;
      if (filter.prodId + "" === LabelConstants.All) {
        value = getProductIdsWithComa();
      } else {
        value = filter?.prodId;
      }
      query += "prodId=" + value + "&";
    }

    if (!isNullUndefined(filter?.fromDate) && filter?.fromDate !== "") {
      query += "fromDate=" + filter?.fromDate + "&";
    }

    if (!isNullUndefined(filter?.toDate) && filter?.toDate !== "") {
      query += "toDate=" + filter?.toDate;
    }

    let data: IBatchTraceabilityReportServerData[] =
      await reportsService.getBatchTraceabilityReports(query);
    let traceabilityDataArray: IBatchTraceabilityReport[] = [];
    data.map((item) => {
      let inwardLength = item?.inwardDetailsDTOS?.length ?? 0;
      let dispatchLength = item?.dispatchDTOS?.length ?? 0;
      let max = Math.max(inwardLength, dispatchLength);
      let traceabilityData: IBatchTraceabilityReport = cloneDeep(
        EmptyListConfigBatchTraceabilityRpt
      );

      traceabilityData.productName = item?.productName;
      traceabilityData.productCode = item?.productCode;
      traceabilityData.prodId = item?.prodId;
      traceabilityData.prodCatId = item?.prodCatId;
      traceabilityData.manufaturer = item?.manufaturer;
      traceabilityData.batchNo = item?.batchNo;
      traceabilityData.remainingQty = item?.remainingQty;
      traceabilityData.batchExpiry = item?.batchExpiry;
      traceabilityData.samplingDate = item?.samplingDate;
      traceabilityData.releasedDate = item?.releasedDate;
      traceabilityData.rejectedDate = item?.rejectedDate;
      for (let i = 0; i < max; i++) {
        let temp: IBatchTraceabilityReport = cloneDeep(traceabilityData);
        temp.grnNumber = item?.inwardDetailsDTOS[i]?.grnNumber;
        temp.samplingQty = item?.inwardDetailsDTOS[i]?.samplingQty;
        temp.batchQty = item?.inwardDetailsDTOS[i]?.batchQty;
        temp.receivedDate = item?.inwardDetailsDTOS[i]?.receivedDate;
        temp.supplier = item?.inwardDetailsDTOS[i]?.supplier ?? "-";
        temp.soNumber = item?.dispatchDTOS[i]?.soNumber ?? "-";
        temp.orderQty = item?.dispatchDTOS[i]?.orderQty ?? null;
        temp.customer = item?.dispatchDTOS[i]?.customer ?? "-";
        temp.dispatchDate = item?.dispatchDTOS[i]?.dispatchDate ?? null;
        temp.deliveredDate = item?.dispatchDTOS[i]?.deliveredDate ?? null;
        temp.trackingNumber = item?.dispatchDTOS[i]?.trackingNumber ?? "-";
        temp.transporter = item?.dispatchDTOS[i]?.transporter ?? "-";
        if(item?.dispatchDTOS[i]?.returnOrderDetails.length > 0) {
          for(let j = 0; j < item?.dispatchDTOS[i]?.returnOrderDetails.length; j++) {
            let temp1 = cloneDeep(temp);
            temp1.returnOrderNum = item?.dispatchDTOS[i]?.returnOrderDetails[j]?.returnOrderNum ?? '-';
            temp1.returnQty = item?.dispatchDTOS[i]?.returnOrderDetails[j]?.returnQty ?? null;
            temp1.returnDate = item?.dispatchDTOS[i]?.returnOrderDetails[j]?.returnDate ?? '-';
            traceabilityDataArray.push(temp1);
          }
        } else {
          let temp1 = cloneDeep(temp);
          temp1.returnOrderNum = '-';
          temp1.returnQty = null;
          temp1.returnDate = '-';
          traceabilityDataArray.push(temp1);
        }
      }
    });

    updateFiltersByData(traceabilityDataArray ?? []);
  };

  const getGRNReports = async (filter: IListConfigGoodsReceiptRpt) => {
    let data: IGoodsReceiptReport[] =
      await reportsService.getGoodsReceiptsReports(
        removeUnrequiredAttributes(filter, LabelConstants.GRN)
      );
    updateFiltersByData(data ?? []);
  };

  const getProductIdsWithComa = () => {
    let ids = [];
    products?.map((item) => {
      if (
        !isNullUndefined(item?.productId) &&
        item.productId + "" !== LabelConstants.All
      ) {
        ids.push(item.productId);
      }
    });

    return ids?.join();
  };

  const getStatusIdWithComa = (statuses) => {
    let data = [];
    statuses?.map((item) => {
      if (item.value !== LabelConstants.All) {
        data.push(item.value);
      }
    });
    return data.join();
  };

  const getWarehouseIdsWithComa = () => {
    let ids = [];
    warehouses?.map((item) => {
      if (
        !isNullUndefined(item?.wareHouseId) &&
        item.wareHouseId + "" !== LabelConstants.All
      ) {
        ids.push(item.wareHouseId);
      }
    });

    return ids?.join();
  };

  const getCustomerIdsWithComa = () => {
    let ids = [];
    customers?.map((item) => {
      if (!isNullUndefined(item.custId) && item.custId !== allKeyValue)
        ids.push(item.custId);
    });
    return ids?.join();
  };

  const getSupplierIdsWithComa = () => {
    let ids = [];
    suppliers?.map((item) => {
      if (!isNullUndefined(item.supId) && item.supId !== allKeyValue)
        ids.push(item.supId);
    });
    return ids?.join();
  };

  const getProductMovementReports = async (
    filter: IListConfigProductMovementRpt
  ) => {
    let query = "?";

    if (!isNullUndefined(filter?.productId) && filter?.productId !== 0) {
      let value;
      if (filter.productId + "" === LabelConstants.All) {
        value = getProductIdsWithComa();
      } else {
        value = filter?.productId;
      }
      query += "productId=" + value + "&";
    }

    if (!isNullUndefined(filter?.customerId) && filter?.customerId !== 0) {
      let value;
      if (filter.customerId + "" === allKeyValue + "") {
        value = getCustomerIdsWithComa();
      } else {
        value = filter?.customerId;
      }
      query += "customerId=" + value;
    }

    let data: IProductMovementReport[] =
      await reportsService.getProductMovementReports(query);
    updateFiltersByData(data ?? []);
  };

  const getDispatchReports = async (filter: IListConfigDispatchRpt) => {
    let query = "?";

    if (!isNullUndefined(filter?.productId) && filter?.productId !== 0) {
      let value;
      if (filter.productId + "" === LabelConstants.All) {
        value = getProductIdsWithComa();
      } else {
        value = filter?.productId;
      }
      query += "productId=" + value + "&";
    }

    if (!isNullUndefined(filter?.customerId) && filter?.customerId !== 0) {
      let value;
      if (filter.customerId + "" === allKeyValue + "") {
        value = getCustomerIdsWithComa();
      } else {
        value = filter?.customerId;
      }
      query += "customerId=" + value + "&";
    }

    if (filter?.status?.length > 0) {
      let value;
      if (filter.status + "" === LabelConstants.All) {
        value = DISPATCH_STATUS.join();
      } else {
        value = filter?.status;
      }
      query += "status=" + value;
    }

    let data: IDispatchReport[] = await reportsService.getDispatchReports(
      query
    );
    updateFiltersByData(data ?? []);
  };

  const getSupplierReports = async (filter: IListConfigSupplierRpt) => {
    let query = "?";

    if(filter.name !== "") {
      query += "name=" + filter?.name + "&";
    }
    if (filter?.status?.length > 0) {
      let value;
      if (filter.status + "" === LabelConstants.All) {
        value = Statuses.join();
      } else {
        value = filter?.status;
      }
      query += "status=" + value + "&";
    }
    if (!isNullUndefined(filter?.fromDate) && filter?.fromDate !== "") {
      query += "fromDate=" + filter?.fromDate + "&";
    }

    if (!isNullUndefined(filter?.toDate) && filter?.toDate !== "") {
      query += "toDate=" + filter?.toDate + "&";
    }
    if (!isNullUndefined(filter?.validFrom) && filter?.validFrom !== "") {
      query += "validFrom=" + filter?.validFrom + "&";
    }

    if (!isNullUndefined(filter?.validTo) && filter?.validTo !== "") {
      query += "validTo=" + filter?.validTo + "&";
    }
    let data: ISupplierReport[] = await reportsService.getSupplierReports(
      query
    )
    updateFiltersByData(data ?? []);
  }
  const getCustomerReports = async (filter: IListConfigSupplierRpt) => {
    let query = "?";

    if(filter.name !== "") {
      query += "name=" + filter?.name + "&";
    }
    if (filter?.status?.length > 0) {
      let value;
      if (filter.status + "" === LabelConstants.All) {
        value = Statuses.join();
      } else {
        value = filter?.status;
      }
      query += "status=" + value + "&";
    }
    if (!isNullUndefined(filter?.fromDate) && filter?.fromDate !== "") {
      query += "fromDate=" + filter?.fromDate + "&";
    }

    if (!isNullUndefined(filter?.toDate) && filter?.toDate !== "") {
      query += "toDate=" + filter?.toDate + "&";
    }
    if (!isNullUndefined(filter?.validFrom) && filter?.validFrom !== "") {
      query += "validFrom=" + filter?.validFrom + "&";
    }

    if (!isNullUndefined(filter?.validTo) && filter?.validTo !== "") {
      query += "validTo=" + filter?.validTo + "&";
    }
    let data: ICustomerReport[] = await reportsService.getCustomerReports(
      query
    )
    updateFiltersByData(data ?? []);
  }

  const getTransporterReports = async (filter: IListConfigTransporterRpt) => {
    let query = "?";

    if(filter.name !== "") {
      query += "name=" + filter?.name + "&";
    }
    if (filter?.status?.length > 0) {
      let value;
      if (filter.status + "" === LabelConstants.All) {
        value = Statuses.join();
      } else {
        value = filter?.status;
      }
      query += "status=" + value + "&";
    }
    if (!isNullUndefined(filter?.fromDate) && filter?.fromDate !== "") {
      query += "fromDate=" + filter?.fromDate + "&";
    }

    if (!isNullUndefined(filter?.toDate) && filter?.toDate !== "") {
      query += "toDate=" + filter?.toDate + "&";
    }
    if (!isNullUndefined(filter?.validFrom) && filter?.validFrom !== "") {
      query += "validFrom=" + filter?.validFrom + "&";
    }

    if (!isNullUndefined(filter?.validTo) && filter?.validTo !== "") {
      query += "validTo=" + filter?.validTo + "&";
    }
    let data: ITransactionReport[] = await reportsService.getTransporterReports(
      query
    )
    updateFiltersByData(data ?? []);
  }

  const getProductsReports = async (filter: IListConfigProductRpt) => {
    let query = "?";

    if (!isNullUndefined(filter?.name) && filter?.name !== "") {
      query += "name=" + filter?.name + "&";
    }
    if (!isNullUndefined(filter?.prodCode) && filter?.prodCode !== "") {
      query += "prodCode=" + filter?.prodCode + "&";
    }
    if (filter?.status?.length > 0) {
      let value;
      if (filter.status + "" === LabelConstants.All) {
        value = Statuses.join();
      } else {
        value = filter?.status;
      }
      query += "status=" + value + "&";
    }
    if (!isNullUndefined(filter?.fromDate) && filter?.fromDate !== "") {
      query += "fromDate=" + filter?.fromDate + "&";
    }

    if (!isNullUndefined(filter?.toDate) && filter?.toDate !== "") {
      query += "toDate=" + filter?.toDate + "&";
    }
    
    let data: IProductReport[] = await reportsService.getProductsReports(
      query
    )
    updateFiltersByData(data ?? []);
  }

  const getPendingSOReports = async (filter: IListConfigPendingSORpt) => {
    let query = "?";

    if (!isNullUndefined(filter?.prodId) && filter?.prodId !== 0) {
      let value;
      if (filter.prodId + "" === LabelConstants.All) {
        value = getProductIdsWithComa();
      } else {
        value = filter?.prodId;
      }
      query += "prodId=" + value + "&";
    }

    if (!isNullUndefined(filter?.customerId) && filter?.customerId !== 0) {
      let value;
      if (filter.customerId + "" === allKeyValue + "") {
        value = getCustomerIdsWithComa();
      } else {
        value = filter?.customerId;
      }
      query += "customerId=" + value + "&";
    }

    if (filter?.status?.length > 0) {
      let value;
      if (filter.status + "" === LabelConstants.All) {
        value = PENDING_SO_STATUS.join();
      } else {
        value = filter?.status;
      }
      query += "status=" + value;
    }

    let data: IPendingSOReport[] = await reportsService.getPendingSOReports(
      query
    );
    updateFiltersByData(data ?? []);
  };

  const updateFiltersByData = (data: any[]) => {
    const _filters: IListConfig = cloneDeep(masterFilter);
    _filters.currentPage = 1;
    _filters.searchQuery = "";
    _filters.totalRecords = data?.length;
    _filters.currentPageSize = 100;
    _filters.totalPageCount = Math.ceil(
      data?.length / _filters.currentPageSize
    );
    // _filters.sortItem = "modifiedDate";
    // _filters.sortDirection = "desc";
    // _filters.sortIsDate = true;
    setMasterfilter(_filters);
    setmasterData(data);
  };

  const clearMasterData = () => {
    updateFiltersByData([]);
  };

  const getComaSeperatedValuesForKey = (key, type = "") => {
    let result = { key: "", value: "" };
    if (key === "prodId" || key === "productId") {
      result.key = "prodIdUdf";
      result.value = getProductIdsWithComa();
    } else if (key === "status") {
      result.key = "statusUdf";
      if (
        type === LabelConstants.Stock ||
        type === LabelConstants.Stock_Planning
      ) {
        result.value = getStatusIdWithComa(BatchStatusTypes);
      } else if (type === LabelConstants.PO) {
        result.value = PurchaseStatuses.join();
      } else if (type === LabelConstants.PO || type === LabelConstants.GRN) {
        result.value = PurchaseStatuses.join();
      }
    } else if (key === "warehouseId" || key === "warehouse") {
      result.key = "warehouseIdUdf";
      result.value = getWarehouseIdsWithComa();
    } else if (key === "supplier") {
      result.key = "supplierUdf";
      result.value = getSupplierIdsWithComa();
    }

    return result;
  };

  const removeUnrequiredAttributes = (filter: any, type: string = "") => {
    let tempfilter: any = cloneDeep(filter);
    if (!isNullUndefined(tempfilter)) {
      delete tempfilter.searchData;
      for (const [key, value] of Object.entries(tempfilter)) {
        if (typeof value === "string" && _.isEmpty(value)) {
          delete tempfilter[key];
        } else if (Array.isArray(value) && value?.length <= 0) {
          delete tempfilter[key];
        } else if (typeof value === "number" && value <= 0) {
          delete tempfilter[key];
        } else if (value === null || value === undefined) {
          delete tempfilter[key];
        }

        if (
          (typeof value === "number" && value === allKeyValue) ||
          (typeof value === "string" && value === LabelConstants.All) ||
          (Array.isArray(value) &&
            value?.length > 0 &&
            value[0] === LabelConstants.All)
        ) {
          delete tempfilter[key];
          let res = getComaSeperatedValuesForKey(key, type);
          tempfilter[res?.key] = res?.value?.split(",");
        }
      }
    }
    return tempfilter;
  };
  return {
    suppliers,
    filteredMasterData,
    masterFilter,
    setMasterfilter,
    getPOReports,
    setmasterData,
    clearMasterData,
    setFilteredMasterData,
    masterData,
    getSupplierReports,
    getCustomerReports,
    getTransporterReports,
    getProductsReports,
    getStockReports,
    getPlanningReports,
    getGoodsMovementReports,
    getGRNReports,
    getBatchTraceabilityReports,
    getProductMovementReports,
    getDispatchReports,
    getPendingSOReports,
    getFilteredDownloadData,
    filteredMasterDownloadData,
    products,
    warehouses,
    customers,
  } as IReportsContext;
};

export default useReports;
