import { useContext, useEffect, useState } from "react";
import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  useRouteError,
} from "react-router-dom";
import { AppContext } from "../../controllers/contexts/app.context";
import { IContextInfo, ILoaderConfig } from "../../common/models";
import { StorageService } from "../../api/generics/storage.service";
import { RoutingConstants } from "../../common/constants/routingConstants";

// spinners
import {
  EmptySpinner,
  ReportsData,
  SettingsData,
} from "../../common/constants/generalConstants";

// styles
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// Components
import SideNavComponent from "../common/sidenav.component";
import Spinner from "../common/spinner.component";
import HeaderComponent from "../common/header.component";
import ProductComponent from "../master/product/product.component";
import SupplierLayoutComponent from "../master/supplier/supplierLayout.component";
import CustomerComponent from "../master/customer/customer.component";
import TransporterComponent from "../master/transporter/transporter.component";
import SupplierComponent from "../master/supplier/supplier.component";
import SupplierFormComponent from "../master/supplier/supplierForm.component";
import TransporterLayoutComponent from "../master/transporter/transporterLayout.component";
import TransporterFormComponent from "../master/transporter/transporterForm.component";
import LoginComponent from "../auth/login.component";
import CustomerLayoutComponent from "../master/customer/customerLayout.component";
import CustomerFormComponent from "../master/customer/customerForm.component";
import ProductLayoutComponent from "../master/product/productLayout.component";
import EmployeeComponent from "../master/employee/employee.component";
import EmployeeLayoutComponent from "../master/employee/employeeLayout.component";
import EmployeeFormComponent from "../master/employee/employeeForm.component";
import ProductFormComponent from "../master/product/productForm.component";
import SupplierDetailsComponent from "../master/supplier/supplierDetails.component";
import CustomerDetailsComponent from "../master/customer/customerDetails.component";
import ProductDetailsComponent from "../master/product/productDetails.component";
import TransporterDetailsComponent from "../master/transporter/transporterDetails.component";
import EmployeeDetailsComponent from "../master/employee/employeeDetails.component";
import useMaster from "../../controllers/hooks/useMaster";
import GrnLayoutComponent from "../orderin/grn/grnLayout.component";
import purchaseOrderLayoutComponent from "../purchaseorder/purchaseOrderLayout.component";
import PurchaseOrderComponent from "../purchaseorder/purchaseOrder.component";
import purchaseOrderFormcomponent from "../purchaseorder/purchaseOrderForm.component";
import WarehouseLayoutComponent from "../warehouse/warehouseLayout.component";
import WarehouseComponent from "../warehouse/warehouse.component";
import WarehouseFormComponent from "../warehouse/warehouseForm.component";
import WarehouseDetailsComponent from "../warehouse/warehouseDetails.component";
import WarehouseLocationFormComponent from "../warehouse/warehouseLocationForm.component";
import PurchaseDetailsComponent from "../purchaseorder/purchaseDetails.component";
import SamplingLayoutComponent from "../sampling/samplingLayout.component";
import SamplingComponent from "../sampling/sampling.component";
import SamplingFormComponent from "../sampling/samplingForm.component";
import GrnBatchComponent from "../orderin/grn/grn_batch.component";
import GrnListComponent from "../orderin/grn/grn_list.component";
import GrnCreateComponent from "../orderin/grn/grn_create.component";
import "../../assets/styles/customStyles.scss";
import SamplingDetailsComponent from "../sampling/samplingDetails.component";
import BatchManagementComponent from "../batchmanagement/batchmanagement.component";
import BatchManagementLayoutComponent from "../batchmanagement/batchmanagementLayout.component";
import GRNPrintComponent from "../orderin/grn/grn_print";
import SalesOrderLayoutComponent from "../sales_order/salesOrderLayout.component";
import SalesOrderComponent from "../sales_order/sales_order.component";
import SalesOrderFormcomponent from "../sales_order/sales_order_form.component";
import SalesDetailsComponent from "../sales_order/saler_order_details.component";
import DeliveryOrderLayoutComponent from "../orderout/deliveryOrderLayout.component";
import DeliveryOrderComponent from "../orderout/delivery_order.component";
import DeliveryOrderFormcomponent from "../orderout/delivery_order_form.component";
import DeliveryOrderDetailsComponent from "../orderout/delivery_order_details.component";
import OrderPickingLayoutComponent from "../orderpicking/orderPickingLayout.component";
import OrderPickingListComponent from "../orderpicking/orderPickingList.componet";
import CreateOrderPickingComponent from "../orderpicking/orderPickingCreate.component";
import OrderPickingDetailsComponent from "../orderpicking/orderPickingDetails.component";
import ReturnOrdersLayoutComponent from "../returnorders/returnOrdersLayout.component";
import ReturnOrdersComponent from "../returnorders/returnOrders.component";
import ReturnOrdersFormcomponent from "../returnorders/returnOrdersForm.component";
import ChangePassword from "../profile/changePassword.component";
import ReportsLayoutComponent from "../reports/reportsLayout.component";
import ReportsComponent from "../reports/reports.component";
import WarehouseTransferListComponent from "../stocktransfer/warehouseTransferList.component";
import WarehouseTransferDetails from "../stocktransfer/warehouseTransferDetails.component";
import WarehouseTransferCreate from "../stocktransfer/warehouseTransferCreate.component";
import WarehouseTransferLayoutComponent from "../stocktransfer/warehouseTransferLayout.component";
import Profile from "../profile/profile.component";
import SettingsLayoutComponent from "../setup/settingsLayout.component";
import SettingsComponent from "../setup/settings.component";
import useAuth from "../../controllers/hooks/useAuth";
import PickingPrintFormat from "../prints/PickingPrintFormat";
import DeliveryPrintFormat from "../prints/DeliveryPrintFormat";
import PurchaseOrderPrintFormat from "../prints/PurchaseOrderPrintFormat";
import SalesOrderPrintFormat from "../prints/SalesOrderPrintFormat";
import DashboardLayoutComponent from "../dashboard/dashboardLayout.component";
import DashboardComponent from "../dashboard/dashboardComponent";
import SessionTimer from "../common/sessionTimer";
import ReturnOrderPrintFormat from "../prints/ReturnOrderPrintFormat";
import OrderPickingCancelComponent from "../orderpicking/orderPickingCancel.component";

const LayoutComponent = () => {
  const storageService: StorageService = new StorageService();
  const [loader, setLoader] = useState<ILoaderConfig>(EmptySpinner);
  const [userContext, setUserContext] = useState<IContextInfo>(
    storageService.ContextInfo()
  );
  const { dosageForm, productCategory, refreshData } = useMaster();

  const { isAccessDisabled } = useAuth();

  useEffect(() => {
    if (userContext !== null) {
      refreshData();
    }
  }, [userContext]);

  const signOut = () => {
    console.log("Sign Out Clicked");
    setUserContext(null);
    storageService.clearContextInfo();
  };

  const RoutesLayout = () => (
    <div className={`wrapper`}>
      <HeaderComponent />
      <SideNavComponent />
      <div className="content-wrapper">
        <div className="container-full">
          <Outlet />
        </div>
      </div>
      <SessionTimer
        initialMinute={userContext?.sessionExpireInMinutes ?? 720}
        warnBeforeMinutes={5}
        onSessionTimeout={() => signOut()}
        userName={userContext?.username}
        upateSpinner={(value, mesg) => {
          setLoader({
            showSpinner: value,
            spinnerMessage: mesg,
          });
        }}
      />
    </div>
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<RoutesLayout />} errorElement={<RoutesLayout />}>
        <Route path={RoutingConstants?.profile} Component={Profile} />
        <Route
          path={RoutingConstants?.changePassword}
          Component={ChangePassword}
        />
        {!isAccessDisabled("MD", "visible", true, "PROD") && (
          <Route
            path={RoutingConstants?.product}
            Component={ProductLayoutComponent}
          >
            <Route
              path={RoutingConstants?.product}
              Component={ProductComponent}
            />
            <Route
              path={RoutingConstants?.productCreate}
              Component={ProductFormComponent}
            />
            <Route
              path={RoutingConstants?.productEdit}
              Component={ProductFormComponent}
            />
            <Route
              path={RoutingConstants?.productDetails}
              Component={ProductDetailsComponent}
            />
          </Route>
        )}

        {!isAccessDisabled("MD", "visible", true, "SUP") && (
          <Route
            path={RoutingConstants?.supplier}
            Component={SupplierLayoutComponent}
          >
            <Route
              path={RoutingConstants?.supplier}
              Component={SupplierComponent}
            />
            <Route
              path={RoutingConstants?.supplierCreate}
              Component={SupplierFormComponent}
            />
            <Route
              path={RoutingConstants?.supplierEdit}
              Component={SupplierFormComponent}
            />
            <Route
              path={RoutingConstants?.supplierDetails}
              Component={SupplierDetailsComponent}
            />
          </Route>
        )}

        {!isAccessDisabled("OI", "visible", true, "GRN") && (
          <Route path={RoutingConstants?.grn} Component={GrnLayoutComponent}>
            <Route path={RoutingConstants?.grn} Component={GrnListComponent} />
            <Route
              path={RoutingConstants?.grn_order_print}
              Component={GRNPrintComponent}
            />
            <Route
              path={RoutingConstants?.grn_order_create}
              Component={GrnCreateComponent}
            />
            <Route
              path={RoutingConstants?.grn_order_edit}
              Component={GrnCreateComponent}
            />
            <Route
              path={RoutingConstants?.grn_order_view}
              Component={GrnCreateComponent}
            />
            <Route
              path={RoutingConstants?.grn_batch_view}
              Component={GrnBatchComponent}
            />
            <Route
              path={RoutingConstants?.grn_batch_create}
              Component={GrnBatchComponent}
            />
            <Route
              path={RoutingConstants?.grn_batch_edit}
              Component={GrnBatchComponent}
            />
          </Route>
        )}

        {!isAccessDisabled("MD", "visible", true, "CUST") && (
          <Route
            path={RoutingConstants?.customer}
            Component={CustomerLayoutComponent}
          >
            <Route
              path={RoutingConstants?.customer}
              Component={CustomerComponent}
            />
            <Route
              path={RoutingConstants?.customerCreate}
              Component={CustomerFormComponent}
            />
            <Route
              path={RoutingConstants?.customerEdit}
              Component={CustomerFormComponent}
            />
            <Route
              path={RoutingConstants?.customerDetails}
              Component={CustomerDetailsComponent}
            />
          </Route>
        )}

        {!isAccessDisabled("MD", "visible", true, "TRANS") && (
          <Route
            path={RoutingConstants?.transporter}
            Component={TransporterLayoutComponent}
          >
            <Route
              path={RoutingConstants?.transporter}
              Component={TransporterComponent}
            />
            <Route
              path={RoutingConstants?.transporterCreate}
              Component={TransporterFormComponent}
            />
            <Route
              path={RoutingConstants?.transporterEdit}
              Component={TransporterFormComponent}
            />
            <Route
              path={RoutingConstants?.transporterDetails}
              Component={TransporterDetailsComponent}
            />
          </Route>
        )}

        {!isAccessDisabled("MD", "visible", true, "EMP") && (
          <Route
            path={RoutingConstants?.employee}
            Component={EmployeeLayoutComponent}
          >
            <Route
              path={RoutingConstants?.employee}
              Component={EmployeeComponent}
            />
            <Route
              path={RoutingConstants?.employeeCreate}
              Component={EmployeeFormComponent}
            />
            <Route
              path={RoutingConstants?.employeeEdit}
              Component={EmployeeFormComponent}
            />
            <Route
              path={RoutingConstants?.employeeDetails}
              Component={EmployeeDetailsComponent}
            />
          </Route>
        )}
        {!isAccessDisabled("OI", "visible", true, "PO") && (
          <Route
            path={RoutingConstants?.purchase}
            Component={purchaseOrderLayoutComponent}
          >
            <Route
              path={RoutingConstants?.purchase}
              Component={PurchaseOrderComponent}
            />
            <Route
              path={RoutingConstants?.purchaseCreate}
              Component={purchaseOrderFormcomponent}
            />
            <Route
              path={RoutingConstants?.purchaseDetails}
              Component={PurchaseDetailsComponent}
            />
            <Route
              path={RoutingConstants?.purchasePrint}
              Component={PurchaseOrderPrintFormat}
            />
          </Route>
        )}
        {!isAccessDisabled("RO", "visible") && (
          <Route
            path={RoutingConstants?.returnorders}
            Component={ReturnOrdersLayoutComponent}
          >
            <Route
              path={RoutingConstants?.returnorders}
              Component={ReturnOrdersComponent}
            />
            <Route
              path={RoutingConstants?.returnordersCreate}
              Component={ReturnOrdersFormcomponent}
            />
            <Route
              path={RoutingConstants?.returnordersDetails}
              Component={ReturnOrdersFormcomponent}
            />
            <Route
              path={RoutingConstants?.returnordersEdit}
              Component={ReturnOrdersFormcomponent}
            />
            <Route
              path={RoutingConstants?.returnordersPrint}
              Component={ReturnOrderPrintFormat}
            />
          </Route>
        )}
        {!isAccessDisabled("OT", "visible", true, "DO") && (
          <Route
            path={RoutingConstants?.delivery_order}
            Component={DeliveryOrderLayoutComponent}
          >
            <Route
              path={RoutingConstants?.delivery_order}
              Component={DeliveryOrderComponent}
            />
            <Route
              path={RoutingConstants?.delivery_order_create}
              Component={DeliveryOrderFormcomponent}
            />
            <Route
              path={RoutingConstants?.delivery_order_edit}
              Component={DeliveryOrderDetailsComponent}
            />
            <Route
              path={RoutingConstants?.delivery_order_view}
              Component={DeliveryOrderDetailsComponent}
            />
            <Route
              path={RoutingConstants?.delivery_order_view}
              Component={DeliveryOrderDetailsComponent}
            />
            <Route
              path={RoutingConstants?.delivery_order_print}
              Component={DeliveryPrintFormat}
            />
          </Route>
        )}
        {!isAccessDisabled("OT", "visible", true, "SO") && (
          <Route
            path={RoutingConstants?.sales_order}
            Component={SalesOrderLayoutComponent}
          >
            <Route
              path={RoutingConstants?.sales_order}
              Component={SalesOrderComponent}
            />
            <Route
              path={RoutingConstants?.sales_order_create}
              Component={SalesOrderFormcomponent}
            />
            <Route
              path={RoutingConstants?.sales_order_edit}
              Component={SalesDetailsComponent}
            />
            <Route
              path={RoutingConstants?.sales_order_view}
              Component={SalesDetailsComponent}
            />
            <Route
              path={RoutingConstants?.sales_order_print}
              Component={SalesOrderPrintFormat}
            />
          </Route>
        )}
        {!isAccessDisabled("ST", "visible") && (
          <Route
            path={RoutingConstants?.warehousel2l}
            Component={WarehouseLayoutComponent}
          >
            {[RoutingConstants?.warehousel2l]?.map((item) => (
              <Route path={item} Component={WarehouseComponent} />
            ))}

            {[
              RoutingConstants?.warehousel2lCreate,
              RoutingConstants?.warehousel2lEdit,
            ]?.map((item) => (
              <Route path={item} Component={WarehouseFormComponent} />
            ))}

            {[RoutingConstants?.warehousel2lDetails]?.map((item) => (
              <Route path={item} Component={WarehouseDetailsComponent} />
            ))}

            {[RoutingConstants?.warehousel2lMap]?.map((item) => (
              <Route path={item} Component={WarehouseLocationFormComponent} />
            ))}
          </Route>
        )}

        {!isAccessDisabled("WH", "visible") && (
          <Route
            path={RoutingConstants?.warehouse}
            Component={WarehouseLayoutComponent}
          >
            {[RoutingConstants?.warehouse]?.map((item) => (
              <Route path={item} Component={WarehouseComponent} />
            ))}

            {[
              RoutingConstants?.warehouseCreate,
              RoutingConstants?.warehouseEdit,
            ]?.map((item) => (
              <Route path={item} Component={WarehouseFormComponent} />
            ))}

            {[RoutingConstants?.warehouseDetails]?.map((item) => (
              <Route path={item} Component={WarehouseDetailsComponent} />
            ))}

            {[RoutingConstants?.warehouseMap]?.map((item) => (
              <Route path={item} Component={WarehouseLocationFormComponent} />
            ))}
          </Route>
        )}
        {!isAccessDisabled("SAM", "visible") && (
          <Route
            path={RoutingConstants?.sampling}
            Component={SamplingLayoutComponent}
          >
            <Route
              path={RoutingConstants?.sampling}
              Component={SamplingComponent}
            />
            <Route
              path={RoutingConstants?.samplingCreate}
              Component={SamplingFormComponent}
            />
            <Route
              path={RoutingConstants?.samplingEdit}
              Component={SamplingFormComponent}
            />
            <Route
              path={RoutingConstants?.samplingDetails}
              Component={SamplingDetailsComponent}
            />
          </Route>
        )}

        {!isAccessDisabled("BAMAGT", "visible") && (
          <Route
            path={RoutingConstants?.batchmanagement}
            Component={BatchManagementLayoutComponent}
          >
            <Route
              path={RoutingConstants?.batchmanagement}
              Component={BatchManagementComponent}
            />
          </Route>
        )}
        {!isAccessDisabled("OT", "visible", true, "OP") && (
          <Route
            path={RoutingConstants.orderPickingList}
            Component={OrderPickingLayoutComponent}
          >
            <Route
              path={RoutingConstants.orderPickingList}
              Component={OrderPickingListComponent}
            />
            <Route
              path={RoutingConstants.createOrderPicking}
              Component={CreateOrderPickingComponent}
            />
            <Route
              path={RoutingConstants.orderPickingDetails}
              Component={OrderPickingDetailsComponent}
            />
            <Route
              path={RoutingConstants.orderPickingCancel}
              Component={OrderPickingCancelComponent}
            />
            <Route
              path={RoutingConstants.orderPickingPrint}
              Component={PickingPrintFormat}
            />
          </Route>
        )}
        {!isAccessDisabled("ST", "visible", true, "W2W") && (
          <Route
            path={RoutingConstants.warehouseTransferList}
            Component={WarehouseTransferLayoutComponent}
          >
            <Route
              path={RoutingConstants.warehouseTransferList}
              Component={WarehouseTransferListComponent}
            />
            <Route
              path={RoutingConstants.warehouseTransferCreate}
              Component={WarehouseTransferCreate}
            />
            <Route
              path={RoutingConstants.warehouseTransferDetailsList}
              Component={WarehouseTransferDetails}
            />
          </Route>
        )}
        {!isAccessDisabled("REP", "visible") && (
          <Route
            path={RoutingConstants.reports}
            Component={ReportsLayoutComponent}
          >
            {ReportsData?.map(
              (data) =>
                !isAccessDisabled("REP", "visible", true, data.key) && (
                  <Route path={data?.path} Component={ReportsComponent} />
                )
            )}
          </Route>
        )}
        {/* {!isAccessDisabled("SET", "visible") && ( */}
        <Route
          path={RoutingConstants.setup}
          Component={SettingsLayoutComponent}
        >
          {SettingsData?.map(
            (data) => (
              // !isAccessDisabled(
              //   "SET",
              //   "visible",
              //   true,
              //   data.key
              // ) && (
              <Route path={data?.path} Component={SettingsComponent} />
            )
            // )
          )}
        </Route>
        {/* )} */}
        {/* {!isAccessDisabled("SET", "visible") && ( */}
        <Route
          path={RoutingConstants.dashboard}
          Component={DashboardLayoutComponent}
        >
          <Route
            path={RoutingConstants.dashboard}
            Component={DashboardComponent}
          />
        </Route>
      </Route>
    )
  );

  return (
    <>
      <AppContext.Provider
        value={{
          userContext: userContext,
          setLoader: setLoader,
          signOut: signOut,
          setUserContext: setUserContext,
          DosageForms: dosageForm,
          ProductCategories: productCategory,
          refreshData: refreshData,
        }}
      >
        {loader?.showSpinner && (
          <div
            style={{
              display: "flex",
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              zIndex: 1,
            }}
          >
            <div className="spinner-border" />
            <Spinner Message={loader?.spinnerMessage} />
          </div>
        )}
        {userContext === null ? (
          <LoginComponent
            upateSpinner={(value, mesg) => {
              setLoader({
                showSpinner: value,
                spinnerMessage: mesg,
              });
            }}
          />
        ) : (
          <RouterProvider router={router} />
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </AppContext.Provider>
    </>
  );
};

export default LayoutComponent;
