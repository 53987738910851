import { useContext, useEffect, useState } from "react";
import { EmptyListConfigSupplierRpt, EmptySupplierReport, Statuses, SupplierReportKeys } from "../../../common/constants/generalConstants";
import { ReportsContext } from "../../../controllers/contexts/reports.context";
import { IListConfig, IListConfigSupplierRpt } from "../../../common/models";
import { cloneDeep } from "lodash";
import _ from "lodash";
import moment from "moment";
import { isNullUndefined } from "../../../common/methods/globalmethods";
import { ISupplierReport } from "../../../common/models/reports";
import { LabelConstants } from "../../../common/constants/labelConstants";

const SupplierViewComponent = () => {
    const {
        masterFilter,
        setMasterfilter,
        filteredMasterData,
        clearMasterData,
        setFilteredMasterData,
        masterData,
        getSupplierReports,
    } = useContext(ReportsContext);

    const [filter, setfilter] = useState<IListConfigSupplierRpt>(EmptyListConfigSupplierRpt);

    useEffect(() => {
        clearSearch();
    }, []);

    const handleQueryChange = (
        type: string,
        value: any,
        search: boolean = false
    ) => {
        const _filter: IListConfigSupplierRpt = cloneDeep(filter);
        if (search) {
            _filter.searchData[type] = value;
            searchData(_filter);
        } else {
            _filter[type] = value;
        }
        setfilter(_filter);
    };

    const returnSearchFilter = (_filter: ISupplierReport) => {
        let tempfilter: ISupplierReport = cloneDeep(_filter);
        for (const [key, value] of Object.entries(tempfilter)) {
          if (typeof value === "string" && _.isEmpty(value)) {
            delete tempfilter[key];
          } else if (Array.isArray(value) && value?.length <= 0) {
            delete tempfilter[key];
          } else if (typeof value === "number" && value <= 0) {
            delete tempfilter[key];
          } else if (value === null || value === undefined) {
            delete tempfilter[key];
          }
        }
        return tempfilter;
    };

    const searchData = (_filter: IListConfigSupplierRpt) => {
        let tempFilter: ISupplierReport = returnSearchFilter(_filter.searchData);
        let _filteredList = masterData?.filter((_item: any, _itemIdx: number) => {
            let result = true;
            for (const [key, value] of Object.entries(tempFilter)) {
                const data = _item[key]?.toString()?.toLowerCase();
                result = result && data?.includes(value?.toLowerCase());
            }
            return result;
        });
        setFilteredMasterData(_filteredList);
    };

    const handleSort = (column: string, isDate: boolean = false) => {
        const _filter: IListConfig = cloneDeep(masterFilter);
        if (_filter.sortItem === column) {
            _filter.sortDirection = _filter.sortDirection === "asc" ? "desc" : "asc";
            _filter.sortIsDate = isDate;
        } else {
            _filter.sortItem = column;
            _filter.sortDirection = "asc";
            _filter.sortIsDate = isDate;
        }
        setMasterfilter(_filter);
    };

    const clearSearch = () => {
        const _filter: IListConfigSupplierRpt = cloneDeep(filter);
        _filter.searchData = cloneDeep(EmptySupplierReport);
        _filter.name = "";
        _filter.status = [];
        _filter.validFrom = "";
        _filter.validTo = "";
        _filter.fromDate = "";
        _filter.toDate = "";
        setfilter(_filter);
        clearMasterData();
    };
    
    const checkIfSearchStringsAreEmpty = () => {
        let result = false;
        if (
          filter?.name === "" &&
          filter?.status?.length <= 0 &&
          filter?.fromDate === "" &&
          filter?.toDate === "" &&
          filter?.validFrom === "" &&
          filter?.validTo === ""
        ) {
          result = true;
        }
        return result;
    };

    return (
        <>
            <div className="box">
                <div className="box-body">
                <div className="col-lg-12 d-flex">
                    <div className="input-group w-p30 ms-10">
                    <input
                        className="form-control"
                        placeholder="Supplier Name"
                        value={filter?.name}
                        onChange={(event) =>
                        handleQueryChange("name", event.target.value)
                        }
                    />
                    </div>
                    <div className="input-group w-p30 ms-10">
                        <select
                            className="form-select"
                            value={filter?.status}
                            onChange={(e) =>
                            handleQueryChange("status", [e.target.value])
                            }
                        >
                            <option value={""}>Status</option>
                            <option key={`all`} value={LabelConstants.All}>
                                All
                            </option>
                            {Statuses.map(
                            (_statusType: any, _statusTypeIdx: number) => {
                                return (
                                <option
                                    key={`Status${_statusTypeIdx}`}
                                    value={_statusType}
                                >
                                    {_statusType}
                                </option>
                                );
                            }
                            )}
                        </select>
                    </div>
                    <div className="input-group w-p30 ms-10">
                    <input
                        className="form-control"
                        type="date"
                        placeholder="From Date"
                        value={filter?.validFrom ?? ""}
                        onChange={(event) =>
                        handleQueryChange("validFrom", event.target.value)
                        }
                    />
                    <i
                        style={{
                        position: "absolute",
                        top: -15,
                        left: 0,
                        fontSize: 10,
                        }}
                    >
                        Valid From
                    </i>
                    </div>
                    <div className="input-group w-p30 ms-10">
                    <input
                        className="form-control"
                        type="date"
                        placeholder="To Date"
                        value={filter?.validTo ?? ""}
                        onChange={(event) =>
                        handleQueryChange("validTo", event.target.value)
                        }
                    />
                    <i
                        style={{
                        position: "absolute",
                        top: -15,
                        left: 0,
                        fontSize: 10,
                        }}
                    >
                        Valid To
                    </i>
                    </div>
                    <div className="input-group w-p30 ms-10">
                    <input
                        className="form-control"
                        type="date"
                        placeholder="From Date"
                        value={filter?.fromDate ?? ""}
                        onChange={(event) =>
                        handleQueryChange("fromDate", event.target.value)
                        }
                    />
                    <i
                        style={{
                        position: "absolute",
                        top: -15,
                        left: 0,
                        fontSize: 10,
                        }}
                    >
                        Created From
                    </i>
                    </div>
                    <div className="input-group w-p30 ms-10">
                    <input
                        className="form-control"
                        type="date"
                        placeholder="To Date"
                        value={filter?.toDate ?? ""}
                        onChange={(event) =>
                        handleQueryChange("toDate", event.target.value)
                        }
                    />
                    <i
                        style={{
                        position: "absolute",
                        top: -15,
                        left: 0,
                        fontSize: 10,
                        }}
                    >
                        Created To
                    </i>
                    </div>
                    <div className="input-group w-p10 ms-10">
                    <button
                        type="button"
                        className="waves-effect waves-light btn btn-success rounded"
                        disabled={checkIfSearchStringsAreEmpty()}
                        onClick={() => {
                            getSupplierReports(filter);
                        }}
                    >
                        {LabelConstants.Search}
                    </button>
                    </div>
                    <div className="input-group w-p10 ms-10">
                    <button
                        type="button"
                        className="waves-effect waves-light btn btn-success-light rounded"
                        onClick={() => {
                        clearSearch();
                        }}
                    >
                        {LabelConstants.Clear}
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div className="box">
                <div className="box-body">
                    <div className="table-responsive">
                        <table
                            className="table table-hover table-bordered mb-0"
                            style={{ textAlign: "center" }}
                        >
                            <thead className="table-primary">
                                <tr>
                                    <th>S.No</th>
                                    {SupplierReportKeys?.map((item) => (
                                        <th onClick={() => handleSort(item.key)}>
                                            {item.placeholder}
                                            <i
                                                className={`ms-2 fa fa-sort${masterFilter?.sortItem === item.key
                                                        ? `-${masterFilter?.sortDirection} text-primary`
                                                        : " text-fade"
                                                    }`}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={"test"}>
                                    <td></td>
                                    {SupplierReportKeys?.map((item) => (
                                        <td>
                                            <input
                                                className="form-control"
                                                placeholder={item?.placeholder}
                                                value={filter?.searchData[item.key] ?? ""}
                                                onChange={(event) => {
                                                    handleQueryChange(item.key, event.target.value, true);
                                                }}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                {filteredMasterData?.map(
                                    (_item: ISupplierReport, _itemIdx: number) => {
                                        return (
                                            <tr key={_itemIdx}>
                                                <td>{_itemIdx + 1}</td>
                                                {SupplierReportKeys?.map((keydata) => {
                                                    if (keydata.type === "date") {
                                                        return (
                                                            <td>
                                                                {moment(_item[keydata.key]).format(
                                                                    "DD/MM/yyyy"
                                                                )}
                                                            </td>
                                                        );
                                                    } else {
                                                        return <td>{_item[keydata.key] ?? ""}</td>;
                                                    }
                                                })}
                                            </tr>
                                        );
                                    }
                                )}
                                {(isNullUndefined(filteredMasterData) ||
                                    filteredMasterData?.length <= 0) && (
                                        <tr>
                                            <td
                                                colSpan={SupplierReportKeys?.length + 1}
                                                align="center"
                                            >
                                                No data to display!!
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SupplierViewComponent