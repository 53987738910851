import {
  ICustomer,
  ICustomerAddress,
  ICustomerAddressContact,
  IEmployeeAddress,
  IEmployeeAddressContact,
  IGRNOrderProduct,
  IGRNProductWithBatchDetails,
  IGRNProductLocation,
  IGoodsReceipt,
  IGoodsReceiptServerRequestObject,
  IListConfig,
  ILoaderConfig,
  ILoginRequest,
  IGRNOrderItem,
  IStatusUpdate,
  ISupplier,
  ISupplierAddress,
  ISupplierAddressContact,
  ITransporter,
  ITransporterAddress,
  ITransporterAddressContact,
  IWarehouse,
  IWarehouseMapForm,
  IDeleteConfirmationProps,
  IListConfigGRN,
  IGRNAisleToBatchProps,
  IGRNAisleToBatchLocationInfo,
  IListConfigPO,
  IOrders,
  IListConfigTransport,
  IListConfigProduct,
  IListConfigCustomer,
  IListConfigRO,
  IListConfigSO,
  ILocationUpdater,
  IForgotPwdRequest,
  IChangePwdRequest,
  IListConfigDO,
  IListConfigOP,
  IListConfigPORpt,
  IListConfigStockRpt,
  IListConfigPlanningRpt,
  IListConfigGoodsMovementRpt,
  IListConfigGoodsReceiptRpt,
  IListConfigBatchTraceabilityRpt,
  IListConfigProductMovementRpt,
  IListConfigDispatchRpt,
  IListConfigPendingSORpt,
  IListConfigSampling,
  IListConfigSupplierRpt,
  IListConfigCustomerRpt,
  IListConfigTransporterRpt,
  IListConfigProductRpt,
} from "../models";
import { IDeliveryOrder } from "../models/deliveryorder";
import { IEmployee } from "../models/employee";
import {
  CancelledProductInventory,
  SaveNewLocationDetails,
} from "../models/pickingorder";
import { IProducts } from "../models/products";
import { IPurchase, Iitems } from "../models/purchase";
import {
  IBatchTraceabilityReport,
  ICustomerReport,
  IDispatchDTOS,
  IDispatchReport,
  IGoodsMovementReport,
  IGoodsReceiptReport,
  IPOReport,
  IPendingSOReport,
  IPlanningReport,
  IProductMovementReport,
  IProductReport,
  IStockReport,
  ISupplierReport,
  ITransactionReport,
} from "../models/reports";
import { IROProductDetails, IReturnOrders } from "../models/returnorders";
import {
  ISODetailsSeverFormat,
  ISalesOrder,
  ISalesOrdreServerFormat,
} from "../models/salesorder";
import { ISampling } from "../models/sampling";
import { IAccessControlAndPermissions } from "../models/settings";
import { MAX_PAGE_RECORD_SIZE } from "./apiConstants";
import { LabelConstants } from "./labelConstants";
import { RoutingConstants } from "./routingConstants";

export const ERROR = -1;
export const SUCCESS = 0;

export const ERROR_MESSAGES = {
  MAX_ALLOCATED: "Maximum number of pallets already allocated!!",
  ENTER_NO_OF_PALLETS: "Enter number of pallets required before allocation!!",
  PALLOT_DE_ALLOCATION_SUCCESS: "Pallet deallocated successfully!!",
  PALLOT_NOT_IN_USE: "Select a free place to continue with allocation!!",
};

export const Constants = {
  //#region DateFormat
  dateLocale: "en-IN",
  dateformatOptionsDateTime: {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  },
  dateformatOptionsDate: {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  dateformatOptionsMonthYear: {
    year: "numeric",
    month: "long",
  },
  dateformatOptionsTime: {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  },
  monthsLong: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  monthsShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Ma",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  //#endregion
};

export const validateEmailRegex = (value: string) => {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return emailRegex.test(value);
};

export const DocumentUploadKeys = {
  Customer: "CUST",
  Supplier: "SUP",
  Transporter: "TRANS",
  Employee: "EMP",
  Sampling: "sam",
};

export const EmptyListConfigTransport: IListConfigTransport = {
  status: [],
  transporterId: null,
  transporterName: "",
  search: true,
  searchLocally: true,
};

export const EmptyListConfigCustomer: IListConfigCustomer = {
  search: true,
  searchLocally: true,
  status: [],
  customerName: "",
};

export const EmptyLocationUpdater: ILocationUpdater = {
  ids: [],
  status: "",
};

export const EmptyListConfigDO: IListConfigDO = {
  deliveryNum: "",
  transporterId: 0,
  createdFromDate: "",
  createdToDate: "",
  estDelFromDate: "",
  estDelToDate: "",
  status: [],
  search: false,
  soNum: "",
};

export const EmptyPlanningReport: IPlanningReport = {
  warehouseId: null,
  status: "",
  onHand: null,
  onOrder: null,
  onCommitted: null,
  stockStatus: "",
  qty: null,
  productCode: null,
  prodCatId: null,
  palletRefNum: "",
  batchNo: "",
  batchExpiry: "",
  warehouseName: "",
  productName: "",
};

export const EmptyBatchTraceabilityReport: IBatchTraceabilityReport = {
  productName: "",
  productCode: "",
  prodId: null,
  prodCatId: null,
  manufaturer: "",
  batchNo: "",
  batchQty: null,
  batchExpiry: "",
  samplingDate: "",
  releasedDate: "",
  rejectedDate: "",
  grnNumber: "",
  receivedDate: "",
  supplier: "",
  soNumber: "",
  orderQty: null,
  customer: "",
  dispatchDate: "",
  deliveredDate: "",
  trackingNumber: "",
  transporter: "",
  remainingQty: null,
  samplingQty: null,
  returnOrderNum: "",
  returnQty: null,
  returnDate: ""
}

export const EmptyDispatchDTOS: IDispatchDTOS = {
  soNumber: "",
  orderQty: null,
  customer: "",
  dispatchDate: "",
  deliveredDate: "",
  trackingNumber: "",
  transporter: "",
  returnOrderDetails: [],
};

export const EmptyProductMovementReport: IProductMovementReport = {
  productId: null,
  prodCategoryId: null,
  customerId: null,
  dateOfDispatch: "",
  quantity: null,
  trackingNumber: "",
  soNumber: "",
  orderPickingNumber: "",
  deliveryOrderNumber: "",
  customerPo: "",
  productName: "",
  customerName: "",
  productCode: "",
  batchNo: "",
  batchExpiry: "",
};

export const EmptyPendingSOReport: IPendingSOReport = {
  soNumber: "",
  customerOrder: "",
  orderDate: "",
  orderQty: null,
  customerName: "",
  address1: "",
  address2: "",
  town: "",
  county: "",
  postCode: "",
  pendingQty: null,
  status: "",
  prodId: null,
};

export const EmptyDispatchReport: IDispatchReport = {
  soNumber: "",
  pickingOrderNum: "",
  deliveryNum: "",
  soReferenceNum: "",
  soDate: "",
  dispatchDate: "",
  customerName: "",
  address1: "",
  address2: "",
  town: "",
  county: "",
  postCode: "",
  remarks: "",
  deliveryDate: "",
  status: "",
  totalItems: "",
  transporter: "",
  trackingNum: "",
  prodId: null,
  prodCatId: null,
  dispatchQty: null,
  productName: "",
  productCode: "",
};

export const EmptyGoodsMovementReport: IGoodsMovementReport = {
  productCode: "",
  productName: "",
  qty: null,
  batchNum: "",
  batchExpiryDate: "",
  movementType: "",
  from: null,
  to: null,
  dateOfTransaction: "",
  fromWarehouseName: "",
  toShelfId: "",
  fromShelfId: "",
  toWarehouseName: "",
};

export const EmptyGoodsReceiptReport: IGoodsReceiptReport = {
  poNum: "",
  poCreatedDate: "",
  expDeliveryDate: "",
  productCode: null,
  prodCatId: null,
  quantity: null,
  receivedQty: null,
  pendingQty: null,
  grnCreatedDate: "",
  grnNum: "",
  supplierId: null,
  warehouseId: null,
  poStatus: "",
  batchNo: "",
  batchExpiry: "",
  batchQty: "",
  productName: "",
  supplierName: "",
  warehouseName: "",
};

export const EmptyStockReport: IStockReport = {
  warehouseId: null,
  warehouseName: "",
  locationId: null,
  productCode: null,
  productName: "",
  prodCategoryId: null,
  qty: null,
  status: "",
  palletRefNum: "",
  batchNo: "",
  batchExpiryDate: "",
  isgroupkey: false,
};

export const EmptyPOReport: IPOReport = {
  poNum: "",
  poCreatedDate: "",
  expDeliveryDate: "",
  productCode: null,
  prodCatId: null,
  quantity: null,
  receivedQty: null,
  pendingQty: null,
  grnCreatedDate: "",
  grnNum: "",
  supplierId: null,
  warehouseId: null,
  poStatus: "",
  productName: "",
  supplierName: "",
  warehouseName: "",
};

export const EmptySupplierReport: ISupplierReport = {
  name: "",
  type: "",
  validFrom: "",
  validTo: "",
  address1: "",
  address2: "",
  city: "",
  county: "",
  country: "",
  postCode: "",
  status: "",
  contactName: "",
  phone: "",
  email: "",
}

export const  EmptyCustomerReport: ICustomerReport = {
  name: "",
  type: "",
  productCategory: "",  
  validFrom: "",
  validTo: "",
  address1: "",
  address2: "",
  city: "",
  county: "",
  country: "",
  postCode: "",
  status: "",
  contactName: "",
  phone: "",
  email: "",
}

export const EmptyTransporterReport: ITransactionReport = {
  name: "",
  validFrom: "",
  validTo: "",
  address1: "",
  address2: "",
  city: "",
  county: "",
  country: "",
  postCode: "",
  status: "",
  contactName: "",
  phone: "",
  email: "",
}

export const EmptyProductReport: IProductReport = {
  productCode: "",
  productName: "",
  packSize: "",
  dosageForm: "",
  prodCategory: "",
  prodManufacturer: "",
  prodLicenseNo: "",
  gtin: "",
  pipCode: null,
  minStockQty: null,
  status: "",
} 

export const POReportKeys = [
  { key: "poNum", placeholder: "PO Num", type: "string" },
  { key: "poCreatedDate", placeholder: "Created Date", type: "date" },
  { key: "expDeliveryDate", placeholder: "Delivery Date", type: "date" },
  { key: "productCode", placeholder: "Prod Code", type: "number" },
  { key: "productName", placeholder: "Prod Name", type: "string" },
  { key: "quantity", placeholder: "Order Qty", type: "number" },
  { key: "receivedQty", placeholder: "GRN Qty", type: "number" },
  { key: "pendingQty", placeholder: "Pending Qty", type: "number" },
  { key: "grnCreatedDate", placeholder: "GRN Date", type: "date" },
  { key: "grnNum", placeholder: "GRN Num", type: "string" },
  { key: "supplierName", placeholder: "Supplier Name", type: "string" },
  { key: "warehouseName", placeholder: "Warehouse", type: "string" },
  { key: "poStatus", placeholder: "PO Status", type: "string" },
];

export const StockReportKeys = [
  { key: "warehouseName", placeholder: "Warehouse Name", type: "string" },
  { key: "locationId", placeholder: "Location", type: "number" },
  { key: "productCode", placeholder: "Prod Code", type: "number" },
  { key: "productName", placeholder: "Product", type: "string" },
  { key: "qty", placeholder: "Qty", type: "number" },
  { key: "status", placeholder: "Status", type: "string" },
  { key: "palletRefNum", placeholder: "Pallet No", type: "string" },
  { key: "batchNo", placeholder: "Batch No", type: "string" },
  { key: "batchExpiryDate", placeholder: "Batch Exp. Date", type: "date" },
];

export const PlanningReportKeys = [
  { key: "warehouseName", placeholder: "Warehouse Name", type: "string" },
  { key: "productCode", placeholder: "Prod Code", type: "number" },
  { key: "productName", placeholder: "Prod Name", type: "string" },
  { key: "totalRelQty", placeholder: "Total Released", type: "number" },
  { key: "totalQrnQty", placeholder: "Total Quarantine", type: "number" },
  { key: "onHand", placeholder: "Total In Hand", type: "number" },
  { key: "onOrder", placeholder: "On Order", type: "number" },
  { key: "onCommitted", placeholder: "On Committed", type: "number" },
];

export const GoodsMovementReportKeys = [
  { key: "productCode", placeholder: "Product Code", type: "string" },
  { key: "productName", placeholder: "Product Name", type: "string" },
  { key: "qty", placeholder: "Qty", type: "number" },
  { key: "batchNum", placeholder: "Batch No", type: "string" },
  { key: "batchExpiryDate", placeholder: "Expiry", type: "date" },
  { key: "movementType", placeholder: "Movement Type", type: "string" },
  {
    key: "fromWarehouseName",
    placeholder: "From",
    type: "string",
    l2ldata: "fromShelfId",
  },
  {
    key: "toWarehouseName",
    placeholder: "To",
    type: "string",
    l2ldata: "toShelfId",
  },
  {
    key: "dateOfTransaction",
    placeholder: "Date Of Transaction",
    type: "date",
  },
  {
    key: "createdBy",
    placeholder: "Created By",
    type: "string",
  },
  {
    key: "createdAt",
    placeholder: "Created At",
    type: "datetime",
  },
];

export const GoodsReceiptReportKeys = [
  { key: "poNum", placeholder: "PO No", type: "string" },
  { key: "poCreatedDate", placeholder: "PO Date", type: "date" },
  { key: "expDeliveryDate", placeholder: "Del. Date", type: "date" },
  { key: "productCode", placeholder: "Prod Code", type: "number" },
  { key: "productName", placeholder: "Prod Name", type: "string" },
  { key: "quantity", placeholder: "Order Qty", type: "number" },
  { key: "receivedQty", placeholder: "GRN Qty", type: "number" },
  {
    key: "grnCreatedDate",
    placeholder: "GRN Date",
    type: "date",
  },
  { key: "grnNum", placeholder: "GRN No.", type: "string" },
  { key: "supplierName", placeholder: "Supp. Name", type: "string" },
  { key: "warehouseName", placeholder: "Warehouse", type: "string" },
  { key: "poStatus", placeholder: "PO Status", type: "string" },
  { key: "batchNo", placeholder: "Batch No", type: "string" },
  { key: "batchExpiry", placeholder: "Batch Exp.", type: "date" },
  { key: "batchQty", placeholder: "Batch Qty.", type: "string" },
];

export const UserAccessKeys = [
  { name: "Module" },
  { name: "Create" },
  { name: "View" },
  { name: "Edit" },
  { name: "Approval" },
  { name: "Delete" },
  { name: "Update Status" },
  { name: "Create Locations" },
  { name: "Visible" },
  { name: "Download" },
  { name: "" },
];

export const SupplierReportKeys = [
  { key: "name", placeholder: "Supplier Name", type: "string"},
  { key: "type", placeholder: "Type", type: "string"},
  { key: "validFrom", placeholder: "Valid From", type: "date"},
  { key: "validTo", placeholder: "Valid To", type: "date"},
  { key: "status", placeholder: "Status", type: "string"},
  { key: "address1", placeholder: "Address 1", type: "string"},
  { key: "address2", placeholder: "Address 2", type: "string"},
  { key: "city", placeholder: "City", type: "string"},
  { key: "county", placeholder: "County", type: "string"},
  { key: "country", placeholder: "Country", type: "string"},
  { key: "postCode", placeholder: "Post Code", type: "string"},
  { key: "contactName", placeholder: "Contact Name", type: "string"},
  { key: "phone", placeholder: "Phone", type: "string"},
  { key: "email", placeholder: "Email", type: "string"},
]

export const CustomerReportKeys = [
  { key: "name", placeholder: "Customer Name", type: "string"},
  { key: "type", placeholder: "Type", type: "string"},
  { key: "productCategory", placeholder: "Product Category", type: "string"},
  { key: "validFrom", placeholder: "Valid From", type: "date"},
  { key: "validTo", placeholder: "Valid To", type: "date"},
  { key: "status", placeholder: "Status", type: "string"},
  { key: "address1", placeholder: "Address 1", type: "string"},
  { key: "address2", placeholder: "Address 2", type: "string"},
  { key: "city", placeholder: "City", type: "string"},
  { key: "county", placeholder: "County", type: "string"},
  { key: "country", placeholder: "Country", type: "string"},
  { key: "postCode", placeholder: "Post Code", type: "string"},
  { key: "contactName", placeholder: "Contact Name", type: "string"},
  { key: "phone", placeholder: "Phone", type: "string"},
  { key: "email", placeholder: "Email", type: "string"},
]

export const TransporterReportKeys = [
  { key: "name", placeholder: "Transporter Name", type: "string"},
  { key: "validFrom", placeholder: "Valid From", type: "date"},
  { key: "validTo", placeholder: "Valid To", type: "date"},
  { key: "status", placeholder: "Status", type: "string"},
  { key: "address1", placeholder: "Address 1", type: "string"},
  { key: "address2", placeholder: "Address 2", type: "string"},
  { key: "city", placeholder: "City", type: "string"},
  { key: "county", placeholder: "County", type: "string"},
  { key: "country", placeholder: "Country", type: "string"},
  { key: "postCode", placeholder: "Post Code", type: "string"},
  { key: "contactName", placeholder: "Contact Name", type: "string"},
  { key: "phone", placeholder: "Phone", type: "string"},
  { key: "email", placeholder: "Email", type: "string"},
]

export const ProductsReportKeys = [
  {key: "productCode", placeholder: "Product Code", type: "string"},
  {key: "productName", placeholder: "Product Name", type: "string"},
  {key: "packSize", placeholder: "Pack Size", type: "string"},
  {key: "dosageForm", placeholder: "Dosage Form", type: "string"},
  {key: "prodCategory", placeholder: "Category", type: "string"},
  {key: "prodManufacturer", placeholder: "Manufacturer", type: "string"},
  {key: "prodLicenseNo", placeholder: "Product License Number", type: "string"},
  {key: "gtin", placeholder: "GTINBaroode", type: "string"},
  {key: "pipCode", placeholder: "PIP Code", type: "string"},
  {key: "minStockQty", placeholder: "Min Stock Qty", type: "string"},
  {key: "status", placeholder: "Status", type: "string"},
]

export const BatchTraceabilityReportKeys = [
  { key: "productCode", placeholder: "Product Code", type: "string" },
  { key: "productName", placeholder: "Product Name", type: "string" },
  { key: "manufaturer", placeholder: "Manufaturer", type: "string" },
  { key: "supplier", placeholder: "Supplier", type: "string" },
  {
    key: "batchQty",
    placeholder: "Batch Qty",
    type: "number",
  },
  { key: "batchNo", placeholder: "Batch No", type: "string" },
  { key: "batchExpiry", placeholder: "Expiry Date", type: "date" },
  { key: "grnNumber", placeholder: "GRN Number", type: "string" },
  { key: "receivedDate", placeholder: "Received Date", type: "date" },
  { key: "samplingDate", placeholder: "Sampling Date", type: "date" },
  { key: "samplingQty", placeholder: "Sampling Qty", type: "number" },
  { key: "releasedDate", placeholder: "Released Date", type: "date" },
  { key: "rejectedDate", placeholder: "Rejected Date", type: "date" },
  { key: "soNumber", placeholder: "SO Number", type: "string" },
  { key: "orderQty", placeholder: "Order Qty", type: "number" },
  { key: "customer", placeholder: "Customer Name", type: "string" },
  { key: "dispatchDate", placeholder: "Dispatched Date", type: "date" },
  { key: "deliveredDate", placeholder: "Delivery Date", type: "date" },
  { key: "trackingNumber", placeholder: "Tracking number", type: "string" },
  { key: "transporter", placeholder: "Transporter", type: "string" },
  { key: "returnOrderNum", placeholder: "Return No", type: "string" },
  { key: "returnQty", placeholder: "Return Qty", type: "number" },
  { key: "returnDate", placeholder: "Return Date", type: "string" },

];

export const ProductMovementReportKeys = [
  { key: "productCode", placeholder: "Product Code", type: "string" },
  { key: "productName", placeholder: "Product Name", type: "string" },
  { key: "customerName", placeholder: "Customer", type: "string" },
  { key: "dateOfDispatch", placeholder: "Date of Dispatch", type: "date" },
  { key: "quantity", placeholder: "Qty", type: "number" },
  { key: "trackingNumber", placeholder: "Tracking Number", type: "string" },
  { key: "soNumber", placeholder: "SO Num", type: "string" },
  { key: "orderPickingNumber", placeholder: "Order Picking#", type: "string" },
  {
    key: "deliveryOrderNumber",
    placeholder: "Delivery Order#",
    type: "string",
  },
  { key: "customerPo", placeholder: "Customer PO", type: "string" },
  { key: "batchNo", placeholder: "Batch Number", type: "string" },
  { key: "batchExpiry", placeholder: "Batch Expiry", type: "string" },
];

export const DispatchReportKeys = [
  { key: "soNumber", placeholder: "SO#", type: "string" },
  { key: "pickingOrderNum", placeholder: "Order Picking#", type: "string" },
  { key: "deliveryNum", placeholder: "Delivery Order#", type: "string" },
  { key: "soReferenceNum", placeholder: "SO Reference", type: "string" },
  { key: "soDate", placeholder: "SO Date", type: "date" },
  { key: "dispatchDate", placeholder: "Dispatch Date", type: "date" },
  { key: "customerName", placeholder: "Customer", type: "string" },
  { key: "address1", placeholder: "Address1", type: "string" },
  { key: "address2", placeholder: "Address2", type: "string" },
  { key: "town", placeholder: "Town", type: "string" },
  { key: "county", placeholder: "County", type: "string" },
  { key: "postCode", placeholder: "PostCode", type: "string" },
  { key: "remarks", placeholder: "Remarks", type: "string" },
  { key: "deliveryDate", placeholder: "Delivery Date", type: "date" },
  { key: "status", placeholder: "Status", type: "string" },
  { key: "totalItems", placeholder: "TotalItems", type: "string" },
  { key: "transporter", placeholder: "Transporter", type: "string" },
  { key: "trackingNum", placeholder: "Tracking#", type: "string" },
  { key: "productCode", placeholder: "Product Code", type: "string" },
  { key: "productName", placeholder: "Product Name", type: "string" },
  { key: "dispatchQty", placeholder: "Dispacth Qty", type: "string" },
];

export const PendingSOReportKeys = [
  { key: "soNumber", placeholder: "SO Number", type: "string" },
  {
    key: "customerOrder",
    placeholder: "Customer Order Reference",
    type: "string",
  },
  { key: "productCode", placeholder: "Product Code", type: "number"},
  { key: "productName", placeholder: "Product Name", type: "string"},
  { key: "orderDate", placeholder: "Order Date", type: "date" },
  { key: "orderQty", placeholder: "Order Qty", type: "number" },
  { key: "customerName", placeholder: "Customer Name", type: "string" },
  { key: "address1", placeholder: "Address1", type: "string" },
  { key: "address2", placeholder: "Address2", type: "string" },
  { key: "town", placeholder: "Town", type: "string" },
  { key: "county", placeholder: "County", type: "string" },
  { key: "postCode", placeholder: "PostCode", type: "string" },
  { key: "pendingQty", placeholder: "Pending Qty", type: "number" },
  { key: "status", placeholder: "Status", type: "string" },
];

export const EmptyListConfigSupplierRpt: IListConfigSupplierRpt = {
  name: "",
  fromDate: "",
  toDate: "",
  validFrom: "",
  validTo: "",
  status: [],
  searchData: EmptySupplierReport,
};

export const EmptyListConfigCustomerRpt: IListConfigCustomerRpt = {
  name: "",
  fromDate: "",
  toDate: "",
  validFrom: "",
  validTo: "",
  status: [],
  searchData: EmptyCustomerReport,
}

export const EmptyListConfigTransportRpt: IListConfigTransporterRpt = {
  name: "",
  fromDate: "",
  toDate: "",
  validFrom: "",
  validTo: "",
  status: [],
  searchData: EmptyTransporterReport,
}

export const EmptyListConfigProductRpt: IListConfigProductRpt = {
  name: "",
  fromDate: "",
  toDate: "",
  status: [],
  prodCode: null,
  searchData: EmptyProductReport,
}

export const EmptyListConfigPlanningRpt: IListConfigPlanningRpt = {
  warehouseId: null,
  status: "",
  prodId: null,
  batchNo: "",
  searchData: EmptyPlanningReport,
};

export const EmptyListConfigGoodsReceiptRpt: IListConfigGoodsReceiptRpt = {
  grnNum: "",
  status: [],
  warehouse: "",
  grnTypes: [],
  createdDateFrom: "",
  createdDateTo: "",
  poNum: "",
  searchData: EmptyGoodsReceiptReport,
  productId: null,
};

export const EmptyListConfigStockRpt: IListConfigStockRpt = {
  warehouseId: null,
  status: "",
  prodId: null,
  batchNo: "",
  searchData: EmptyStockReport,
};

export const EmptyListConfigGoodsMovementRpt: IListConfigGoodsMovementRpt = {
  searchData: EmptyGoodsMovementReport,
  prodId: null,
  movementType: "",
};

export const EmptyListConfigBatchTraceabilityRpt: IListConfigBatchTraceabilityRpt =
  {
    searchData: EmptyBatchTraceabilityReport,
    batchNo: "",
    fromDate: "",
    toDate: "",
    prodId: null,
  };

export const EmptyListConfigProductMovementRpt: IListConfigProductMovementRpt =
  {
    searchData: EmptyProductMovementReport,
    productId: null,
    customerId: null,
  };

export const EmptyListConfigDispatchRpt: IListConfigDispatchRpt = {
  searchData: EmptyDispatchReport,
  productId: null,
  customerId: null,
  status: [],
};

export const EmptyListConfigPendingSORpt: IListConfigPendingSORpt = {
  searchData: EmptyPendingSOReport,
  customerId: null,
  status: [],
  prodId: null,
};

export const EmptyListConfigPORpt: IListConfigPORpt = {
  poNum: "",
  status: [],
  supplier: 0,
  createdDateFrom: "",
  createdDateTo: "",
  exDeliveryDateFrom: "",
  exDeliveryDateTo: "",
  searchData: EmptyPOReport,
  productId: null,
};

export const EmptyListConfigOP: IListConfigOP = {
  soNum: "",
  pickingNum: "",
  status: [],
  customer: 0,
  createdFromDate: "",
  createdToDate: "",
  search: false,
};

export const EmptyListConfigSampling: IListConfigSampling = {
  batchNo: "",
  status: [],
  fromDate: "",
  toDate: "",
  search: false,
};

export const EmptyListConfigSO: IListConfigSO = {
  soNum: "",
  status: [],
  customerId: 0,
  customerName: "",
  createdFrom: "",
  createdTo: "",
  deliveryDateFrom: "",
  deliveryDateTo: "",
  customerRefNum: "",
  search: false,
};

export const EmptyListConfigProduct: IListConfigProduct = {
  search: true,
  searchLocally: true,
  status: [],
  productCode: null,
  productName: "",
};

export const EmptyListConfig: IListConfig = {
  selectedFilter: "",
  searchQuery: "",
  sortDirection: "asc",
  sortItem: "",
  totalPageCount: 0,
  totalRecords: 0,
  currentPageSize: MAX_PAGE_RECORD_SIZE,
  currentPage: 1,
  sortIsDate: false,
};

export const EmptyListPOSearchConfig: IListConfigPO = {
  poNum: "",
  status: [],
  supplier: 0,
  createdDateFrom: "",
  createdDateTo: "",
  exDeliveryDateFrom: "",
  exDeliveryDateTo: "",
  search: false,
};

export const EmptyROProductDetails: IROProductDetails = {};

export const EmptyListROSearchConfig: IListConfigRO = {
  search: false,
  returnOrderNum: "",
  customerId: 0,
  soNum: "",
  deliveryNum: "",
  fromDate: "",
  toDate: "",
};

export const EmptyIOrders: IOrders = {
  value: "",
  label: "",
  text: "",
};

export const EmptyListGRNSearchConfig: IListConfigGRN = {
  grnNum: "",
  status: [],
  warehouse: "",
  grnTypes: [],
  createdDateFrom: "",
  createdDateTo: "",
  search: false,
};

export const EmptySpinner: ILoaderConfig = {
  showSpinner: false,
  spinnerMessage: "Loading...",
};

export const EmptyStatusUpdate: IStatusUpdate = {
  ids: [],
  comments: "",
  status: "",
};

export const EmptyLoginRequest: ILoginRequest = {
  password: "",
  username: "",
  passcode: 0,
};
export const EmptyForgotPwdRequest: IForgotPwdRequest = {
  username: "",
  passcode: 0,
  newPassword: "",
  confirmPassword: "",
};
export const EmptyChangePwdRequest: IChangePwdRequest = {
  username: localStorage.getItem("contextInfo")
    ? JSON.parse(localStorage.getItem("contextInfo")).data.username
    : "",
  passcode: 0,
  newPassword: "",
  oldPassword: "",
  confirmPassword: "",
};

export const GENERAL_CONSTANTS = {
  IN_USE: "INUSE", //can use
  NOT_IN_USE: "NOT_INUSE", //cannot use
  OCCUPIED: "OCCUPIED", //cannot use
};

export const LOCATION_STATUS_COLORS = {
  RELEASE: { name: "REL", color: "bg-ash", text: "Released" },
  QUARANTINE: { name: "QRN", color: "bg-warning", text: "Quarantine" },
  REJECTED: { name: "REJ", color: "bg-red", text: "Rejected" },
  IN_USE: { name: "INUSE", color: "bg-success", text: "Free" },
  DIFFERENT: { name: "DIF", color: "bg-danger", text: "Different" },
  NOT_IN_USE: {
    name: "NOT_IN_USE",
    color: "bg-warning-light",
    text: "Not In Use",
  },
};

//#region Supplier
export const NewSupplier: ISupplier = {
  supName: "",
  supType: "",
  validFrom: "",
  validTo: "",
  comments: "",
  status: "ACTIVE",
  deleted: false,
  supAddresses: [
    {
      address1: "",
      address2: "",
      city: "",
      county: "",
      country: "",
      postCode: "",
      addressUsage: "",
      supCommunication: [
        {
          contactName: "",
          phoneNumber: "",
          email: "",
          designationCountryCode: "",
        },
        {
          contactName: "",
          phoneNumber: "",
          email: "",
          designationCountryCode: "",
        },
        {
          contactName: "",
          phoneNumber: "",
          email: "",
          designationCountryCode: "",
        },
      ],
    },
  ],
  PIPCode: "",
};

export const NewSupplierAddress: ISupplierAddress = {
  address1: "",
  address2: "",
  city: "",
  county: "",
  country: "",
  postCode: "",
  addressUsage: "",
  supCommunication: [
    {
      contactName: "",
      phoneNumber: "",
      email: "",
      designationCountryCode: "",
    },
    {
      contactName: "",
      phoneNumber: "",
      email: "",
      designationCountryCode: "",
    },
    {
      contactName: "",
      phoneNumber: "",
      email: "",
      designationCountryCode: "",
    },
  ],
};

export const NewSupplierAddressContact: ISupplierAddressContact = {
  contactName: "",
  phoneNumber: "",
  email: "",
  designationCountryCode: "",
};
//#endregion

//#region Employee
export const NewEmployee: IEmployee = {
  empName: "", // "penchalaswamy",
  gender: "", // "Male",
  dob: "", // "2023-12-12",
  dateOfJoining: "", // "2023-12-21",
  dateOfResign: "", // "2023-12-22",
  username: "", // "Swamy123",
  password: "", // "12344",
  passcode: 0, // 1234,
  status: "ACTIVE",
  deleted: false,
  designation: "",
  empAddresses: [
    {
      address1: "",
      address2: "",
      city: "",
      county: "",
      country: "",
      postCode: "",
      addressUsage: "",
      empCommunication: [
        {
          contactName: "",
          phoneNumber: "",
          email: "",
          designationCountryCode: "",
        },
      ],
    },
  ],
};

export const NewEmployeeAddress: IEmployeeAddress = {
  address1: "",
  address2: "",
  city: "",
  county: "",
  country: "",
  postCode: "",
  addressUsage: "",
  empCommunication: [
    {
      contactName: "",
      phoneNumber: "",
      email: "",
      designationCountryCode: "",
    },
  ],
};

export const NewEmployeeAddressContact: IEmployeeAddressContact = {
  contactName: "",
  phoneNumber: "",
  email: "",
  designationCountryCode: "",
};
//#endregion

//#region sampling
// export const NewSampling: ISampling ={
//     deleted: false,

// }

//#region  Product
export const NewGRNOrderProduct: IGRNOrderProduct = {
  poDetailsId: 0,
  prodId: 0,
  prodCatId: 0,
  quantity: 0,
  price: 0,
  vat: 0,
  carriageAmount: 0,
  totalAmount: 0,
  selected: false,
  receivedQty: 0,
};
//#endregion

export const NewGRNServerCreateProductLocation: IGRNProductLocation = {
  locationId: 0,
  palletRefNum: "",
  locQty: 0,
};

//#region  NewGRNProductLocation
export const NewGRNProductLocation: IGRNProductLocation = {
  locationId: 0,
  shelfId: "",
  prodLocId: 0,
  grDetId: 0,
  palletRefNum: "",
  locQty: 0,
  batchNo: "",
};
//#endregion

export const NewGRNServerCreateRequestProduct: IGRNProductWithBatchDetails = {
  prodLocs: [],
  prodId: "",
  prodCatId: "",
  batchNo: "",
  batchExpDate: "",
  status: "",
  receivedQty: "",
  palletsCount: "",
  remainingQty: "",
  samplingReq: true,
};

//#region  IGRNProductWithBatchDetails
export const NewGRNProduct: IGRNProductWithBatchDetails = {
  prodId: "",
  prodCatId: "",
  batchNo: "",
  batchExpDate: "",
  status: "",
  receivedQty: "",
  palletsCount: "",
  isSamplingReq: false,
  remainingQty: "",
  prodLocs: [],
  samplingReq: false,
  goodsRecId: null,
  grDetId: null,
};
//#endregion

export const saveNewLocationDetailsObject: SaveNewLocationDetails = {
  locationId: 0,
  productInventory: {
    id: 0,
    locationId: 0,
    warehouseId: 0,
    shelfId: "",
    batchNo: "",
    batchExpiryDate: "",
    prodId: 0,
    prodCatId: 0,
    quantity: 0,
    status: "REL",
    palletRefNum: "",
  },
};

export const NewGRNServerEditProductLocation: IGRNProductLocation = {
  prodLocId: 0,
  grDetId: 0,
  locationId: 0,
  palletRefNum: "",
  locQty: 0,
};

export const NewGRNServerEditGRDetails: IGRNProductWithBatchDetails = {
  grDetId: 0,
  goodsRecId: 0,
  prodId: "",
  prodCatId: "",
  batchNo: "",
  batchExpDate: "",
  status: "",
  receivedQty: "",
  palletsCount: "",
  remainingQty: "",
  prodLocs: [],
  samplingReq: true,
};

export const NewGRNServerEditObject: IGoodsReceiptServerRequestObject = {
  goodsRecId: 0,
  warehouseId: "",
  goodsRecType: "",
  orderNum: "",
  receiptDate: "",
  sealNum: "",
  description: "",
  status: "",
  createdDate: "",
  createdBy: "",
  modifiedDate: "",
  modifiedBy: "",
  grDetails: [],
  goodsReceiptNumber: "",
  sealed: false,
  deleted: false,
  supValid: false,
};

//#region  Product
export const NewGRNServerCreateObject: IGoodsReceiptServerRequestObject = {
  warehouseId: "",
  orderNum: "",
  receiptDate: "",
  sealNum: "",
  description: "",
  status: "",
  createdDate: "",
  createdBy: "",
  modifiedDate: "",
  modifiedBy: "",
  grDetails: [],
  goodsReceiptNumber: "",
  sealed: false,
  deleted: false,
  supValid: false,
  goodsRecType: "",
};
//#endregion

//#region  Product
export const NewProduct: IProducts = {
  productName: "",
  strength: "",
  productCode: "",
  packSize: "",
  prodLicenseNo: "",
  prodManufacturer: "",
  prodCategory: "",
  dosageForm: "",
  gtin: "",
  pipCode: 0,
  minStockQty: 0,
  comments: "",
  status: "ACTIVE",
  deleted: false,
  PIPCode: "",
};
//#endregion

//#region SalesOrder
export const NewSalesOrder: ISalesOrder = {
  soNum: "0",
  deliveryDate: "",
  totalSaleOrderAmount: 0,
  totalTaxAmount: 0,
  customerRefNum: "",
  status: "",
  isDeleted: false,
  customerId: "",
  soCreatedDate: "",
  soProductDetails: [],
  reason: "",
  deliveryAddress: "",
  location: "",
  carrierChargeAmount: 0,
  modifiedDate: "",
  customerName: "",
  addressId: 0,
  totalProductsAmount: 0,
  createdDate: "",
  createdBy: "",
  modifiedBy: "",
};
//#endregion

//#region SalesOrder
export const NewDeliveryOrder: IDeliveryOrder = {
  deliveryNum: "",
  pickingDate: new Date() + "",
  deliveryDate: new Date() + "",
  soNum: "",
  customerId: 0,
  deliveryAddress: "",
  custContactDetails: "",
  orderPickingId: "",
  status: "DRAFT",
  dispatchDate: "",
  transporterId: 0,
  transporterName: "",
  transporterAddress: "",
  transContactDetails: "",
  estDeliveryDate: "",
  bookingRefNo: "",
  dataLog: "",
  reason: "",
  deliveryOrderDetails: [],
  deleted: false,
};
//#endregion

export const NewSODetailsServerFormat: ISODetailsSeverFormat = {
  prodId: 0,
  prodCatId: 0,
  quantity: 0,
  unitPrice: 0,
  vat: 0,
};

//#region ISalesOrdreServerFormat
export const NewSalesOrderServer: ISalesOrdreServerFormat = {
  customerId: 0,
  customerName: "",
  soNum: "",
  soCreatedDate: "",
  deliveryDate: "",
  customerRefNum: "",
  deliveryAddress: "",
  addressId: 0,
  carrierChargeAmount: 0,
  status: "",
  reason: "",
  soProductDetails: [],
};

//#region Customer
export const NewCustomer: ICustomer = {
  custName: "",
  custType: "",
  prodCategory: "",
  validFrom: "",
  validTo: "",
  comments: "",
  status: "ACTIVE",
  deleted: false,
  custAddresses: [
    {
      address1: "",
      address2: "",
      city: "",
      county: "",
      country: "",
      postCode: "",
      addressUsage: "",
      custCommunication: [
        {
          contactName: "",
          phoneNumber: "",
          email: "",
          designationCountryCode: "",
        },
        {
          contactName: "",
          phoneNumber: "",
          email: "",
          designationCountryCode: "",
        },
        {
          contactName: "",
          phoneNumber: "",
          email: "",
          designationCountryCode: "",
        },
      ],
    },
  ],
};
export const NewCustomerAddress: ICustomerAddress = {
  address1: "",
  address2: "",
  city: "",
  county: "",
  country: "",
  postCode: "",
  addressUsage: "",
  custCommunication: [
    {
      contactName: "",
      phoneNumber: "",
      email: "",
      designationCountryCode: "",
    },
    {
      contactName: "",
      phoneNumber: "",
      email: "",
      designationCountryCode: "",
    },
    {
      contactName: "",
      phoneNumber: "",
      email: "",
      designationCountryCode: "",
    },
  ],
};

export const NewCustomerAddressContact: ICustomerAddressContact = {
  contactName: "",
  phoneNumber: "",
  email: "",
  designationCountryCode: "",
};
//#endregion

//purchaseOrder

export const NewPurchaseOrder: Iitems = {
  productName: "",
  productCode: "",
  dosagForm: "",
  strength: "",
  qty: "",
  pricePack: "",
  VAT: "",
  TotalAmount: "",
  carriageAmount: 987,
  poDetailsId: 22,
  price: 233,
  prodCatId: 2,
  prodId: 1,
  quantity: 4,
  totalAmount: 876,
  vat: 234,
};

//#region Transporter
export const NewTransporter: ITransporter = {
  transName: "",
  validFrom: "",
  validTo: "",
  comments: "",
  status: "ACTIVE",
  deleted: false,
  transAddresses: [
    {
      address1: "",
      address2: "",
      city: "",
      county: "",
      country: "",
      postCode: "",
      addressUsage: "",
      transCommunication: [
        {
          contactName: "",
          phoneNumber: "",
          email: "",
          designationCountryCode: "",
        },
        {
          contactName: "",
          phoneNumber: "",
          email: "",
          designationCountryCode: "",
        },
        {
          contactName: "",
          phoneNumber: "",
          email: "",
          designationCountryCode: "",
        },
      ],
    },
  ],
};

export const NewTransporterAddress: ITransporterAddress = {
  address1: "",
  address2: "",
  city: "",
  county: "",
  country: "",
  postCode: "",
  addressUsage: "",
  transCommunication: [
    {
      contactName: "",
      phoneNumber: "",
      email: "",
      designationCountryCode: "",
    },
    {
      contactName: "",
      phoneNumber: "",
      email: "",
      designationCountryCode: "",
    },
    {
      contactName: "",
      phoneNumber: "",
      email: "",
      designationCountryCode: "",
    },
  ],
};

export const NewTransporterAddressContact: ITransporterAddressContact = {
  contactName: "",
  phoneNumber: "",
  email: "",
  designationCountryCode: "",
};
//#endregion

export const NewDeleteConfirmationDialog: IDeleteConfirmationProps = {};
export const NewGRNAisleToBatchMapDialog: IGRNAisleToBatchProps = {
  oncancel: undefined,
  onconfirm: undefined,
  locationData: [],
};
export const NewGRNAisleToBatchLocationMap: IGRNAisleToBatchLocationInfo = {
  shelfId: "",
  quantity: 0,
  referenceNumber: null,
};

//#region Warehouse
export const NewWarehouse: IWarehouse = {
  clientName: "",
  warehouseName: "",
  description: "",
  warehouseScope: "",
  warehouseType: "",
  status: "ACTIVE",
  deleted: false,
  wareHouseAddress: {
    address1: "",
    address2: "",
    city: "",
    county: "",
    country: "",
    postCode: "",
    addressUsage: "",
    warehouseCom: [
      {
        contactName: "",
        phoneNumber: "",
        email: "",
        designationCountryCode: "",
      },
      {
        contactName: "",
        phoneNumber: "",
        email: "",
        designationCountryCode: "",
      },
      {
        contactName: "",
        phoneNumber: "",
        email: "",
        designationCountryCode: "",
      },
    ],
  },
};

export const NewLocationForm: IWarehouseMapForm = {
  warehouseId: 0,
  asileName: "",
  bays: 0,
  shelves: 0,
};
//#endregion

//#region  Product
export const NewOrderItemDetails: IGRNOrderItem = {
  product: [],
  productInfo: NewProduct,
};

//#endregion

export const NewReturnOrders: IReturnOrders = {
  id: 0,
  returnOrderNum: "",
  roCreatedDate: "",
  soNum: "",
  customerId: 0,
  customerName: "",
  deliveryNum: "",
  status: "",
  returnAmount: 0,
  vatAmount: 0,
  totalAmount: 0,
  returnReason: "",
  createdDate: new Date() + "",
  createdBy: "",
  modifiedDate: new Date() + "",
  modifiedBy: "",
  returnOrderDetails: [],
  deleted: false,
  carriageAmount: 0,
  deliveryAddress: "",
};

//#region  Product
export const NewGoodsReceipt: IGoodsReceipt = {
  goodsRecType: "",
  orderNum: "",
  receiptDate: "",
  isSupValid: null,
  sealNum: "",
  isSealed: null,
  description: "",
  status: "",
  createdDate: "",
  created_by: "",
  modifiedDate: "",
  modifiedBy: "",
  warehouseId: "",
  isSamplingRequired: true,
  grDetails: [],
  isDeleted: false,
  goodsRecId: "",
  goodsReceiptNumber: "",
  defaultSelectedShelfs: [],
  supplierId: 0,
};
//#endregion

export const GenderOptions: Array<string> = ["MALE", "FEMALE"];
export const SupplierTypes: Array<any> = [
  {
    value: "MANUFACTURE",
    text: "Manufacturer",
  },
  {
    value: "WHOLESALE",
    text: "Wholesaler",
  },
];
export const CustomerTypes: Array<any> = [
  {
    value: "PHARMACY",
    text: "Pharmacy",
  },
  {
    value: "WHOLESALE",
    text: "Wholesaler",
  },
  {
    value: "WHOLESALER_EXPORT",
    text: "Wholesaler-Export",
  },
];

export const Statuses: Array<string> = [
  "ACTIVE",
  "INACTIVE",
  "SUSPENDED",
  "TERMINATED",
];
export const PurchaseStatuses: Array<string> = [
  "FULL_FILL",
  "CANCEL",
  "SUBMITTED",
  "CLOSED",
  "PART_FILL",
  "DRAFT",
];

export const BatchStatuses: Array<string> = ["QUARANTINE", "RELEASE", "REJECT"];

export const SalesOrderCreationStatuses: Array<string> = ["DRAFT", "SUBMITTED"];

export const SalesOrderUpdateStatuses: Array<string> = [
  "DRAFT",
  "SUBMITTED",
  "CANCELLED",
  "PART_FILL",
  "FULL_FILL",
  "CLOSED",
];

export const SettingsData = [
  {
    name: LabelConstants.UserPermissionsAndAccess,
    path: RoutingConstants.permissionsandaccess,
    key: "UPA",
  },
];

export const ReportsData = [
  {
    name: LabelConstants.SuppliersReport,
    path: RoutingConstants.supplier_reports,
    key: "SUP-R",
  },
  {
    name: LabelConstants.CustomersReport,
    path: RoutingConstants.customer_reports,
    key: "CUST-R",
  },
  {
    name: LabelConstants.TransportersReport,
    path: RoutingConstants.transporter_reports,
    key: "TRANS-R",
  },
  {
    name: LabelConstants.ProductsReport,
    path: RoutingConstants.product_reports,
    key: "PROD-R",
  },
  {
    name: LabelConstants.Stock,
    path: RoutingConstants.stk_reports,
    key: "STC",
  },
  {
    name: LabelConstants.Stock_Planning,
    path: RoutingConstants.sp_reports,
    key: "STCP",
  },
  {
    name: LabelConstants.PurchaseOrder,
    path: RoutingConstants.po_reports,
    key: "POR",
  },
  {
    name: LabelConstants.GoodsMovement,
    path: RoutingConstants.gm_reports,
    key: "GMR",
  },
  {
    name: LabelConstants.GoodsReceipt,
    path: RoutingConstants.gr_reports,
    key: "GRNR",
  },
  {
    name: LabelConstants.BatchTraceablility,
    path: RoutingConstants.bt_reports,
    key: "BTR",
  },
  {
    name: LabelConstants.Product_Movement,
    path: RoutingConstants.pm_reports,
    key: "PMR",
  },
  {
    name: LabelConstants.Pending_SO,
    path: RoutingConstants.pso_reports,
    key: "PSOR",
  },
  {
    name: LabelConstants.Dispatch,
    path: RoutingConstants.dsp_reports,
    key: "DR",
  },
];

export const OrderPickingUpdateStatuses: Array<string> = [
  "DRAFT",
  "PICKING",
  "PARTIALLY_PICKED",
  "PICKED",
  "READY_FOR_DISPATCH",
  "PART_DISPATCHED",
  "DISPATCHED",
  "CANCELLED",
];

export const DeliveryOrderUpdateStatuses: Array<string> = [
  "DRAFT",
  "DISPATCHED",
  "CANCELLED",
];

export const DeliveryOrderStatuses: Array<string> = [
  "DRAFT",
  "DISPATCHED",
  "CANCELLED",
  "IN_TRANSIT",
  "OFD",
  "DELIVERED",
  "NOT_DELIVERED",
  "RETURNED",
  "PART_RETURNED",
];

export const DeliveryOrderStatusesToCreate: Array<string> = [
  "DRAFT",
  "DISPATCHED",
];

export const SamplingStatuses: Array<string> = ["OPEN", "PASS", "FAIL"];
export const WarehouseStatuses: Array<string> = ["ACTIVE", "INACTIVE"];
export const ProductStatuses: Array<string> = [
  "ACTIVE",
  "INACTIVE",
  "SUSPENDED",
];

export const WarehouseTypes: Array<any> = [
  {
    value: "MEDLEY",
    text: "Medley Warehouse",
  },
  {
    value: "THIRD_PARTY",
    text: "Third Party Warehouse",
  },
];
export const WarehouseScopes: Array<any> = [
  {
    value: "MIA",
    text: "MIA",
  },
  {
    value: "WDA",
    text: "WDA",
  },
  {
    value: "WDA+SAMPLING",
    text: "WDA+SAMPLING",
  },
];

export const GoodReceiptStatus: Array<any> = [
  {
    value: "DRAFT",
    text: "Draft",
  },
  {
    value: "COMPLETED",
    text: "Completed",
  },
  {
    value: "APPROVED",
    text: "Approval",
  },
];

export const BatchStatusTypes: Array<any> = [
  {
    value: LabelConstants.All,
    text: "All",
  },
  {
    value: "QRN",
    text: "Quarantine",
  },
  {
    value: "SAMP",
    text: "Sampling",
  },
  {
    value: "REL",
    text: "Release",
  },
  {
    value: "REJ",
    text: "Rejected",
  },
];

export const MovementTypes: Array<any> = [
  { value: LabelConstants.All, label: "All" },
  { value: "W2W", label: "W2W" },
  { value: "L2L", label: "L2L" },
];

export const PENDING_SO_STATUS: Array<any> = ["DRAFT", "SUBMITTED", "CANCELLED", "PART_FILL", "FULL_FILL", "CLOSED"];

export const DISPATCH_STATUS: Array<any> = [
  "DELIVERED",
  "DISPATCHED",
  "PART_RETURNED",
  "RETURNED",
  "IN_TRANSIT",
  "OFD",
  "",
];

export const FORM_MODES = {
  Create: "Create",
  View: "View",
  Edit: "Edit",
};

export const SEQUENCE_PREFIXES = {
  GRN: "GRN",
  PR: "PR",
};

export const TimePeriodValues: Array<any> = [
  {
    value: 1,
    label: "Current Month",
  },
  {
    value: 2,
    label: "Previous Month",
  },
  {
    value: 3,
    label: "Three Months",
  },
  {
    value: 6,
    label: "Six Months",
  },
  {
    value: 12,
    label: "One Year",
  },
];

export const GoodReceiptTypes: Array<any> = [
  {
    value: "PO",
    text: "Purchase Order",
  },
  {
    value: "TO",
    text: "Transfer Order",
  },
  {
    value: "RO",
    text: "Return Order",
  },
];

export const GRNDialogs = {
  CANCEL: "CANCEL",
  DELETE: "DELETE",
};

export const INVENTORY_STATUS = {
  OUT_OF_STOCK: "OUT_OF_STOCK",
  IN_STOCK: "IN_STOCK",
  LOW_INVENTORY: "LOW_INVENTORY",
};

// export const ProductCategories: Array<string> = ["GSL", "POM", "P", "Others"];
// export const DosageForms: Array<string> = ["Tablet", "Eff.Tablet", "Caplets", "Capsules", "Oral Solution", "Syrup, Solution", "Suspension"];
