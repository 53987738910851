import {
  OrdersApiBase,
  OrderPickingList,
  ProductsBySO,
  ProductLocations,
  createOrderPicking,
  getNextOpNumber,
  updateOrderPicking,
  OPStatusUpdate,
  searchOPByCriteria,
  createNewTransferOrderWarehouse,
  updateTransferWarehouse,
  WarehouseTransferList,
  getNextTONumber,
  cancelLocationDetails,
  updateLocationQtyCancel,
  updateStatusToCancel,
  updateLocationDetails,
  getcancelPrefLocationDetails,
  validateIfCancelIsEligible,
} from "../../common/constants/apiConstants";
import { ERROR, SUCCESS } from "../../common/constants/generalConstants";
import {
  IBatchManagement,
  IListConfigOP,
  IStatusUpdate,
} from "../../common/models";
import { CancelLocationDetails } from "../../common/models/pickingorder";
import { AxiosService } from "../generics/axios.service";
import { IOrderPickingService } from "../interfaces/IOrderPicking";

export class OrderPickingService implements IOrderPickingService {
  private axiosService: AxiosService = new AxiosService();

  async getWarehouseTransferOrdersList(): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Get(
        WarehouseTransferList,
        OrdersApiBase
      );
      const _batchAllList: any = result.data?.content ?? [];
      return _batchAllList;
    } catch (error) {
      console.error("Error: Order warehouse  List - ", error);
      return [];
    }
  }

  async getProductsBySO(so: string): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Get(
        ProductsBySO(so),
        OrdersApiBase
      );
      const _productsList: any = result.data?.soProductDetails ?? [];
      console.log("PR List - ", _productsList);
      return _productsList;
    } catch (error) {
      console.error("Error: PR  List - ", error);
      return [];
    }
  }
  async getPickingOrdersList(pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Get(
        OrderPickingList(pageNo),
        OrdersApiBase
      );
      return result.data ?? null;
    } catch (error) {
      console.error("Error: Order Picking  List - ", error);
      return null;
    }
  }

  async searchOPList(filter: IListConfigOP, pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Post(
        searchOPByCriteria(pageNo),
        filter,
        OrdersApiBase
      );
      return result?.data ?? null;
    } catch (error) {
      console.error("Error: searchOPList List - ", error);
      return null;
    }
  }
  async createNewTransferOrderWarehouse(transferOrder: any): Promise<boolean> {
    try {
      const result: any = await this.axiosService.Post(
        createNewTransferOrderWarehouse,
        transferOrder,
        OrdersApiBase
      );
      if (result) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error: save OP - ", error);
      return false;
    }
  }
  async updateNewOrderPicking(newOP: any): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Put(
        updateOrderPicking(newOP.id),
        newOP,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: save OP - ", error);
      return error.response.data?.errorMessage ?? "Error saving Order Picking.";
    }
  }

  async updateTransferWarehouse(updateTransfer: any): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Put(
        updateTransferWarehouse(updateTransfer.id),
        updateTransfer,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: save OP - ", error);
      return error.response.data?.errorMessage ?? "Error saving Order Picking.";
    }
  }
  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Post(OPStatusUpdate, status, OrdersApiBase);
      return true;
    } catch (error) {
      console.error(
        "Error: Update OP Status - ",
        error.response.data?.errorMessage
      );
      return error.response.data?.errorMessage ?? false;
    }
  }
  async getProductLocations(
    type: any,
    prId: any,
    qty: any,
    whId: any
  ): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Get(
        ProductLocations(type, prId, qty, whId),
        OrdersApiBase
      );
      const _prLocations: any = result.data ?? [];
      console.log("PR locations List - ", _prLocations);
      return _prLocations;
    } catch (error) {
      console.error("Error: PR locations List - ", error);
      return [];
    }
  }

  async getCancelLocationsDetails(
    locationId: string,
    batchNo: string,
    prodId: string,
    qty: string,
    batchExp: string
  ): Promise<CancelLocationDetails[]> {
    try {
      const result: any = await this.axiosService.Get(
        cancelLocationDetails(locationId, batchNo, prodId, qty, batchExp),
        OrdersApiBase
      );
      const _prLocations: CancelLocationDetails[] = result?.data ?? null;
      console.log("getCancelLocationsDetails - ", _prLocations);
      return _prLocations;
    } catch (error) {
      console.error("Error: getCancelLocationsDetails - ", error);
      return null;
    }
  }

  async updateStatusToCancel(id: any, comments: string): Promise<any> {
    try {
      let result = await this.axiosService.Put(
        updateStatusToCancel(id, comments),
        null,
        OrdersApiBase
      );
      return { status: SUCCESS, message: result?.data?.response };
    } catch (error) {
      console.error("Error: updateStatusToCancel - ", error.response.data);
      return {
        status: ERROR,
        message: error?.response?.data?.errorMessage ?? "",
      };
    }
  }

  async updateLocQtyCancel(id: string, qty: string): Promise<any> {
    try {
      const result: any = await this.axiosService.Post(
        updateLocationQtyCancel(id, qty),
        null,
        OrdersApiBase
      );
      return { status: SUCCESS, value: result.data };
    } catch (error) {
      console.error("Error: updateLocQtyCancel - ", error);
      return {
        status: ERROR,
        value: error.response.data?.errorMessage ?? "",
      };
    }
  }

  async getCancelledPrefferedLocationDetails(
    batchNo: string,
    prod: string,
    batchExpiry: string,
    qty: string,
    warehouseId: string
  ): Promise<any> {
    try {
      const result: any = await this.axiosService.Post(
        getcancelPrefLocationDetails(
          batchNo,
          prod,
          batchExpiry,
          qty,
          warehouseId
        ),
        null,
        OrdersApiBase
      );
      const _prLocations: CancelLocationDetails[] = result?.data ?? null;
      console.log("getCancelledPrefferedLocationDetails - ", _prLocations);
      return _prLocations;
    } catch (error) {
      console.error("Error: getCancelledPrefferedLocationDetails - ", error);
      return null;
    }
  }

  async updateLocationDetailsForLocation(data: any): Promise<any> {
    try {
      const result: any = await this.axiosService.Post(
        updateLocationDetails,
        data,
        OrdersApiBase
      );
      return { status: SUCCESS, message: result.data };
    } catch (error) {
      console.error("Error: updateLocationDetailsForLocation - ", error);
      return {
        status: ERROR,
        message: error.response.data[0]?.errorMessage ?? "Error saving data!!",
      };
    }
  }

  async validateCancelEligible(pickingNum: string): Promise<any> {
    try {
      let status = ERROR;
      const result: any = await this.axiosService.Get(
        validateIfCancelIsEligible(pickingNum),
        OrdersApiBase
      );
      if (result?.data?.response === "") {
        status = SUCCESS;
      }
      return { status: status, message: result?.data?.response };
    } catch (error) {
      console.error("Error: Next OP Number - ", error);
      return { status: ERROR, message: error?.response?.data?.errorMessage };
    }
  }

  async createNewOrderPicking(newOP: any): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Post(
        createOrderPicking,
        newOP,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: save OP - ", error);
      return (
        error.response.data?.errorMessage ?? "Error saving new Order Picking."
      );
    }
  }

  async getNextOpNumber() {
    try {
      const result: any = await this.axiosService.Get(
        getNextOpNumber,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Next OP Number - ", error);
      return [];
    }
  }
  async getNextToNumber() {
    try {
      const result: any = await this.axiosService.Get(
        getNextTONumber,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Next TO Number - ", error);
      return [];
    }
  }
}
