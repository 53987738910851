import { NavLink, useLocation } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { useContext, useState } from "react";
import {
  getProductCode,
  getProductName,
  getProductPackSize,
  pagePrintPorStyle,
} from "../../common/methods/globalmethods";
import { IPurchase, ISupplier } from "../../common/models";
import moment from "moment";
import { PurchaseContext } from "../../controllers/contexts/purchase.context";
import PrintHeader from "./PrintHeader";
import BoxItemWithKeyValue from "./Components/BoxItemWithKeyValue";

const PurchaseOrderPrintFormat = () => {
  const { productsList } = useContext(PurchaseContext);
  const location = useLocation();
  const [poItem, setPoItem] = useState<IPurchase>(
    location?.state?.selectedItem
  );
  const [suppliers, setSuppliers] = useState<ISupplier[]>(
    location?.state?.suppliers
  );
  const getSubTotal = () => {
    let subtotal = 0;
    poItem?.poDetails?.map((item) => {
      subtotal += item?.totalAmount - item?.vat;
    });
    return subtotal.toFixed(2);
  };

  const getSupplierAddress = (value) => {
    const supplier = suppliers.find(so => so.supId === poItem.supplierId);
    if(supplier) {
      return supplier?.supAddresses[0]?.[value]
    }
  } 

  return (
    <div>
      <div className="content-header row">
        <div className="d-flex align-items-center col-md-11">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.purchase}
                    >
                      {LabelConstants.OrderIn}
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.purchase}
                    >
                      Purchase Order
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Print
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="col-md-1">
          <button
            type="button"
            className="waves-effect waves-light btn btn-success rounded"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <style>{pagePrintPorStyle}</style>
        <div style={{ width: 210 + "mm" }}>
          <div
            style={{
              backgroundColor: "white",
              padding: 30,
              paddingTop: 0,
              paddingBottom: 10,
            }}
          >
            <PrintHeader centerText="PURCHASE ORDER" />
            <div className="row">
              <div className="col-7">
                {BoxItemWithKeyValue("PO #", poItem?.purchaseOrderNumber)}
                {BoxItemWithKeyValue("Supplier Name", poItem?.supplierName)}
                {BoxItemWithKeyValue("Supplier Address", `${getSupplierAddress("address1")},\n${getSupplierAddress("address2")}`)}
                {BoxItemWithKeyValue("", `${getSupplierAddress("city")},\n${getSupplierAddress("county")}`)}
                {BoxItemWithKeyValue("", `${getSupplierAddress("country")},\n${getSupplierAddress("postCode")}`)}
                
              </div>
              <div className="col-5">
                {BoxItemWithKeyValue(
                  "Order Date",
                  moment(poItem?.poCreatedDate).format("DD/MM/YYYY")
                )}
                {BoxItemWithKeyValue("Order Ref. #", poItem?.refNumber)}
              </div>
            </div>
          </div>
          <div
            className="col-12 table-responsive"
            style={{ backgroundColor: "white", padding: 20, paddingTop: 0 }}
          >
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ fontSize: 12, margin: 0 }}>#</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Product Code</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Product Name</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Pack Size</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Price</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Order Qty</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {poItem?.poDetails?.map((item, idx) => (
                  <tr className="col-12 p-0">
                    <td className="col-1" style={{ fontSize: 12, margin: 0 }}>
                      <p>{idx + 1}</p>
                    </td>
                    <td className="col-2" style={{ fontSize: 12, margin: 0 }}>
                      <p>{getProductCode(item?.prodId, productsList)}</p>
                    </td>
                    <td className="col-3">
                      <p>{getProductName(item?.prodId, productsList)}</p>
                    </td>
                    <td className="col-1" style={{ fontSize: 12, margin: 0 }}>
                      <p>{getProductPackSize(item.prodId, productsList)}</p>
                    </td>
                    <td className="col-1" style={{ fontSize: 12, margin: 0 }}>
                      <p>{item.price}</p>
                    </td>
                    <td className="col-1" style={{ fontSize: 12, margin: 0 }}>
                      <p>{item.quantity}</p>
                    </td>
                    <td className="col-2">
                      <p>{(item.totalAmount - item.vat)?.toFixed(2)}</p>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="b-1 border-black" colSpan={4} rowSpan={3}>
                    <p className="text-bold">VAT Analysis:</p>
                    <p>{`Standard:      ${poItem?.taxAmount?.toFixed(2)}`}</p>
                  </td>
                  <td className="text-bold" colSpan={2}>
                    SUB TOTAL
                  </td>
                  <td className="text-bold">{getSubTotal()}</td>
                </tr>
                <tr>
                  <td colSpan={2} className="text-bold">
                    TAX RATE
                  </td>
                  <td className="text-bold">{poItem?.taxAmount?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan={2} className="text-bold">
                    TOTAL
                  </td>
                  <td className="text-bold">
                    {(parseFloat(getSubTotal()) + poItem?.taxAmount)?.toFixed(
                      2
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="col-md-12 border-grey b-1 h-150 mt-30 p-10">
              <p className="text-bold">
                <u>Special Instructions:</u>
              </p>
              <p>
                All deliveries must be pre-booked prior to delivery : email -
                supplychain@medleypharma.co.uk
              </p>
            </div>
            <div className="col-md-4 border-grey b-1 mt-30 p-20">
              <p className="text-bold" style={{ marginLeft: -5 }}>
                <u>Delivery Address:</u>
              </p>
              <p style={{ marginBottom: 0 }}>Medley Pharma Limited</p>
              <p style={{ marginBottom: 0 }}>2A Olympic Way,</p>
              <p style={{ marginBottom: 0 }}>Sefton Business Park,</p>
              <p style={{ marginBottom: 0 }}>Aintree, L130 1RD</p>
              <p style={{ marginBottom: 0 }}>Tel: +44 (0) 1515214527</p>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderPrintFormat;
