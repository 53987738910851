import ReactPaginate from "react-paginate";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import PageSortDescription from "../common/pageSortDescription.component";
import { useContext, useEffect } from "react";
import { ReportsData } from "../../common/constants/generalConstants";
import { ReportsContext } from "../../controllers/contexts/reports.context";
import POReportViewComponent from "./views/po_report";
import StockReportViewComponent from "./views/stock_report";
import StockPlanningViewComponent from "./views/planning_report";
import GoodsMovementViewComponent from "./views/goods_movement_report";
import GRNReportViewComponent from "./views/grn_report";
import BatchTraceabilityViewComponent from "./views/batch_traceability_report";
import DispatchViewComponent from "./views/dispatch_report";
import ProductMovementViewComponent from "./views/product_movement_report";
import PendingSOViewComponent from "./views/pendingso_report";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import useAuth from "../../controllers/hooks/useAuth";
import SupplierViewComponent from "./views/supplier_report";
import CustomerViewComponent from "./views/customer_report";
import TransporterViewComponent from "./views/transporter_report";
import ProductViewComponent from "./views/product_report";

const ReportsComponent = () => {
  const {
    masterFilter,
    filteredMasterData,
    setMasterfilter,
    clearMasterData,
    getFilteredDownloadData,
    filteredMasterDownloadData,
  } = useContext(ReportsContext);
  const location = useLocation();
  const { isAccessDisabled } = useAuth();
  useEffect(() => {
    clearMasterData();
    return () => {
      clearMasterData();
    };
  }, []);

  const getDownloadFileName = () => {
    let name = location?.pathname;
    name = name.substring(name.indexOf("/reports/") + 9);
    name += "_" + moment(new Date()).format("DD-MM-yyyy") + ".csv";
    return name;
  };

  const isDownloadDisabled = () => {
    let pathname = location.pathname;
    let key = "";
    ReportsData?.map((item) => {
      if (item.path === pathname) {
        key = item.key;
      }
    });
    let result = isAccessDisabled("REP", "download", true, key);
    return result;
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="col-lg-10">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {
                      ReportsData.find(
                        (item) => item?.path === location?.pathname
                      )?.name
                    }
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div
            className="col-lg-2"
            style={
              isDownloadDisabled()
                ? { pointerEvents: "none", opacity: 0.5 }
                : {}
            }
          >
            <CSVLink
              asyncOnClick={true}
              onClick={(event, done) => {
                if (filteredMasterData?.length > 0) {
                  getFilteredDownloadData(location?.pathname, event, done);
                } else {
                  done(false);
                }
              }}
              data={filteredMasterDownloadData}
              filename={getDownloadFileName()}
              className="waves-effect waves-light btn btn-success rounded  pull-right"
              target="_blank"
            >
              Download CSV
            </CSVLink>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            {location?.pathname === RoutingConstants.stk_reports && (
              <StockReportViewComponent />
            )}
            {location?.pathname === RoutingConstants.sp_reports && (
              <StockPlanningViewComponent />
            )}
            {location?.pathname === RoutingConstants.po_reports && (
              <POReportViewComponent />
            )}
            {location?.pathname === RoutingConstants.gm_reports && (
              <GoodsMovementViewComponent />
            )}
            {location?.pathname === RoutingConstants.gr_reports && (
              <GRNReportViewComponent />
            )}
            {location?.pathname === RoutingConstants.bt_reports && (
              <BatchTraceabilityViewComponent />
            )}
            {location?.pathname === RoutingConstants.pm_reports && (
              <ProductMovementViewComponent />
            )}
            {location?.pathname === RoutingConstants.dsp_reports && (
              <DispatchViewComponent />
            )}
            {location?.pathname === RoutingConstants.pso_reports && (
              <PendingSOViewComponent />
            )}
            {location?.pathname === RoutingConstants.supplier_reports && (
              <SupplierViewComponent />
            )}
            {location?.pathname === RoutingConstants.customer_reports && (
              <CustomerViewComponent />
            )}
            {location?.pathname === RoutingConstants.transporter_reports && (
              <TransporterViewComponent />
            )}
            {location?.pathname === RoutingConstants.product_reports && (
              <ProductViewComponent />
            )}
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <PageSortDescription
                      filters={masterFilter}
                      dataList={filteredMasterData}
                    />
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    {masterFilter?.totalPageCount > 1 && (
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="productorder_paginate"
                      >
                        <ReactPaginate
                          forcePage={masterFilter?.currentPage - 1}
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={
                            masterFilter && masterFilter?.totalPageCount < 2
                              ? 0
                              : masterFilter?.totalPageCount
                          }
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          pageClassName={`paginate_button page-item`}
                          pageLinkClassName="page-link"
                          activeLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          breakClassName={`paginate_button page-item`}
                          disabledClassName={`disabled`}
                          previousClassName={`paginate_button page-item previous`}
                          nextClassName={`paginate_button page-item next`}
                          onPageChange={(e) => {
                            setMasterfilter({
                              ...masterFilter,
                              currentPage: e.selected + 1,
                            });
                          }}
                          containerClassName={`pagination mb-1`}
                          activeClassName={`active`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsComponent;
